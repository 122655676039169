import { Input } from "@/types";
import Form from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Switch from "@/ui-lib/components/Switch";
import { useTheme } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ServiceType } from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { DimensionValuesMap } from "../../../api/analytics/useGetDimensionValues";
import SideDrawerLegacy from "../../../components/SideDrawerLegacy";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import RecommendationsNotificationForm from "./RecommendationsNotificationForm";

const serviceLabelToTypeMap = {
  Anomaly: ServiceType.ANMLY,
  BigQuery: ServiceType.BIGQUERY,
  "Elastic Block Storage (EBS)": ServiceType.EBS,
  "Elastic Cloud Compute (EC2)": ServiceType.EC2,
  "Elastic Kubernetes Service (EKS)": ServiceType.EKS,
  "Google Compute Engine (GCE)": ServiceType.GCE,
  "Google Cloud Storage (GCS)": ServiceType.GCS,
  "Google Kubernetes Engine (GKE)": ServiceType.GKE,
  "Relational Database Service (RDS)": ServiceType.RDS,
  "Simple Storage Service (S3)": ServiceType.S3,
};

export const serviceTypeToLabelMap = {
  [ServiceType.ANMLY]: "Anomaly",
  [ServiceType.BIGQUERY]: "BigQuery",
  [ServiceType.EBS]: "Elastic Block Storage (EBS)",
  [ServiceType.EC2]: "Elastic Cloud Compute (EC2)",
  [ServiceType.EKS]: "Elastic Kubernetes Service (EKS)",
  [ServiceType.GCE]: "Google Compute Engine (GCE)",
  [ServiceType.GCS]: "Google Cloud Storage (GCS)",
  [ServiceType.GKE]: "Google Kubernetes Engine (GKE)",
  [ServiceType.RDS]: "Relational Database Service (RDS)",
  [ServiceType.S3]: "Simple Storage Service (S3)",
};

type Settings = {
  notifyAlerts: boolean;
  notifyBudgets: boolean;
  notifyCaseUpdates: boolean;
  notifyRecs: boolean;
  notifyRecsDaily: boolean;
  notifyRecsMonthly: boolean;
  notifyRecsWeekly: boolean;
  notifyReportsDaily: boolean;
  notifyReportsMonthly: boolean;
  notifyReportsWeekly: boolean;
  recFilters: {
    services?: string[];
    subAccountIDs?: string[];
    vendors?: string[];
  };
};

interface Props {
  dimensionValuesMap: DimensionValuesMap;
  isLoadingDimensionValueMap: boolean;
  isProcessing: boolean;
  settings: Settings;
  onInteraction: (
    interaction: EditUserNotificationSettingsForm.Interaction
  ) => void;
}

type State = {
  enableAlertsInput: { value: boolean; hasChanged: boolean };
  enableBudgetsInput: { value: boolean; hasChanged: boolean };
  enableCaseNotificationsInput: { value: boolean; hasChanged: boolean };
  enableRecommendationsInput: { value: boolean; hasChanged: boolean };
  enableRecsDailyInput: { value: boolean; hasChanged: boolean };
  enableRecsWeeklyInput: { value: boolean; hasChanged: boolean };
  enableRecsMonthlyInput: { value: boolean; hasChanged: boolean };
  enableReportsDailyInput: { value: boolean; hasChanged: boolean };
  enableReportsMonthlyInput: { value: boolean; hasChanged: boolean };
  enableReportsWeeklyInput: { value: boolean; hasChanged: boolean };
  isSideDrawerOpen: boolean;
  servicesInput: Input<string[]>;
  subAccountsInput: Input<string[]>;
  vendorsInput: Input<string[]>;
};

export function EditUserNotificationSettingsForm(props: Props): JSX.Element {
  const theme = useTheme();

  //
  // State
  //

  const initialState: State = {
    enableAlertsInput: {
      value: props.settings.notifyAlerts,
      hasChanged: false,
    },
    enableBudgetsInput: {
      value: props.settings.notifyBudgets,
      hasChanged: false,
    },
    enableCaseNotificationsInput: {
      value: props.settings.notifyCaseUpdates,
      hasChanged: false,
    },
    enableRecommendationsInput: {
      value: props.settings.notifyRecs,
      hasChanged: false,
    },
    enableRecsDailyInput: {
      value: props.settings.notifyRecsDaily ?? false,
      hasChanged: false,
    },
    enableRecsMonthlyInput: {
      value: props.settings.notifyRecsMonthly ?? false,
      hasChanged: false,
    },
    enableRecsWeeklyInput: {
      value: props.settings.notifyRecsWeekly ?? false,
      hasChanged: false,
    },
    enableReportsDailyInput: {
      value: props.settings.notifyReportsDaily,
      hasChanged: false,
    },
    enableReportsMonthlyInput: {
      value: props.settings.notifyReportsMonthly,
      hasChanged: false,
    },
    enableReportsWeeklyInput: {
      value: props.settings.notifyReportsWeekly,
      hasChanged: false,
    },
    isSideDrawerOpen: false,
    servicesInput: {
      value: props.settings.recFilters.services
        ? props.settings.recFilters.services.map(
            (service) => serviceTypeToLabelMap[service]
          )
        : [],
      isValid: true,
      hasChanged: false,
    },
    subAccountsInput: {
      value: props.settings.recFilters.subAccountIDs ?? [],
      isValid: true,
      hasChanged: false,
    },
    vendorsInput: {
      value: props.settings.recFilters.vendors ?? [],
      isValid: true,
      hasChanged: false,
    },
  };

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Side Effects
  //

  useEffect(() => {
    setState(initialState);
  }, [props.settings]);

  //
  //  Handlers
  //

  const hasRecNotificationChanges =
    state.enableRecommendationsInput &&
    (state.vendorsInput.hasChanged ||
      state.servicesInput.hasChanged ||
      state.subAccountsInput.hasChanged);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    let value: boolean | string = event.target.value;
    let hasChanged = false;

    if (Array.isArray(JSON.parse(value))) {
      value = JSON.parse(value);
    } else {
      value = value === "true" ? true : false;
    }

    switch (name) {
      case "enableAlerts": {
        hasChanged = props.settings.notifyAlerts !== value;
        break;
      }
      case "enableBudgets": {
        hasChanged = props.settings.notifyBudgets !== value;
        break;
      }
      case "enableCaseNotifications": {
        hasChanged = props.settings.notifyCaseUpdates !== value;
        break;
      }
      case "enableRecommendations": {
        hasChanged = props.settings.notifyRecs !== value;
        break;
      }
      case "enableRecsDaily": {
        hasChanged = props.settings.notifyRecsDaily !== value;
        break;
      }
      case "enableRecsWeekly": {
        hasChanged = props.settings.notifyRecsWeekly !== value;
        break;
      }
      case "enableRecsMonthly": {
        hasChanged = props.settings.notifyRecsMonthly !== value;
        break;
      }
      case "enableReportsDaily": {
        hasChanged = props.settings.notifyReportsDaily !== value;
        break;
      }
      case "enableReportsMonthly": {
        hasChanged = props.settings.notifyReportsMonthly !== value;
        break;
      }
      case "enableReportsWeekly": {
        hasChanged = props.settings.notifyReportsWeekly !== value;
        break;
      }
      case "services": {
        hasChanged = !isEqual(props.settings.recFilters.services, value);
        break;
      }
      case "subAccounts": {
        hasChanged = !isEqual(props.settings.recFilters.subAccountIDs, value);
        break;
      }
      case "vendors": {
        hasChanged = !isEqual(props.settings.recFilters.vendors, value);
      }
    }

    setState((currentState) => ({
      ...currentState,
      [`${name}Input`]: { value, hasChanged },
    }));
  }

  function handleReset(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    setState(initialState);
  }

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    props.onInteraction({
      type: EditUserNotificationSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      enableAlerts: state.enableAlertsInput.value,
      enableBudgets: state.enableBudgetsInput.value,
      enableCaseNotifications: state.enableCaseNotificationsInput.value,
      enableRecs: state.enableRecommendationsInput.value,
      enableRecsDaily: state.enableRecsDailyInput.value,
      enableRecsMonthly: state.enableRecsMonthlyInput.value,
      enableRecsWeekly: state.enableRecsWeeklyInput.value,
      enableReportsDaily: state.enableReportsDailyInput.value,
      enableReportsMonthly: state.enableReportsMonthlyInput.value,
      enableReportsWeekly: state.enableReportsWeeklyInput.value,
      recFilters: {
        services: state.servicesInput.value.map(
          (value) => serviceLabelToTypeMap[value]
        ),
        subAccountIDs: state.subAccountsInput.value,
        vendors: state.vendorsInput.value,
      },
    });
  }

  //
  // Render
  //

  const hasChanged = Object.values(state).some(
    (input) =>
      typeof input === "object" && !Array.isArray(input) && input.hasChanged
  );

  return (
    <Form height={500}>
      <Text
        fontSize={theme.h3_fontSize}
        fontWeight={theme.h3_fontWeight}
        marginVertical={theme.space_md}
        color={theme.text_color}
      >
        {copyText.subTitleNotificationPreferences}
      </Text>
      <table>
        <tbody>
          <tr>
            <td>
              <Flex
                alignItems="center"
                marginVertical={theme.space_md}
                marginRight={theme.space_xxl}
              >
                <Tooltip
                  content={copyText.tooltip_alerts}
                  icon={faInfoCircle}
                />
                <Text
                  fontSize={theme.fontSize_base}
                  fontWeight={theme.fontWeight_semiBold}
                >
                  {copyText.notificationTypeAlerts}
                </Text>
              </Flex>
            </td>
            <td key="enableAlerts">
              <Flex marginHorizontal={theme.space_md}>
                <Switch
                  name="enableAlerts"
                  checked={state.enableAlertsInput.value}
                  onChange={(checked) =>
                    handleChange({
                      target: {
                        name: "enableAlerts",
                        value: String(checked),
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                />
                <Box marginHorizontal={theme.space_xs}>
                  <Text fontSize={theme.fontSize_ui}>
                    {copyText.notificationOption_enabled}
                  </Text>
                </Box>
              </Flex>
            </td>
          </tr>
          <tr>
            <td>
              <Flex
                alignItems="center"
                marginVertical={theme.space_md}
                marginRight={theme.space_xxl}
              >
                <Tooltip
                  content={copyText.tooltip_budgets}
                  icon={faInfoCircle}
                />
                <Text
                  fontSize={theme.fontSize_base}
                  fontWeight={theme.fontWeight_semiBold}
                >
                  {copyText.notificationTypeBudgets}
                </Text>
              </Flex>
            </td>
            <td key="enableBudgets">
              <Flex marginHorizontal={theme.space_md}>
                <Switch
                  name="enableBudgets"
                  checked={state.enableBudgetsInput.value}
                  onChange={(checked) =>
                    handleChange({
                      target: {
                        name: "enableBudgets",
                        value: String(checked),
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                />
                <Box marginHorizontal={theme.space_xs}>
                  <Text fontSize={theme.fontSize_ui}>
                    {copyText.notificationOption_enabled}
                  </Text>
                </Box>
              </Flex>
            </td>
          </tr>
          <tr>
            <td>
              <Flex
                alignItems="center"
                marginVertical={theme.space_md}
                marginRight={theme.space_xxl}
              >
                <Tooltip content={copyText.tooltip_cases} icon={faInfoCircle} />
                <Text
                  fontSize={theme.fontSize_base}
                  fontWeight={theme.fontWeight_semiBold}
                >
                  {copyText.notificationTypeCases}
                </Text>
              </Flex>
            </td>
            <td key="enableCaseNotifications">
              <Flex marginHorizontal={theme.space_md}>
                <Switch
                  name="enableCaseNotifications"
                  checked={state.enableCaseNotificationsInput.value}
                  onChange={(checked) =>
                    handleChange({
                      target: {
                        name: "enableCaseNotifications",
                        value: String(checked),
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                />
                <Box marginHorizontal={theme.space_xs}>
                  <Text fontSize={theme.fontSize_ui}>
                    {copyText.notificationOption_enabled}
                  </Text>
                </Box>
              </Flex>
            </td>
          </tr>
          <tr>
            <td>
              <Flex
                alignItems="center"
                marginVertical={theme.space_md}
                marginRight={theme.space_xxl}
              >
                <Tooltip
                  content={copyText.tooltip_recommendations}
                  icon={faInfoCircle}
                />
                <Text
                  fontSize={theme.fontSize_base}
                  fontWeight={theme.fontWeight_semiBold}
                >
                  {copyText.notificationTypeRecommendations}
                </Text>
              </Flex>
            </td>
            <td key="enableRecommendations">
              <Flex marginHorizontal={theme.space_md}>
                <Switch
                  name="enableRecommendations"
                  checked={state.enableRecommendationsInput.value}
                  onChange={(checked) =>
                    handleChange({
                      target: {
                        name: "enableRecommendations",
                        value: String(checked),
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                />
                <Box marginHorizontal={theme.space_xs}>
                  <Text fontSize={theme.fontSize_ui}>
                    {copyText.notificationOption_enabled}
                  </Text>
                </Box>
              </Flex>
            </td>
            <td>
              <Button
                disabled={!state.enableRecommendationsInput.value}
                size="small"
                type="button"
                onClick={() => {
                  mergeState({
                    isSideDrawerOpen: true,
                  });
                }}
              >
                {copyText.recommendationsConfigureButtonLabel}
              </Button>
            </td>
          </tr>
          <tr>
            <td>
              <Flex
                alignItems="center"
                marginVertical={theme.space_md}
                marginRight={theme.space_xxl}
              >
                <Tooltip
                  content={copyText.tooltip_reports}
                  icon={faInfoCircle}
                />
                <Text
                  fontSize={theme.fontSize_base}
                  fontWeight={theme.fontWeight_semiBold}
                >
                  {copyText.notificationTypeReports}
                </Text>
              </Flex>
            </td>
            <td key="enableReportsDaily">
              <Flex marginHorizontal={theme.space_md}>
                <Switch
                  name="enableReportsDaily"
                  checked={state.enableReportsDailyInput.value}
                  onChange={(checked) =>
                    handleChange({
                      target: {
                        name: "enableReportsDaily",
                        value: String(checked),
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                />
                <Box marginHorizontal={theme.space_xs}>
                  <Text fontSize={theme.fontSize_ui}>
                    {copyText.notificationOption_daily}
                  </Text>
                </Box>
              </Flex>
            </td>
            <td key="enableReportsWeekly">
              <Flex marginHorizontal={theme.space_md}>
                <Switch
                  name="enableReportsWeekly"
                  checked={state.enableReportsWeeklyInput.value}
                  onChange={(checked) =>
                    handleChange({
                      target: {
                        name: "enableReportsWeekly",
                        value: String(checked),
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                />
                <Box marginHorizontal={theme.space_xs}>
                  <Text fontSize={theme.fontSize_ui}>
                    {copyText.notificationOption_weekly}
                  </Text>
                </Box>
              </Flex>
            </td>
            <td key="enableReportsMonthly">
              <Flex marginHorizontal={theme.space_md}>
                <Switch
                  name="enableReportsMonthly"
                  checked={state.enableReportsMonthlyInput.value}
                  onChange={(checked) =>
                    handleChange({
                      target: {
                        name: "enableReportsMonthly",
                        value: String(checked),
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                />
                <Box marginHorizontal={theme.space_xs}>
                  <Text fontSize={theme.fontSize_ui}>
                    {copyText.notificationOption_monthly}
                  </Text>
                </Box>
              </Flex>
            </td>
          </tr>
        </tbody>
      </table>
      <Flex justifyContent="end" marginTop={theme.space_xs}>
        <Button
          disabled={
            (!hasChanged && hasRecNotificationChanges) || props.isProcessing
          }
          marginRight={theme.space_md}
          secondary
          width={100}
          onClick={handleReset}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!hasChanged || props.isProcessing}
          primary
          width={100}
          onClick={handleSubmit}
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Flex>
      <SideDrawerLegacy
        isOpen={state.isSideDrawerOpen}
        title={copyText.recommendationsNotificationFormTitle}
        onClose={() =>
          mergeState({
            isSideDrawerOpen: false,
          })
        }
        renderContent={() =>
          state.isSideDrawerOpen ? (
            <RecommendationsNotificationForm
              dimensionValuesMap={props.dimensionValuesMap}
              isLoadingDimensionValueMap={props.isLoadingDimensionValueMap}
              recNotificationConfig={{
                enableRecsDaily: state.enableRecsDailyInput.value,
                enableRecsMonthly: state.enableRecsMonthlyInput.value,
                enableRecsWeekly: state.enableRecsWeeklyInput.value,
                isEnabled: props.settings.notifyRecs,
                services: state.servicesInput.value,
                subAccountIDs: state.subAccountsInput.value,
                vendors: state.vendorsInput.value,
              }}
              onChange={handleChange}
              onClose={() => mergeState({ isSideDrawerOpen: false })}
            />
          ) : null
        }
      />
    </Form>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EditUserNotificationSettingsForm {
  export const INTERACTION_SUBMIT_BUTTON_CLICKED = `EditUserNotificationSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED`;
  export const INTERACTION_CONFIG_BUTTON_CLICKED = `EditUserNotificationSettingsForm.INTERACTION_CONFIG_BUTTON_CLICKED`;

  interface InteractionSubmitButtonClicked {
    type: typeof INTERACTION_SUBMIT_BUTTON_CLICKED;
    enableAlerts: boolean;
    enableBudgets: boolean;
    enableCaseNotifications: boolean;
    enableRecs: boolean;
    enableRecsDaily: boolean;
    enableRecsMonthly: boolean;
    enableRecsWeekly: boolean;
    enableReportsDaily: boolean;
    enableReportsMonthly: boolean;
    enableReportsWeekly: boolean;
    recFilters?: {
      services: string[];
      subAccountIDs: string[];
      vendors: string[];
    };
  }

  interface InteractionConfigButtonClicked {
    type: typeof INTERACTION_CONFIG_BUTTON_CLICKED;
  }

  export type Interaction =
    | InteractionSubmitButtonClicked
    | InteractionConfigButtonClicked;
}

export default EditUserNotificationSettingsForm;
