import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { DataIntegrationEntity } from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { sortBy, uniq } from "lodash";
import React from "react";
import copyText from "../copyText";

const StyledButton = styled(Button)(({ selected, theme }) => ({
  borderRadius: theme.borderRadius_4,
  padding: `4px 12px`,
  backgroundColor: selected
    ? theme.date_picker_selected_highlight
    : theme.background_color,
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: selected
    ? theme.primary_color_background_inverse
    : theme.background_color_disabled,
  color: selected ? theme.primary_color_background : theme.text_color,
  ":focus": {
    boxShadow: "none",
  },
}));

interface Props {
  integrations: DataIntegrationEntity[];
  validationErrorFilters: string[] | null;
  onInteraction: (interaction: ValidationErrorListControls.Interaction) => void;
}

export function ValidationErrorListControls(props: Props): JSX.Element | null {
  const theme = useTheme();

  const providerTypes = sortBy(
    uniq(props.integrations.map((integration) => integration.providerType))
  );
  return (
    <Flex direction="column" marginTop={theme.space_md}>
      <Text
        appearance="h5"
        style={{ letterSpacing: "0.5px" }}
        fontWeight={500}
        color={theme.text_color_caption}
      >
        {copyText.tabLabelIntegrations.toLocaleUpperCase()}
      </Text>
      <Flex marginTop={theme.space_sm}>
        <StyledButton
          iconEnd={
            props.validationErrorFilters === null ? (
              <Icon color={theme.primary_color_background} icon={faTimes} />
            ) : undefined
          }
          onClick={() =>
            props.onInteraction({
              type: ValidationErrorListControls.INTERACTION_FILTER_PROVIDER_CLICKED,
              providerType: null,
            })
          }
          selected={props.validationErrorFilters === null}
          size="tiny"
        >
          {copyText.validationFilterLabel_ALL}
        </StyledButton>
        {providerTypes.map((provider) => {
          const isSelected = props.validationErrorFilters?.includes(provider);
          return (
            <StyledButton
              selected={isSelected}
              size="tiny"
              iconEnd={isSelected ? <Icon icon={faTimes} /> : undefined}
              marginLeft={theme.space_xs}
              onClick={() =>
                props.onInteraction({
                  type: ValidationErrorListControls.INTERACTION_FILTER_PROVIDER_CLICKED,
                  providerType: provider,
                })
              }
            >
              {provider}
            </StyledButton>
          );
        })}
      </Flex>
    </Flex>
  );
}

ValidationErrorListControls.INTERACTION_FILTER_PROVIDER_CLICKED =
  `ValidationErrorListControls.INTERACTION_FILTER_PROVIDER_CLICKED` as const;

interface InteractionFilterProviderClicked {
  type: typeof ValidationErrorListControls.INTERACTION_FILTER_PROVIDER_CLICKED;
  providerType: CloudProviderType | null;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ValidationErrorListControls {
  export type Interaction = InteractionFilterProviderClicked;
}

export default ValidationErrorListControls;
