import { createStructParam } from "@/lib/use-query-params";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { AWSKubernetesInstanceEntity } from "../types";
import { createSortingUtils } from "@/utils/sort";

type TableData = {
  accountID: string;
  nodeName: string;
  instanceId: string;
  coveredCost: number;
  cpuTotal: number;
  cpuUsed: number;
  memoryTotal: number;
  memoryUsed: number;
  name: string;
  onDemandCost: number;
  totalCost: number;
};

type Props = {
  instances: AWSKubernetesInstanceEntity[];
  isLoadingInstances: boolean;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const { numberSort, stringSort } = createSortingUtils<TableData>({
  validKeys: [
    "accountID",
    "coveredCost",
    "cpuTotal",
    "cpuUsed",
    "memoryTotal",
    "memoryUsed",
    "name",
    "onDemandCost",
    "totalCost",
  ],
});

export default function AWSKubernetesInstanceTable(props: Props) {
  const columnHelper = createColumnHelper<TableData>();

  const [sortRule, setSortRule] = useQueryParam(
    "instance_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const tableData = useMemo(
    (): TableData[] =>
      props.instances.map((instance) => ({
        accountID: instance.accountID,
        nodeName: instance.nodeName,
        instanceId: instance.instanceId,
        coveredCost: instance.coveredCost,
        cpuTotal: instance.cpuTotal,
        cpuUsed: instance.cpuTotal ? instance.cpuUsed / instance.cpuTotal : 0,
        memoryTotal: instance.memoryTotal,
        memoryUsed: instance.memoryTotal
          ? instance.memoryUsed / instance.memoryTotal
          : 0,
        name: instance.name,
        onDemandCost: instance.onDemandCost,
        totalCost: instance.totalCost,
      })),
    [props.instances]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("nodeName", {
        header: copyText.awsKubernetesInstanceHeader_nodeName,
        size: 180,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("instanceId", {
        header: copyText.awsKubernetesInstanceHeader_instanceId,
        size: 180,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsKubernetesInstanceGroupHeader_totalCost,
        meta: { align: "right" },
        size: 120,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("onDemandCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsKubernetesInstanceGroupHeader_onDemandCost,
        meta: { align: "right" },
        size: 145,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("coveredCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsKubernetesInstanceGroupHeader_coveredCost,
        meta: { align: "right" },
        size: 130,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("cpuUsed", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.awsKubernetesInstanceGroupHeader_cpuUsed,
        meta: { align: "right" },
        size: 135,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("cpuTotal", {
        cell: ({ getValue }) => <>{formatNumber(getValue())}</>,
        header: copyText.awsKubernetesInstanceGroupHeader_cpuTotal,
        meta: { align: "right" },
        size: 110,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("memoryUsed", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.awsKubernetesInstanceGroupHeader_memoryUsed,
        meta: { align: "right" },
        size: 155,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("memoryTotal", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsKubernetesInstanceGroupHeader_memoryTotal,
        meta: { align: "right" },
        size: 110,
        sortingFn: numberSort,
      }),
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      initialState={{ sorting: [sortRule] }}
      isLoading={props.isLoadingInstances}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}
