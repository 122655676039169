import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AWSKubernetesInstanceGroupEntity,
  AWSKubernetesInstanceGroupFilters,
} from "../types";
import { createSortingUtils } from "@/utils/sort";

type TableData = {
  accountID: string;
  coveredCost: number;
  cpuTotal: number;
  cpuUsed: number;
  memoryTotal: number;
  memoryUsed: number;
  name: string;
  onDemandCost: number;
  totalCost: number;
};

type Props = {
  instanceGroups: AWSKubernetesInstanceGroupEntity[];
  isLoadingInstanceGroups: boolean;
  onInteraction: (
    interaction: AWSKubernetesInstanceGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const { numberSort, stringSort } = createSortingUtils<TableData>({
  validKeys: [
    "accountID",
    "coveredCost",
    "cpuTotal",
    "cpuUsed",
    "memoryTotal",
    "memoryUsed",
    "name",
    "onDemandCost",
    "totalCost",
  ],
});

function AWSKubernetesInstanceGroupTable(props: Props) {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const tableData = useMemo(
    () =>
      props.instanceGroups.map((instanceGroup) => ({
        accountID: instanceGroup.accountID,
        coveredCost: instanceGroup.coveredCost,
        cpuTotal: instanceGroup.cpuTotal,
        cpuUsed: instanceGroup.cpuTotal
          ? instanceGroup.cpuUsed / instanceGroup.cpuTotal
          : 0,
        memoryTotal: instanceGroup.memoryTotal,
        memoryUsed: instanceGroup.memoryTotal
          ? instanceGroup.memoryUsed / instanceGroup.memoryTotal
          : 0,
        name: instanceGroup.name,
        onDemandCost: instanceGroup.onDemandCost,
        totalCost: instanceGroup.totalCost,
      })),
    [props.instanceGroups]
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "viewInstances",
        cell: ({ row }) => (
          <Tooltip content={copyText.awsKubernetesInstanceGroupHeaderTooltip}>
            <LinkWithSearchParams
              searchParams={{
                selected_group: getSelectedGroupJSON(row.original),
              }}
            >
              <Button
                iconStart={<Icon icon={faTableList} />}
                primary
                size="tiny"
              />
            </LinkWithSearchParams>
          </Tooltip>
        ),
        enableSorting: false,
        size: 50,
      }),
      columnHelper.accessor("name", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSKubernetesInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "name",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.awsKubernetesTableNull}
          </Text>
        ),
        header: copyText.awsKubernetesInstanceGroupHeader_name,
        size: 120,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("accountID", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSKubernetesInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "accountID",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.awsKubernetesTableNull}
          </Text>
        ),
        header: () => (
          <Tooltip
            content={
              <Box width={200}>
                <Text color={theme.text_color_inverse}>
                  {copyText.awsIAMListPermissionTooltipMessage}
                </Text>
              </Box>
            }
          >
            {copyText.awsKubernetesInstanceGroupHeader_accountID}
          </Tooltip>
        ),
        sortingFn: stringSort,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsKubernetesInstanceGroupHeader_totalCost,
        meta: { align: "right" },
        size: 120,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("onDemandCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsKubernetesInstanceGroupHeader_onDemandCost,
        meta: { align: "right" },
        size: 145,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("coveredCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsKubernetesInstanceGroupHeader_coveredCost,
        meta: { align: "right" },
        size: 130,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("cpuUsed", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.awsKubernetesInstanceGroupHeader_cpuUsed,
        meta: { align: "right" },
        size: 135,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("cpuTotal", {
        cell: ({ getValue }) => <>{formatNumber(getValue())}</>,
        header: copyText.awsKubernetesInstanceGroupHeader_cpuTotal,
        meta: { align: "right" },
        size: 110,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("memoryUsed", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.awsKubernetesInstanceGroupHeader_memoryUsed,
        meta: { align: "right" },
        size: 155,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("memoryTotal", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsKubernetesInstanceGroupHeader_memoryTotal,
        meta: { align: "right" },
        size: 110,
        sortingFn: numberSort,
      }),
    ],
    [props.instanceGroups]
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      initialState={{ sorting: [sortRule] }}
      isLoading={props.isLoadingInstanceGroups}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

function getSelectedGroupJSON(datum: TableData) {
  try {
    return JSON.stringify({
      name: datum.name,
      accountID: datum.accountID,
    });
  } catch {
    return "";
  }
}

AWSKubernetesInstanceGroupTable.INTERACTION_FILTER_CLICKED =
  `AWSKubernetesInstanceGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AWSKubernetesInstanceGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof AWSKubernetesInstanceGroupFilters;
  filterValue: AWSKubernetesInstanceGroupFilters[keyof AWSKubernetesInstanceGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSKubernetesInstanceGroupTable {
  export type Interaction = InteractionFilterClicked;
}

export default AWSKubernetesInstanceGroupTable;
