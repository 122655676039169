import { createStructParam } from "@/lib/use-query-params";
import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import {
  formatNumber,
  formatNumberRounded,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { GCPCloudRunService } from "../types";
import { createSortingUtils } from "@/utils/sort";

type TableData = {
  cpuAllocationTime: number;
  memoryAllocationTime: number;
  peakInstanceCount: number;
  projectId: string;
  region: string;
  requestCount: number;
  revisionName: string;
  serviceName: string;
};

type Props = {
  isLoading: boolean;
  services: GCPCloudRunService[];
  onClose: () => void;
};

const sortRuleStruct = z.object({
  id: z.string(),
  desc: z.boolean(),
});

const { stringSort, numberSort } = createSortingUtils<TableData>();

export default function GCPCloudRunServiceTable(props: Props) {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  const [sortRule, setSortRule] = useQueryParam(
    "service_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cpuAllocationTime",
    })
  );

  const [searchText, setSearchText] = useState("");

  const tableData = useMemo(
    () => getTableData(props.services),
    [props.services]
  );

  const filteredData = useMemo(() => {
    if (searchText.length === 0) return tableData;

    return tableData.filter((datum) => {
      const str = searchText.toLowerCase();
      const serviceName = datum.serviceName.toLowerCase();
      const revisionName = datum.revisionName.toLowerCase();

      return serviceName.includes(str) || revisionName.includes(str);
    });
  }, [tableData, searchText]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("serviceName", {
        cell: ({ row }) => (
          <>{`${row.original.serviceName} / ${row.original.projectId}`}</>
        ),
        header: copyText.cloudRunTableServiceHeader_serviceName,
        meta: { align: "left" },
        size: 220,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("revisionName", {
        header: copyText.cloudRunTableServiceHeader_revisionName,
        meta: { align: "left" },
        size: 220,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("cpuAllocationTime", {
        cell: ({ getValue }) => (
          <>{`${formatNumberRounded(getValue())} vCPU-seconds`}</>
        ),
        header: copyText.cloudRunTableServiceHeader_cpuAllocationTime,
        size: 220,
        meta: { align: "right" },
        sortingFn: numberSort,
      }),
      columnHelper.accessor("memoryAllocationTime", {
        cell: ({ getValue }) => (
          <>{`${prettyBytes(getValue(), { binary: true })}-seconds`}</>
        ),
        header: copyText.cloudRunTableServiceHeader_memoryAllocationTime,
        size: 220,
        meta: { align: "right" },
        sortingFn: numberSort,
      }),
      columnHelper.accessor("peakInstanceCount", {
        header: copyText.cloudRunTableServiceHeader_peakInstanceCount,
        meta: { align: "right" },
        sortingFn: numberSort,
      }),
      columnHelper.accessor("requestCount", {
        cell: ({ getValue }) => <>{formatNumber(getValue())}</>,
        header: copyText.cloudRunTableServiceHeader_requestCount,
        meta: { align: "right" },
        sortingFn: numberSort,
      }),
    ],
    []
  );

  return (
    <Modal isOpen showCloseButton onClose={props.onClose} minWidth={1100}>
      <Modal.Header>
        <Flex
          justifyContent="space-between"
          marginLeft={theme.space_sm}
          width="100%"
        >
          <Text fontSize={theme.h4_fontSize}>
            {copyText.cloudRunTableServicesTitle}
          </Text>
          <Flex alignItems="center">
            <Box width={250} marginRight={theme.space_lg}>
              <TextInput
                disabled={!props.services.length}
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchInputPlaceholder}
                size="medium"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Box>

            <CSVLink
              data={flattenDataToCSV(filteredData)}
              filename={`Cloud-Run-Services-${formatDate(new Date(), "MM-dd-yyyy")}`}
            >
              <Button
                iconStart={<Icon color="inherit" icon={faFileExport} />}
                primary
                size="small"
                marginRight={theme.space_md}
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Table
          columns={columns}
          data={filteredData}
          initialState={{ sorting: [sortRule] }}
          isLoading={props.isLoading}
          onChangeSortBy={([newSortRule]) =>
            setSortRule(newSortRule, "replaceIn")
          }
          showPagination
          sortable
        />
      </Modal.Body>
    </Modal>
  );
}

function getTableData(services: GCPCloudRunService[]): TableData[] {
  return services.map((service) => ({
    cpuAllocationTime: service.cpuAllocationTime ?? 0,
    memoryAllocationTime: service.memoryAllocationTime ?? 0,
    peakInstanceCount: service.peakInstanceCount ?? 0,
    projectId: service.projectId ?? copyText.cloudRunTableNull,
    region: service.region ?? copyText.cloudRunTableNull,
    requestCount: service.requestCount ?? 0,
    revisionName: service.revisionName ?? copyText.cloudRunTableNull,
    serviceName: service.serviceName ?? copyText.cloudRunTableNull,
  }));
}

function flattenDataToCSV(data: TableData[]) {
  if (!data.length) {
    return [];
  }

  return data.map((datum) => ({
    projectId: datum.projectId,
    region: datum.region,
    serviceName: datum.serviceName,
    revisionName: datum.revisionName,
    cpuAllocationTime: datum.cpuAllocationTime,
    memoryAllocationTime: datum.memoryAllocationTime,
    peakInstanceCount: datum.peakInstanceCount,
    requestCount: datum.requestCount,
  }));
}
