import externalLinks from "@/constants/externalLinks";
import IconAlibabaCloud from "@/ui-lib/icons/IconAlibabaCloud";
import IconAwsCloud from "@/ui-lib/icons/IconAws";
import IconAzure from "@/ui-lib/icons/IconAzure";
import IconGCP from "@/ui-lib/icons/IconGCP";
import IconMongoDb from "@/ui-lib/icons/IconMongoDb";
import IconOracleCloud from "@/ui-lib/icons/IconOracleCloud";
import IconSnowflake from "@/ui-lib/icons/IconSnowflake";
import { useTheme } from "@emotion/react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { DataIntegrationEntity, Validation } from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { sortBy, uniq } from "lodash";
import React, { useState } from "react";
import copyText from "../copyText";
import { getValidationsWithErrors } from "../utils";

interface Props {
  integrations: DataIntegrationEntity[];
  validationErrorFilters: string[] | null;
  onClose: () => void;
  onInteraction: (interaction: ValidationErrorList.Interaction) => void;
}

export function ValidationErrorList(props: Props): JSX.Element | null {
  const theme = useTheme();
  const [selectedValidationErrorList, setSelectedValidationErrorList] =
    useState<{
      [key: string]: number[];
    }>({});

  const filteredByProviderType = sortBy(
    props.integrations,
    "providerType"
  ).filter((validationErrors) => {
    if (props.validationErrorFilters) {
      return props.validationErrorFilters.includes(
        validationErrors.providerType
      );
    } else {
      return validationErrors;
    }
  });

  const handleSelectedValidationErrorList = (integrationID, index) => {
    const selectedIntegrationError =
      selectedValidationErrorList[integrationID] ?? [];
    if (
      selectedIntegrationError.length === 0 ||
      !selectedIntegrationError.includes(index)
    ) {
      setSelectedValidationErrorList({
        ...selectedValidationErrorList,
        [integrationID]: [...selectedIntegrationError, index],
      });
    } else {
      const updatedSelectedValidationErrorList =
        selectedIntegrationError.filter((validation) => validation !== index);
      setSelectedValidationErrorList({
        ...selectedValidationErrorList,
        [integrationID]: uniq(updatedSelectedValidationErrorList),
      });
    }
  };

  const renderIntegrationBanner = (
    integration: DataIntegrationEntity,
    errorCountText: string
  ) => {
    return (
      <Flex
        alignItems="center"
        justifyContent="space-between"
        backgroundColor={theme.background_color}
        padding={theme.space_md}
        border={`1px solid ${theme.background_color_disabled}`}
      >
        <Flex alignItems="center">
          <Flex
            alignItems="center"
            backgroundColor="white"
            border={`2px solid ${theme.background_color_disabled}`}
            borderRadius="8px"
            height={40}
            marginRight={theme.space_sm}
            justifyContent="center"
            width={40}
          >
            {providerIcon(integration.providerType)}
          </Flex>
          <Text fontWeight={600} fontSize={theme.fontSize_base}>
            {integration.name}
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Box marginRight={theme.space_sm}>
            {providerDocLink(integration.providerType)}
          </Box>
          <Tooltip content={copyText.validationErrorsTooltip}>
            <Button
              onClick={() =>
                props.onInteraction({
                  type: ValidationErrorList.INTERACTION_CONFIGURE_BUTTON_CLICKED,
                  integrationID: integration.id,
                  providerType: integration.providerType,
                })
              }
              size="tiny"
            >
              <Text>{errorCountText}</Text>
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    );
  };

  const renderIntegrationErrors = (
    integrationID: string,
    validation: Validation,
    index: number
  ) => {
    const selectedIntegrationError =
      selectedValidationErrorList[integrationID] ?? [];
    const isSelected = selectedIntegrationError.includes(index);

    return (
      <Flex
        padding={` 0px ${theme.space_lg} ${theme.space_md} ${theme.space_lg}`}
        marginTop={theme.space_md}
        gap={theme.space_xs}
        direction="column"
        borderBottom={`1px solid ${theme.border_color}`}
        key={validation.name}
        cursor="pointer"
        onClick={() => handleSelectedValidationErrorList(integrationID, index)}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize={theme.fontSize_base} fontWeight={600} truncate>
            <Tooltip
              hide={validation.name.length < 40}
              content={`${validation.errorType}: ${validation.name}`}
            >
              {`${validation.errorType}: ${validation.name}`}
            </Tooltip>
          </Text>
          <Box>
            <Icon
              clickable
              size="lg"
              icon={isSelected ? faChevronUp : faChevronDown}
              color={theme.text_color_caption}
            />
          </Box>
        </Flex>
        <Flex>
          <Text color={theme.text_color_caption}>
            {validation.ternaryError}
          </Text>
        </Flex>
        {validation.error.length > 0 && isSelected && (
          <Box
            backgroundColor={theme.background_color}
            padding={theme.space_md}
            borderRadius={theme.borderRadius_1}
          >
            <Text overflowWrap="break-word">
              {copyText.validationErrorText}
              {validation.error}
            </Text>
          </Box>
        )}
      </Flex>
    );
  };

  return (
    <>
      {filteredByProviderType.map((integration, index) => {
        const isLast = index === filteredByProviderType.length - 1;
        const unsuccessfulValidations = getValidationsWithErrors(integration);
        const errorCountText = copyText.validationErrorsCountText.replace(
          "%count%",
          unsuccessfulValidations.length.toString()
        );
        return (
          <Flex
            direction="column"
            marginBottom={isLast ? theme.space_xl : 0}
            key={integration.id}
          >
            {renderIntegrationBanner(integration, errorCountText)}
            {unsuccessfulValidations.map((validation, index) =>
              renderIntegrationErrors(integration.id, validation, index)
            )}
          </Flex>
        );
      })}
    </>
  );
}

ValidationErrorList.INTERACTION_CONFIGURE_BUTTON_CLICKED =
  `ValidationErrorList.INTERACTION_CONFIGURE_BUTTON_CLICKED` as const;

interface InteractionConfigureButtonClicked {
  type: typeof ValidationErrorList.INTERACTION_CONFIGURE_BUTTON_CLICKED;
  integrationID: string;
  providerType: CloudProviderType;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ValidationErrorList {
  export type Interaction = InteractionConfigureButtonClicked;
}

export default ValidationErrorList;

function providerIcon(providerType) {
  let iconComponent: JSX.Element = <IconGCP size={24} />;
  switch (providerType) {
    case CloudProviderType.ALIBABA:
      iconComponent = <IconAlibabaCloud size={24} />;
      break;
    case CloudProviderType.AWS:
      iconComponent = <IconAwsCloud size={24} />;
      break;
    case CloudProviderType.AZURE:
      iconComponent = <IconAzure size={24} />;
      break;
    case CloudProviderType.MONGO_DB:
      iconComponent = <IconMongoDb size={24} />;
      break;
    case CloudProviderType.OCI:
      iconComponent = <IconOracleCloud size={24} />;
      break;
    case CloudProviderType.SNOWFLAKE:
      iconComponent = <IconSnowflake size={24} />;
      break;
    default:
      break;
  }
  return iconComponent;
}

function providerDocLink(providerType) {
  let link = externalLinks.readmeGCPOnboardingDocumentation;
  switch (providerType) {
    case CloudProviderType.ALIBABA:
      link = externalLinks.readmeAlibabaOnboardingDocumentation;
      break;
    case CloudProviderType.AWS:
      link = externalLinks.readmeAwsOnboardingDocumentation;
      break;
    case CloudProviderType.AZURE:
      link = externalLinks.readmeAzureOnboardingDocumentation;
      break;
    case CloudProviderType.MONGO_DB:
      link = externalLinks.readmeMongoDBOnboardingDocumentation;
      break;
    case CloudProviderType.OCI:
      link = externalLinks.readmeOCIOnboardingDocumentation;
      break;
    case CloudProviderType.SNOWFLAKE:
      link = externalLinks.readmeSnowflakeOnboardingDocumentation;
      break;
    default:
      break;
  }
  return (
    <Text>
      <a href={link} rel="noreferrer" target="_blank">
        {copyText.dataStatusDocumentation}
      </a>
    </Text>
  );
}
