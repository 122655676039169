import timing from "@/constants/timing";
import { Theme, useTheme } from "@emotion/react";
import {
  faArrowDown,
  faArrowUp,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COMPARISON_KEY } from "@ternary/api-lib/ui-lib/charts/utils";
import Pagination from "@ternary/api-lib/ui-lib/components/Pagination";
import ResourceSelector from "@ternary/api-lib/ui-lib/components/ResourceSelector";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { noop } from "lodash";
import React, { useEffect, useMemo } from "react";
import ContentLoader from "react-content-loader";
import {
  Column,
  PluginHook,
  Row as RowType,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import useRefFn from "../../../hooks/useRefFn";
import copyText from "../../copyText";
import {
  Cell,
  FooterCell,
  FooterRow,
  HeaderCell,
  HeaderRow,
  Row,
  StyledTable,
} from "./styledLegacy";
import { TableProps } from "./types";

/**
 * @deprecated This component is deprecated.
 * Please use the new Table component from 'api-lib' instead.
 */
export function TableLegacy<D extends Record<string, unknown>>(
  props: TableProps<D>
): JSX.Element {
  const theme = useTheme();

  const pluginList: PluginHook<D>[] = [useFlexLayout];

  if (props.sortable) {
    pluginList.push(useSortBy);
  }

  if (props.showPagination || props.truncateRows) {
    pluginList.push(usePagination);
  }

  const data = useMemo(
    () => (props.isLoading ? new Array(10).fill({}) : props.data),
    [props.data, props.isLoading]
  );

  const columns = useMemo(() => {
    if (props.isLoading) {
      return props.columns.map(
        (column): Column<D> => ({
          ...column,
          Cell: <Skeleton height={theme.h3_fontSize} theme={theme} />,
          NoAccessorCell: undefined,
        })
      );
    }
    return props.columns;
  }, [props.columns, props.isLoading]);

  const {
    getTableProps,
    getTableBodyProps,
    footerGroups,
    gotoPage,
    headerGroups,
    page,
    pageCount,
    prepareRow,
    rows: allRows,
    state: { pageIndex, pageSize, sortBy },
  } = useTable<D>(
    {
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      columns,
      data,
      disableSortRemove: true,
      initialState: props.initialState,
    },
    ...pluginList
  );

  useEffect(() => {
    if (sortBy && props.onChangeSortBy) {
      const sortRules = sortBy.map((sortBy: { id: string; desc?: boolean }) => {
        return {
          id: sortBy.id,
          desc: !!sortBy.desc,
        };
      });
      props.onChangeSortBy(sortRules);
    }
  }, [sortBy?.[0]?.id, sortBy?.[0]?.desc]);

  const pinnedColumns = props.pinnedColumns ?? 0;
  const pinnedWidths =
    columns.slice(0, pinnedColumns).reduce((accum: number[], column) => {
      if (column.width && typeof column.width === "number") {
        accum.push(column.width);
      }
      return accum;
    }, []) ?? Array(pinnedColumns).fill(250);

  function getLeftPosition(index: number): number | undefined {
    if (index >= pinnedColumns) {
      return undefined;
    }

    //Shrink column sizes when more than 3 dimensions are selected
    const columnWidths =
      pinnedColumns > 3 ? Array(pinnedColumns).fill(150) : pinnedWidths;

    let left = 0;

    columnWidths.forEach((width, colIndex) => {
      if (colIndex < index) {
        left += width;
      }
    });

    return left;
  }

  function handleClickRow(row: RowType<D>): void {
    if (!props.onClick) return;
    props.onClick(row.original);
  }

  const handleChangePage = useRefFn(props.onChangePage ?? (() => {}));
  useEffect(() => {
    if (props.isLoading || !page) {
      handleChangePage([], 0);
      return;
    }

    handleChangePage(
      page.map((row) => row.original),
      pageIndex
    );
  }, [page, pageIndex, props.isLoading]);

  const rows = props.showPagination || props.truncateRows ? page : allRows;

  return (
    <>
      <StyledTable {...getTableProps()} compact={props.compact} width="100%">
        <thead
          style={{
            position: props.showPagination ? "inherit" : "sticky",
            top: 0,
            zIndex: 2,
          }}
        >
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } =
              headerGroup.getHeaderGroupProps();

            return (
              <HeaderRow
                key={key}
                compact={props.compact}
                {...headerGroupProps}
              >
                {headerGroup.headers.map((column, columnIndex) => {
                  const { key, ...headerProps } = column.getHeaderProps();
                  const allProps = {
                    ...(column.canSort ? column.getSortByToggleProps() : {}),
                    ...headerProps,
                  };

                  const sortArrow =
                    column.canSort && column.isSorted ? (
                      <Box marginHorizontal={theme.space_xs}>
                        <FontAwesomeIcon
                          color={theme.primary_color_text}
                          icon={column.isSortedDesc ? faArrowDown : faArrowUp}
                        />
                      </Box>
                    ) : null;

                  return (
                    <HeaderCell
                      key={key}
                      align={column.align}
                      sortable={column.canSort}
                      left={getLeftPosition(columnIndex)}
                      isPinned={props.pinnedColumns !== undefined}
                      {...allProps}
                    >
                      <Flex>
                        {column.align === "right" ? sortArrow : null}
                        {column.render("Header")}
                        {column.align !== "right" ? sortArrow : null}
                      </Flex>
                    </HeaderCell>
                  );
                })}
              </HeaderRow>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length === 0 && !props.isLoading && (
            <tr>
              <EmptyPlaceholder
                height={"500px"}
                icon={faList}
                loading={Boolean(props.isLoading)}
                text={copyText.noDataPlaceholderMessage}
              />
            </tr>
          )}
          {rows.map((row) => {
            prepareRow(row);
            const { key, ...restProps } = row.getRowProps();

            const clickable = isClickable(
              row,
              props.clickableRows,
              props.disableRow
            );

            const rowProps = {
              alternate: props.alternateRows,
              compact: props.compact,
              clickable: props.isLoading ? false : clickable,
              disabled: props.disableRow ? props.disableRow(row) : undefined,
              footer: props.footer,
              height: props.rowHeight,
              onClick:
                !props.isLoading && clickable
                  ? () => handleClickRow(row)
                  : noop,
              ...restProps,
            };

            const isRowHighlighted = props.highlightedRows?.some((value) =>
              Object.values(row.values).includes(value)
            );

            const resourceID = row.original.resourceID as string | undefined;
            const resourceContext = row.original.resourceContext as
              | { forecast: number }
              | undefined;
            const resourceName = row.original.resourceName as
              | string
              | undefined;

            return (
              <Row key={key} {...rowProps}>
                {!props.isLoading &&
                  props.resourceType &&
                  resourceID &&
                  resourceName && (
                    <ResourceSelector
                      key={key}
                      resourceID={resourceID}
                      resourceContext={resourceContext}
                      resourceName={resourceName}
                      resourceType={props.resourceType}
                    />
                  )}
                {row.cells.map((cell, index) => {
                  const { key, ...restProps } = cell.getCellProps();
                  const isSelected =
                    props.selectedRowID !== undefined &&
                    props.selectedRowID === row.original.id;

                  const cellProps = {
                    align: cell.column.align,
                    clickable: clickable,
                    disabled: props.disableRow
                      ? props.disableRow(row)
                      : undefined,
                    compact: props.compact,
                    footer: props.footer,
                    isCellMasked: key.toString().includes(COMPARISON_KEY),
                    isSelected,
                    ...restProps,
                  };

                  const cellStyle = {
                    ...cellProps.style,
                    ...(isRowHighlighted
                      ? {
                          backgroundColor: theme.secondary_color_background,
                        }
                      : {}),
                  };

                  return (
                    <Cell
                      key={key}
                      left={getLeftPosition(index)}
                      {...cellProps}
                      style={cellStyle}
                      onClick={(event) =>
                        cell.column.disableClick
                          ? event.stopPropagation()
                          : noop
                      }
                    >
                      {cell.column.truncate && !props.isLoading ? (
                        <Box width="100%">
                          <Tooltip content={cell.value} delayHide={250}>
                            <Text
                              color={
                                isSelected
                                  ? theme.text_color_inverse
                                  : theme.text_color
                              }
                              truncate
                            >
                              {cell.value}
                            </Text>
                          </Tooltip>
                        </Box>
                      ) : typeof cell.column.NoAccessorCell === "function" ? (
                        cell.render("NoAccessorCell")
                      ) : (
                        cell.render("Cell")
                      )}
                    </Cell>
                  );
                })}
              </Row>
            );
          })}
        </tbody>
        {props.footer && (
          <tfoot>
            {footerGroups.map((group) => {
              const { key, ...footerProps } = group.getFooterGroupProps();
              return (
                <FooterRow
                  key={key}
                  compact={props.compact}
                  footerDelta={props.footerDelta}
                  {...footerProps}
                >
                  {group.headers.map((column, i: number) => {
                    const { key, ...columnProps } = column.getFooterProps();
                    const cellProps = {
                      align: column.align,
                      clickable: props.clickableRows,
                      isSelected: false,
                      ...columnProps,
                    };

                    return (
                      <FooterCell
                        key={key}
                        left={getLeftPosition(i)}
                        {...cellProps}
                      >
                        <Box width="100%">
                          {i === 0
                            ? copyText.dataTableTotalsHeader
                            : column.render("Footer")}
                          {props.footerDelta && i === 0 && (
                            <Text color={theme.text_color} truncate>
                              {copyText.dataTableTotalsDeltasHeader}
                            </Text>
                          )}
                        </Box>
                      </FooterCell>
                    );
                  })}
                </FooterRow>
              );
            })}
          </tfoot>
        )}
      </StyledTable>
      {props.data.length > 0 && props.showPagination ? (
        <Pagination
          currentPageIndex={pageIndex}
          loading={Boolean(props.isLoading)}
          pageSize={pageSize}
          totalPageCount={pageCount}
          totalRows={allRows.length}
          onPageChange={gotoPage}
        />
      ) : null}
    </>
  );
}

export function Skeleton(props: { height: string; theme: Theme }): JSX.Element {
  const RADIUS = Number(props.theme.borderRadius_1.replace("px", ""));

  return (
    <ContentLoader
      backgroundColor={props.theme.loading_skeleton_bg_color}
      foregroundColor={props.theme.loading_skeleton_fg_color}
      speed={timing.skeletonAnimationTime}
      viewBox="0 0 300 100"
      preserveAspectRatio="none"
      style={{
        width: "80%",
        height: props.height,
      }}
    >
      <rect x="0" y="0" rx={RADIUS} ry={RADIUS} width="300" height="90" />
    </ContentLoader>
  );
}

function isClickable(
  row,
  clickableRows?: boolean,
  disableRow?: (row) => boolean
) {
  if (clickableRows && disableRow) {
    return !disableRow(row);
  }
  return clickableRows;
}
