import { createStructParam } from "@/lib/use-query-params";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { AWSComputeInstance } from "../types";
import { createSortingUtils } from "@/utils/sort";

type TableData = {
  cpuUtilization: number;
  diskReadBytes: number;
  diskWriteBytes: number;
  instanceId: string;
  instanceType: string;
  instanceUnits: number;
  networkInBytes: number;
  networkOutBytes: number;
  productMemoryBytes: number;
  productVCPU: number;
  totalCost: number;
};

type Props = {
  instances: AWSComputeInstance[];
  isLoadingInstances: boolean;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const { numberSort, stringSort } = createSortingUtils<TableData>({
  validKeys: [
    "cpuUtilization",
    "diskReadBytes",
    "diskWriteBytes",
    "instanceId",
    "instanceType",
    "networkInBytes",
    "networkOutBytes",
    "productMemoryBytes",
    "productVCPU",
    "totalCost",
  ],
});

export default function AWSComputeInstanceTable(props: Props) {
  const [sortRule, setSortRule] = useQueryParam(
    "instance_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const tableData = useMemo(
    () => getTableData(props.instances),
    [props.instances]
  );

  const columnHelper = createColumnHelper<TableData>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("instanceId", {
        cell: ({ getValue }) => (
          <>{getValue() || copyText.awsComputeTableNull}</>
        ),
        header: copyText.awsComputeTableInstanceHeader_instanceId,
        meta: { align: "left" },
        size: 180,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsComputeTableInstanceHeader_totalCost,
        meta: { align: "right" },
        size: 100,
        sortDescFirst: true,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("instanceType", {
        cell: ({ getValue }) => (
          <>{getValue() || copyText.awsComputeTableNull}</>
        ),
        header: copyText.awsComputeTableInstanceHeader_instanceType,
        meta: { align: "right" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("instanceUnits", {
        cell: ({ getValue }) => (
          <>{getValue() || copyText.awsComputeTableNull}</>
        ),
        header: copyText.awsComputeTableInstanceHeader_instanceUnits,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("productVCPU", {
        header: copyText.awsComputeTableInstanceHeader_productVCPU,
        meta: { align: "right" },
        size: 100,
        sortDescFirst: true,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("cpuUtilization", {
        cell: ({ getValue }) => <>{formatPercentage(getValue() / 100)}</>,
        header: copyText.awsComputeTableInstanceHeader_cpuUtilization,
        meta: { align: "right" },
        size: 100,
        sortDescFirst: true,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("networkInBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsComputeTableInstanceHeader_networkInBytes,
        meta: { align: "right" },
        size: 100,
        sortDescFirst: true,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("networkOutBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsComputeTableInstanceHeader_networkOutBytes,
        meta: { align: "right" },
        size: 100,
        sortDescFirst: true,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("diskReadBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsComputeTableInstanceHeader_diskReadBytes,
        meta: { align: "right" },
        size: 100,
        sortDescFirst: true,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("diskWriteBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsComputeTableInstanceHeader_diskWriteBytes,
        meta: { align: "right" },
        size: 100,
        sortDescFirst: true,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("productMemoryBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsComputeTableInstanceHeader_productMemoryBytes,
        meta: { align: "right" },
        size: 100,
        sortDescFirst: true,
        sortingFn: numberSort,
      }),
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      initialState={{ sorting: [sortRule] }}
      isLoading={props.isLoadingInstances}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
    />
  );
}

function getTableData(instances: AWSComputeInstance[]): TableData[] {
  return instances.map((instance) => ({
    cpuUtilization: instance.cpuUtilization,
    diskReadBytes: instance.diskReadBytes,
    diskWriteBytes: instance.diskWriteBytes,
    instanceId: instance.instanceId,
    instanceType: instance.instanceType,
    instanceUnits: instance.instanceUnits,
    networkInBytes: instance.networkInBytes,
    networkOutBytes: instance.networkOutBytes,
    productMemoryBytes: instance.productMemoryBytes,
    productVCPU: instance.productVCPU,
    totalCost: instance.totalCost,
  }));
}
