export const ActivityType = {
  ASSIGNEE_CHANGE: "ASSIGNEE_CHANGE",
  COMMENT: "COMMENT",
  STATUS_CHANGE: "STATUS_CHANGE",
} as const;

export type ActivityType = (typeof ActivityType)[keyof typeof ActivityType];

export const AlertRuleConditionType = {
  ANOMALY_DETECTION: "ANOMALY_DETECTION",
  FORECAST: "FORECAST",
  THRESHOLDING: "THRESHOLDING",
} as const;

export type AlertRuleConditionType =
  (typeof AlertRuleConditionType)[keyof typeof AlertRuleConditionType];

export const AlertRuleDirection = {
  DEFAULT: "DEFAULT",
  INCREASE_ONLY: "INCREASE_ONLY",
  DECREASE_ONLY: "DECREASE_ONLY",
} as const;

export type AlertRuleDirection =
  (typeof AlertRuleDirection)[keyof typeof AlertRuleDirection];

export const AlertRuleOperator = {
  GREATER_EQUAL: "GREATER_EQUAL",
} as const;

export type AlertRuleOperator =
  (typeof AlertRuleOperator)[keyof typeof AlertRuleOperator];

export const AlertRuleStatus = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
} as const;

export type AlertRuleStatus =
  (typeof AlertRuleStatus)[keyof typeof AlertRuleStatus];

export const AWSRateType = {
  RESERVED_INSTANCE: "RESERVED_INSTANCE",
  SAVINGS_PLAN: "SAVINGS_PLAN",
} as const;

export type AWSRateType = (typeof AWSRateType)[keyof typeof AWSRateType];

export const AWSSavingsPlanServiceType = {
  CSP: "CSP",
  EC2: "EC2",
  SAGEMAKER: "SAGEMAKER",
} as const;

export type AWSSavingsPlanServiceType =
  (typeof AWSSavingsPlanServiceType)[keyof typeof AWSSavingsPlanServiceType];

export const AWSRateAccountScope = {
  LINKED: "LINKED",
  PAYER: "PAYER",
} as const;

export type AWSRateAccountScope =
  (typeof AWSRateAccountScope)[keyof typeof AWSRateAccountScope];

export const AWSRateOfferingClass = {
  CONVERTIBLE: "CONVERTIBLE",
  STANDARD: "STANDARD",
} as const;

export type AWSRateOfferingClass =
  (typeof AWSRateOfferingClass)[keyof typeof AWSRateOfferingClass];

export const AWSRateRecommendationTerm = {
  ONE_YEAR: "ONE_YEAR",
  THREE_YEARS: "THREE_YEARS",
} as const;

export type AWSRateRecommendationTerm =
  (typeof AWSRateRecommendationTerm)[keyof typeof AWSRateRecommendationTerm];

export const AWSRateLookbackPeriod = {
  SEVEN_DAYS: "SEVEN_DAYS",
  SIXTY_DAYS: "SIXTY_DAYS",
  THIRTY_DAYS: "THIRTY_DAYS",
} as const;

export type AWSRateLookbackPeriod =
  (typeof AWSRateLookbackPeriod)[keyof typeof AWSRateLookbackPeriod];

export const AWSRatePaymentOption = {
  NO_UPFRONT: "NO_UPFRONT",
  PARTIAL_UPFRONT: "PARTIAL_UPFRONT",
  ALL_UPFRONT: "ALL_UPFRONT",
  LIGHT_UTILIZATION: "LIGHT_UTILIZATION",
  MEDIUM_UTILIZATION: "MEDIUM_UTILIZATION",
  HEAVY_UTILIZATION: "HEAVY_UTILIZATION",
} as const;

export type AWSRatePaymentOption =
  (typeof AWSRatePaymentOption)[keyof typeof AWSRatePaymentOption];

export const AWSRateTenancy = {
  DEDICATED: "DEDICATED",
  SHARED: "SHARED",
} as const;

export type AWSRateTenancy =
  (typeof AWSRateTenancy)[keyof typeof AWSRateTenancy];

export const AWSRateStatus = {
  ACTIVE: "ACTIVE",
  EXCHANGING: "EXCHANGING",
  PAYMENT_FAILED: "PAYMENT_FAILED",
  PENDING_PAYMENT: "PENDING_PAYMENT",
  RETIRED: "RETIRED",
} as const;

export type AWSRateStatus = (typeof AWSRateStatus)[keyof typeof AWSRateStatus];

export const AzureExportType = {
  ACTUALS: "ACTUALS",
  AMORTIZED: "AMORTIZED",
  FOCUS: "FOCUS",
} as const;

export type AzureExportType =
  (typeof AzureExportType)[keyof typeof AzureExportType];

export const AzureIntegrationType = {
  APNA: "APNA",
  AUTO: "AUTO",
  EA: "EA",
  EA_LEGACY: "EA_LEGACY",
  MCA: "MCA",
  MPA: "MPA",
  MPAv2: "MPAv2",
} as const;

export type AzureIntegrationType =
  (typeof AzureIntegrationType)[keyof typeof AzureIntegrationType];

export const AzureRateType = {
  RI: "RI",
  SP: "SP",
} as const;

export type AzureRateType = (typeof AzureRateType)[keyof typeof AzureRateType];

export const AzureRateRecommendationTerm = {
  ONE_YEAR: "ONE_YEAR",
  THREE_YEARS: "THREE_YEARS",
  FIVE_YEARS: "FIVE_YEARS",
} as const;

export type AzureRateRecommendationTerm =
  (typeof AzureRateRecommendationTerm)[keyof typeof AzureRateRecommendationTerm];

export const AzureCommitmentLookbackPeriod = {
  FOURTEEN_DAYS: "FOURTEEN_DAYS",
  SEVEN_DAYS: "SEVEN_DAYS",
  SIXTY_DAYS: "SIXTY_DAYS",
  THIRTY_DAYS: "THIRTY_DAYS",
} as const;

export type AzureCommitmentLookbackPeriod =
  (typeof AzureCommitmentLookbackPeriod)[keyof typeof AzureCommitmentLookbackPeriod];

export const BudgetThresholdWarningType = {
  DAILY_SPEND: "DAILY_SPEND",
  MONTHLY_SPEND: "MONTHLY_SPEND",
} as const;

export type BudgetThresholdWarningType =
  (typeof BudgetThresholdWarningType)[keyof typeof BudgetThresholdWarningType];

export const CaseStatus = {
  CLOSED: "CLOSED",
  OPEN: "OPEN",
} as const;

export type CaseStatus = (typeof CaseStatus)[keyof typeof CaseStatus];

export const CaseType = {
  INVESTIGATION: "INVESTIGATION",
  OPTIMIZATION: "OPTIMIZATION",
  TASK: "TASK",
} as const;

export type CaseType = (typeof CaseType)[keyof typeof CaseType];

export const ChartType = {
  AREA: "AREA",
  CLUSTERED_BAR: "CLUSTERED_BAR",
  KPI: "KPI",
  LINE: "LINE",
  PIE: "PIE",
  STACKED_AREA: "STACKED_AREA",
  STACKED_BAR: "STACKED_BAR",
  TABLE: "TABLE",
} as const;

export type ChartType = (typeof ChartType)[keyof typeof ChartType];

export const CloudCapability = {
  ACCESS_ANCESTORS_ARRAY: "Access Project Ancestors in Billing Export",
  ACCESS_TAGS_COLUMN_GCP: "Access Tags Column in Billing Export",
  ACCOUNT_USAGE_SNOWFLAKE: "Access Snowflake Account Usage",
  BIGQUERY_DATA: "Access BigQuery Usage Data",
  BILL_DATA_ALIBABA: "Access Alibaba Cloud Billing Data",
  BILL_DATA_AWS_CUR2: "Access CUR 2.0 Data",
  BILL_DATA_AWS_FOCUS: "Access FOCUS Native Export Data",
  BILL_DATA_AWS: "Access CUR Data",
  BILL_DATA_AZURE: "Access Azure Billing Export",
  BILL_DATA_MONGO_DB: "Access MongoDb Billing Data",
  BILL_DATA_OCI: "Access Oracle Cloud Billing Data",
  BILL_DATA_SNOWFLAKE: "Access Snowflake Billing Data",
  BILL_DATA: "Access BigQuery Billing Data",
  BUDGETS: "List Budgets",
  CARBON_FOOTPRINT: "Access GCP Carbon Footprint Data",
  COLLECT_METRICS_AWS: "Collect AWS Metrics",
  COLLECT_METRICS_GCP: "Collect GCP Metrics",
  DETAILED_BILLING_EXPORT: "Access BigQuery Resource-Level Billing Data",
  INVENTORY_EXPORT: "Asset Inventory Export",
  LIST_COMPUTE_COMMITMENTS: "List Compute Commitments",
  PASSTHROUGH_RECOMMENDATIONS: "List Pass-Through Recommendations",
  PRICING_EXPORT: "Access BigQuery Pricing Data Export",
} as const;

export type CloudCapability =
  (typeof CloudCapability)[keyof typeof CloudCapability];

export const SocialIntegrationType = {
  JIRA: "JIRA",
  SLACK: "SLACK",
} as const;

export type SocialIntegrationType =
  (typeof SocialIntegrationType)[keyof typeof SocialIntegrationType];

export const CloudProviderType = {
  ALIBABA: "ALIBABA",
  AWS: "AWS",
  AZURE: "AZURE",
  GCP: "GCP",
  MONGO_DB: "MONGO_DB",
  OCI: "OCI",
  SNOWFLAKE: "SNOWFLAKE",
} as const;

export type CloudProviderType =
  (typeof CloudProviderType)[keyof typeof CloudProviderType];

export const CostAlertEventType = {
  ANOMALY_DETECTED: "ANOMALY_DETECTED",
  THRESHOLD_BREACHED: "THRESHOLD_BREACHED",
} as const;

export type CostAlertEventType =
  (typeof CostAlertEventType)[keyof typeof CostAlertEventType];

export const CustomLabelOperator = {
  EQUALS: "equals",
  FUZZY_EQUALS: "fuzzy_equals",
  NOT_FUZZY_EQUALS: "not_fuzzy_equals",
  REGEX_MATCHES: "regex_matches",
  DOES_NOT_EXIST: "does_not_exist",
} as const;

export type CustomLabelOperator =
  (typeof CustomLabelOperator)[keyof typeof CustomLabelOperator];

export const CustomMetricDataType = {
  DIMENSION: "DIMENSION",
  MEASURE: "MEASURE",
  TIMESTAMP: "TIMESTAMP",
} as const;

export type CustomMetricDataType =
  (typeof CustomMetricDataType)[keyof typeof CustomMetricDataType];

export const DashboardScope = {
  GLOBAL: "GLOBAL",
  PRIVATE: "PRIVATE",
  SHARED: "SHARED",
} as const;

export type DashboardScope =
  (typeof DashboardScope)[keyof typeof DashboardScope];

export const DashboardType = {
  DEFAULT: "DEFAULT",
  SYSTEM_COPY: "SYSTEM_COPY",
  SYSTEM: "SYSTEM",
} as const;

export type DashboardType = (typeof DashboardType)[keyof typeof DashboardType];

export const DataSource = {
  ALERT_EVENTS: "ALERT_EVENTS",
  AWS_COMMITMENT_ALLOCATION: "AWS_COMMITMENT_ALLOCATION",
  AWS_COMMITMENT_CHART: "AWS_COMMITMENT_CHART",
  AWS_COMMITMENT_TABLE: "AWS_COMMITMENT_TABLE",
  AWS_COMPUTE_INSTANCES: "AWS_COMPUTE_INSTANCES",
  AWS_COMPUTE_UTILIZATION: "AWS_COMPUTE_UTILIZATION",
  AWS_COMPUTE_VISIBILITY: "AWS_COMPUTE_VISIBILITY",
  AWS_DATABASE_ELASTICACHE: "AWS_DATABASE_ELASTICACHE",
  AWS_DATABASE_INSTANCES: "AWS_DATABASE_INSTANCES",
  AWS_DATABASE_MEMORY_DB: "AWS_DATABASE_MEMORY_DB",
  AWS_DATABASE_VISIBILITY: "AWS_DATABASE_VISIBILITY",
  AWS_EBS_VISIBILITY: "AWS_EBS_VISIBILITY",
  AWS_EBS_VOLUMES: "AWS_EBS_VOLUMES",
  AWS_KUBERNETES_INSTANCES: "AWS_KUBERNETES_INSTANCES",
  AWS_KUBERNETES_NODE_COST: "AWS_KUBERNETES_NODE_COST",
  AWS_KUBERNETES_NODE_USAGE: "AWS_KUBERNETES_NODE_USAGE",
  AWS_OPEN_SEARCH_VISIBILITY: "AWS_OPEN_SEARCH_VISIBILITY",
  AWS_REDSHIFT_VISIBILITY: "AWS_REDSHIFT_VISIBILITY",
  AWS_STORAGE_VISIBILITY: "AWS_STORAGE_VISIBILITY",
  AZURE_COMMITMENT_CHART: "AZURE_COMMITMENT_CHART",
  AZURE_COMMITMENT_TABLE: "AZURE_COMMITMENT_TABLE",
  AZURE_COMPUTE_VISIBILITY: "AZURE_COMPUTE_VISIBILITY",
  AZURE_KUBERNETES_NODE_USAGE: "AZURE_KUBERNETES_NODE_USAGE",
  AZURE_SQL_VISIBILITY: "AZURE_SQL_VISIBILITY",
  AZURE_STORAGE_VISIBILITY: "AZURE_STORAGE_VISIBILITY",
  BIGQUERY_CAPACITY_COMMITTED: "BIGQUERY_CAPACITY_COMMITTED",
  BIGQUERY_COST: "BIGQUERY_COST",
  BIGQUERY_RESERVATION_USAGE: "BIGQUERY_RESERVATION_USAGE",
  BIGQUERY_STORAGE: "BIGQUERY_STORAGE",
  BIGQUERY_TIMELINE: "BIGQUERY_TIMELINE",
  BIGQUERY_USAGE: "BIGQUERY_USAGE",
  BILLING: "BILLING",
  CARBON_FOOTPRINT: "CARBON_FOOTPRINT",
  CLOUD_RUN_SERVICES: "CLOUD_RUN_SERVICES",
  CLOUD_RUN: "CLOUD_RUN",
  CLOUD_SPANNER_USAGE: "CLOUD_SPANNER_USAGE",
  CLOUD_SPANNER: "CLOUD_SPANNER",
  CLOUD_SQL_COST: "CLOUD_SQL_COST",
  CLOUD_SQL_INSTANCE_USAGE_DAILY: "CLOUD_SQL_INSTANCE_USAGE_DAILY",
  CLOUD_SQL_INSTANCE_USAGE: "CLOUD_SQL_INSTANCE_USAGE",
  COMMITTED_USE: "COMMITTED_USE",
  DETAILED_BILLING: "DETAILED_BILLING",
  EXTERNAL_METRICS: "EXTERNAL_METRICS",
  FOCUS_BILLING: "FOCUS_BILLING",
  GCP_BIGQUERY_COMMITMENT_INVENTORY: "GCP_BIGQUERY_COMMITMENT_INVENTORY",
  GCP_COMBINED_CUD_UTILIZATION: "GCP_COMBINED_CUD_UTILIZATION",
  GCP_COMPUTE_CUD_INVENTORY: "GCP_COMPUTE_CUD_INVENTORY",
  GCP_COMPUTE_CUD_SPEND_INVENTORY: "GCP_COMPUTE_CUD_SPEND_INVENTORY",
  GCP_COMPUTE_CUD_UTILIZATION: "GCP_COMPUTE_CUD_UTILIZATION",
  GCP_COMPUTE_INSTANCES: "GCP_COMPUTE_INSTANCES",
  GCP_COMPUTE_VISIBILITY: "GCP_COMPUTE_VISIBILITY",
  GCP_CUD_COVERABLE_COST: "GCP_CUD_COVERABLE_COST",
  GCP_CUSTOM_PRICING: "GCP_CUSTOM_PRICING",
  KUBERNETES_CLUSTER_USAGE: "KUBERNETES_CLUSTER_USAGE",
  KUBERNETES_CONTAINER_USAGE: "KUBERNETES_CONTAINER_USAGE",
  KUBERNETES_NODE_USAGE: "KUBERNETES_NODE_USAGE",
  MSP_ALERT_ROLLUP: "MSP_ALERT_ROLLUP",
  MSP_ROLLUP: "MSP_ROLLUP",
  PREDICTION_BOUNDS: "PREDICTION_BOUNDS",
  SNOWFLAKE_DATABASE_USAGE_METER_HELPER:
    "SNOWFLAKE_DATABASE_USAGE_METER_HELPER",
  SNOWFLAKE_DATABASE_USAGE: "SNOWFLAKE_DATABASE_USAGE",
  SNOWFLAKE_WAREHOUSE_USAGE: "SNOWFLAKE_WAREHOUSE_USAGE",
  STORAGE_COST: "STORAGE_COST",
  STORAGE_USAGE: "STORAGE_USAGE",
} as const;

export type DataSource = (typeof DataSource)[keyof typeof DataSource];

export const DataStatusType = {
  DEFAULT: "DEFAULT",
  OPTIMIZED: "OPTIMIZED",
} as const;

export type DataStatusType =
  (typeof DataStatusType)[keyof typeof DataStatusType];

export const CompareDurationType = {
  PREVIOUS_PERIOD: "PREVIOUS_PERIOD",
  CUSTOM: "CUSTOM",
  INVOICE: "INVOICE",
} as const;

export type CompareDurationType =
  (typeof CompareDurationType)[keyof typeof CompareDurationType];

export const DayOfWeek = {
  SUNDAY: "SUNDAY",
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
} as const;

export type DayOfWeek = (typeof DayOfWeek)[keyof typeof DayOfWeek];

export const DurationType = {
  CUSTOM: "CUSTOM",
  INVOICE: "INVOICE",
  LAST_MONTH: "LAST_MONTH",
  LAST_N_DAYS: "LAST_N_DAYS",
  LAST_N_MONTHS: "LAST_N_MONTHS",
  LAST_NINETY_DAYS: "LAST_NINETY_DAYS",
  LAST_SEVEN_DAYS: "LAST_SEVEN_DAYS",
  LAST_THIRTY_DAYS: "LAST_THIRTY_DAYS",
  MONTH_TO_DATE: "MONTH_TO_DATE",
  QUARTER_TO_DATE: "QUARTER_TO_DATE",
  TODAY: "TODAY",
  YEAR_TO_DATE: "YEAR_TO_DATE",
  YESTERDAY: "YESTERDAY",
} as const;

export type DurationType = (typeof DurationType)[keyof typeof DurationType];

export const GcpBigQueryEditionType = {
  ENTERPRISE_PLUS: "ENTERPRISE_PLUS",
  ENTERPRISE: "ENTERPRISE",
  STANDARD: "STANDARD",
} as const;

export type GcpBigQueryEditionType =
  (typeof GcpBigQueryEditionType)[keyof typeof GcpBigQueryEditionType];

export const GcpCommitmentScope = {
  BILLING_SHARED: "BILLING_SHARED",
  PROJECT: "PROJECT",
} as const;

export type GcpCommitmentScope =
  (typeof GcpCommitmentScope)[keyof typeof GcpCommitmentScope];

export const GcpCommitmentServiceType = {
  ALLOYDB: "ALLOYDB",
  BIGQUERY_ENTERPRISE: "BIGQUERY_ENTERPRISE",
  BIGQUERY_ENTERPRISE_PLUS: "BIGQUERY_ENTERPRISE_PLUS",
  CLOUD_BIGTABLE: "CLOUD_BIGTABLE",
  CLOUD_MEMORY_STORE: "CLOUD_MEMORY_STORE",
  CLOUD_RUN: "CLOUD_RUN",
  CLOUD_SPANNER: "CLOUD_SPANNER",
  CLOUD_SQL: "CLOUD_SQL",
  CLOUD_VMWARE_ENGINE: "CLOUD_VMWARE_ENGINE",
  COMPUTE_ENGINE: "COMPUTE_ENGINE",
  KUBERNETES_ENGINE: "KUBERNETES_ENGINE",
} as const;

export type GcpCommitmentServiceType =
  (typeof GcpCommitmentServiceType)[keyof typeof GcpCommitmentServiceType];

export const GcpCommitmentDurationType = {
  TWELVE_MONTH: "TWELVE_MONTH",
  THIRTY_SIX_MONTH: "THIRTY_SIX_MONTH",
} as const;

export type GcpCommitmentDurationType =
  (typeof GcpCommitmentDurationType)[keyof typeof GcpCommitmentDurationType];

export const GcpCommitmentType = {
  SPEND: "SPEND",
  RESOURCE: "RESOURCE",
} as const;

export type GcpCommitmentType =
  (typeof GcpCommitmentType)[keyof typeof GcpCommitmentType];

export const GcpCommitmentRecommendationTierType = {
  FULL_UTILIZATION: "FULL_UTILIZATION",
  MAXIMUM_SAVINGS: "MAXIMUM_SAVINGS",
} as const;

export type GcpCommitmentRecommendationTierType =
  (typeof GcpCommitmentRecommendationTierType)[keyof typeof GcpCommitmentRecommendationTierType];

export const GcpTargetCommitmentType = {
  SLOTS: "SLOTS",
  CURRENCY: "CURRENCY",
  MEMORY: "MEMORY",
  VCPU: "VCPU",
} as const;

export type GcpTargetCommitmentType =
  (typeof GcpTargetCommitmentType)[keyof typeof GcpTargetCommitmentType];

export const HourOfDay = {
  TWELVE_AM: "TWELVE_AM",
  ONE_AM: "ONE_AM",
  TWO_AM: "TWO_AM",
  THREE_AM: "THREE_AM",
  FOUR_AM: "FOUR_AM",
  FIVE_AM: "FIVE_AM",
  SIX_AM: "SIX_AM",
  SEVEN_AM: "SEVEN_AM",
  EIGHT_AM: "EIGHT_AM",
  NINE_AM: "NINE_AM",
  TEN_AM: "TEN_AM",
  ELEVEN_AM: "ELEVEN_AM",
  TWELVE_PM: "TWELVE_PM",
  ONE_PM: "ONE_PM",
  TWO_PM: "TWO_PM",
  THREE_PM: "THREE_PM",
  FOUR_PM: "FOUR_PM",
  FIVE_PM: "FIVE_PM",
  SIX_PM: "SIX_PM",
  SEVEN_PM: "SEVEN_PM",
  EIGHT_PM: "EIGHT_PM",
  NINE_PM: "NINE_PM",
  TEN_PM: "TEN_PM",
  ELEVEN_PM: "ELEVEN_PM",
} as const;

export type HourOfDay = (typeof HourOfDay)[keyof typeof HourOfDay];

export const MetricAggregate = {
  MAX: "MAX",
  MEAN: "MEAN",
  MIN: "MIN",
  SUM: "SUM",
} as const;

export type MetricAggregate =
  (typeof MetricAggregate)[keyof typeof MetricAggregate];

export const MongoDbAuthType = {
  API_KEY: "API_KEY",
  SERVICE_ACCOUNT: "SERVICE_ACCOUNT",
} as const;

export type MongoDbAuthType =
  (typeof MongoDbAuthType)[keyof typeof MongoDbAuthType];

export const MspBillingRuleType = {
  ADJUST_TO_LIST: "ADJUST_TO_LIST",
  APPLY_MARGIN: "APPLY_MARGIN",
  BACKOUT_LINE_ITEM: "BACKOUT_LINE_ITEM",
  MOVE_BILLING_ACCOUNT: "MOVE_BILLING_ACCOUNT",
  RECURRING_CHARGE: "RECURRING_CHARGE",
  RERATE: "RERATE",
} as const;

export type MspBillingRuleType =
  (typeof MspBillingRuleType)[keyof typeof MspBillingRuleType];

export const MspResourceType = {
  MSP_CUSTOMER: "MSP_CUSTOMER",
  MSP_GLOBAL: "MSP_GLOBAL",
} as const;

export type MspResourceType =
  (typeof MspResourceType)[keyof typeof MspResourceType];

export const RateRecommendationType = {
  COMMITTED_USE_DISCOUNT: "COMMITTED_USE_DISCOUNT",
  RESERVED_INSTANCE_AWS: "RESERVED_INSTANCE_AWS",
  RESERVED_INSTANCE_AZURE: "RESERVED_INSTANCE_AZURE",
  SAVINGS_PLAN_AWS: "SAVINGS_PLAN_AWS",
  SAVINGS_PLAN_AZURE: "SAVINGS_PLAN_AZURE",
} as const;

export type RateRecommendationType =
  (typeof RateRecommendationType)[keyof typeof RateRecommendationType];

export const ReportScope = {
  GLOBAL: "GLOBAL",
  PRIVATE: "PRIVATE",
  SHARED: "SHARED",
} as const;

export type ReportScope = (typeof ReportScope)[keyof typeof ReportScope];

export const ServiceType = {
  ACS: "ACS",
  AKS: "AKS",
  ANMLY: "ANMLY",
  AZURE_BLOB: "AZURE_BLOB",
  AZURE_SQL: "AZURE_SQL",
  AZURE_VM: "AZURE_VM",
  AZURE_STORAGE: "AZURE_STORAGE",
  BIGQUERY: "BIGQUERY",
  CLOUD_RUN: "CLOUD_RUN",
  EBS: "EBS",
  EC2: "EC2",
  EKS: "EKS",
  ELASTICACHE: "ELASTICACHE",
  GCE: "GCE",
  GCS: "GCS",
  GKE: "GKE",
  MEMORY_DB: "MEMORY_DB",
  OPEN_SEARCH: "OPEN_SEARCH",
  RDS: "RDS",
  REDSHIFT: "REDSHIFT",
  S3: "S3",
  SAGEMAKER: "SAGEMAKER",
  SQL: "CLOUDSQL",
} as const;

export type ServiceType = (typeof ServiceType)[keyof typeof ServiceType];

export const SubscriptionFrequency = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
} as const;

export type SubscriptionFrequency =
  (typeof SubscriptionFrequency)[keyof typeof SubscriptionFrequency];

export const Operator = {
  CONTAINS: "CONTAINS",
  EQUALS: "EQUALS",
  GTE: "GTE",
  LTE: "LTE",
  NOT_CONTAINS: "NOT_CONTAINS",
  NOT_EQUALS: "NOT_EQUALS",
  NOT_SET: "NOT_SET",
  SET: "SET",
} as const;

export type Operator = (typeof Operator)[keyof typeof Operator];

export const JobStatus = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  IN_PROGRESS: "IN_PROGRESS",
} as const;

export type JobStatus = (typeof JobStatus)[keyof typeof JobStatus];

export const ReallocationInvocationType = {
  AD_HOC: "AD_HOC",
  SCHEDULED: "SCHEDULED",
  TASK_BROKER: "TASK_BROKER",
} as const;

export type ReallocationInvocationType =
  (typeof ReallocationInvocationType)[keyof typeof ReallocationInvocationType];

export const ReallocationStatus = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
} as const;

export type ReallocationStatus =
  (typeof ReallocationStatus)[keyof typeof ReallocationStatus];

export const ReallocationType = {
  DYNAMIC: "DYNAMIC",
  STATIC: "STATIC",
} as const;

export type ReallocationType =
  (typeof ReallocationType)[keyof typeof ReallocationType];

export const ReallocationRebuildType = {
  ONEOFF: "ONEOFF",
  SCHEDULED: "SCHEDULED",
} as const;

export type ReallocationRebuildType =
  (typeof ReallocationRebuildType)[keyof typeof ReallocationRebuildType];

export const RecommendationCategory = {
  ANALYTICS: "ANALYTICS",
  BIG_DATA: "BIG_DATA",
  BILLING: "BILLING",
  COMPUTE: "COMPUTE",
  DATABASE: "DATABASE",
  GOOGLE: "GOOGLE",
  KUBERNETES: "KUBERNETES",
  STORAGE: "STORAGE",
} as const;

export type RecommendationCategory =
  (typeof RecommendationCategory)[keyof typeof RecommendationCategory];

export const ReportType = {
  DEFAULT: "DEFAULT",
  SNAPSHOT: "SNAPSHOT",
  SYSTEM_COPY: "SYSTEM_COPY",
  SYSTEM: "SYSTEM",
} as const;

export type ReportType = (typeof ReportType)[keyof typeof ReportType];

export const ResourceType = {
  BUDGET: "BUDGET",
  COST_ALERT: "COST_ALERT",
  DASHBOARD: "DASHBOARD",
  RAMP_PLAN: "RAMP_PLAN",
  RECOMMENDATION: "RECOMMENDATION",
  REPORT: "REPORT",
} as const;

export type ResourceType = (typeof ResourceType)[keyof typeof ResourceType];

export const SavingsOpportunityType = {
  RATE: "RATE",
  USAGE: "USAGE",
  RATE_AND_USAGE: "RATE_AND_USAGE",
} as const;

export type SavingsOpportunityType = keyof typeof SavingsOpportunityType;

export const ScopedViewType = {
  ADMIN: "ADMIN",
  PRIVATE: "PRIVATE",
} as const;

export type ScopedViewType = keyof typeof ScopedViewType;

export const ThemeMode = {
  DARK: "DARK",
  LIGHT: "LIGHT",
} as const;

export type ThemeMode = (typeof ThemeMode)[keyof typeof ThemeMode];

export const TenantStatus = {
  ACTIVE: "ACTIVE",
  ACTIVE_POV: "ACTIVE_POV",
  INACTIVE_DATA_DELETED: "INACTIVE_DATA_DELETED",
  INACTIVE: "INACTIVE",
} as const;

export type TenantStatus = (typeof TenantStatus)[keyof typeof TenantStatus];

export const TenantType = {
  DIRECT_CUSTOMER: "DIRECT_CUSTOMER",
  INTERNAL: "INTERNAL",
  MSP_CUSTOMER: "MSP_CUSTOMER",
  MSP_PARENT: "MSP_PARENT",
} as const;

export type TenantType = (typeof TenantType)[keyof typeof TenantType];

export const TimeGranularity = {
  DAY: "DAY",
  HOUR: "HOUR",
  MINUTE: "MINUTE",
  MONTH: "MONTH",
  QUARTER: "QUARTER",
  SECOND: "SECOND",
  WEEK: "WEEK",
  YEAR: "YEAR",
} as const;

export type TimeGranularity =
  (typeof TimeGranularity)[keyof typeof TimeGranularity];

export const UserConfigStatus = {
  DISABLED: "DISABLED",
  ENABLED: "ENABLED",
  ENABLED_STRICT: "ENABLED_STRICT",
  ENFORCED: "ENFORCED",
} as const;

export type UserConfigStatus =
  (typeof UserConfigStatus)[keyof typeof UserConfigStatus];

export const UserStatus = {
  ACTIVE: "ACTIVE",
  DEACTIVATED: "DEACTIVATED",
  PENDING: "PENDING",
};

export type UserStatus = (typeof UserStatus)[keyof typeof UserStatus];

export const WeekOfMonth = {
  FIRST: "FIRST",
  LAST: "LAST",
} as const;

export type WeekOfMonth = (typeof WeekOfMonth)[keyof typeof WeekOfMonth];

export const WidgetType = {
  BUDGET_CURRENT_MONTH: "BUDGET_CURRENT_MONTH",
  BUDGET_DAILY_TRENDS: "BUDGET_DAILY_TRENDS",
  REALIZED_COMMITMENT_SAVINGS: "REALIZED_COMMITMENT_SAVINGS",
  REPORT: "REPORT",
  SAVINGS_OPPORTUNITY_FILTER: "SAVINGS_OPPORTUNITY_FILTER",
  TEXT: "TEXT",
} as const;

export type WidgetType = (typeof WidgetType)[keyof typeof WidgetType];
