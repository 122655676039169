import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import { RateRecommendationType } from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Link from "@ternary/api-lib/ui-lib/components/Link";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React, { useMemo } from "react";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import copyText from "../copyText";
import { MeasureCell } from "@ternary/api-lib/ui-lib/charts/Table/MeasureCell";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import { ExpandableCell } from "@ternary/api-lib/ui-lib/charts/Table/ExpandableCell";
import { groupBy, sumBy } from "lodash";
import { createRange, getSortedUniqueString } from "../utils";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";

type RecommendationData = {
  id: string;
  estimatedMonthlySavings: number;
  tenantDocID: string;
  tenantName: string;
};

type Recommendation = RecommendationData & {
  term: string | number;
  type: RateRecommendationType;
};

type AggregatedRecommendation = RecommendationData & {
  type: string[];
  term: {
    max: number;
    min: number;
  };
};

type RowData = (Recommendation | AggregatedRecommendation) & {
  hasTenantAccess: boolean;
};
type TableData = RowData & {
  subRows?: RowData[];
};

const TermsInYears = {
  ONE_YEAR: 1,
  THIRTY_SIX_MONTH: 3,
  THIRTY_SIX_MONTHS: 3,
  THREE_YEARS: 3,
  TWELVE_MONTH: 1,
  TWELVE_MONTHS: 1,
};

const columnHelper = createColumnHelper<TableData>();

interface Props {
  isLoading: boolean;
  recommendations: Recommendation[];
  onInteraction: (interaction: MspRateRecommendationTable.Interaction) => void;
}

export function MspRateRecommendationTable(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor("tenantName", {
        cell: (cell) => {
          const tenantDocID = cell.row.original.tenantDocID;
          const type = cell.row.original.type;
          const hasAccess = cell.row.original.hasTenantAccess;

          let pathname = "";

          switch (type) {
            case RateRecommendationType.COMMITTED_USE_DISCOUNT:
              pathname = paths._commitmentDiscountGCP;
              break;
            case RateRecommendationType.RESERVED_INSTANCE_AWS:
            case RateRecommendationType.SAVINGS_PLAN_AWS:
              pathname = paths._commitmentDiscountAWS;
              break;
            case RateRecommendationType.RESERVED_INSTANCE_AZURE:
            case RateRecommendationType.SAVINGS_PLAN_AZURE:
              pathname = paths._commitmentDiscountAzure;
              break;
          }

          return (
            <ExpandableCell row={cell.row}>
              {hasAccess ? (
                <Link
                  target="_blank"
                  to={{
                    pathname,
                    search: `?tenant_id=${tenantDocID}`,
                  }}
                >
                  {cell.getValue()}
                </Link>
              ) : (
                <Text
                  appearance="link"
                  color={theme.link_color}
                  onClick={() =>
                    props.onInteraction({
                      type: MspRateRecommendationTable.INTERACTION_LINK_CLICKED,
                      tenantDocID,
                    })
                  }
                >
                  {cell.getValue()}
                </Text>
              )}
            </ExpandableCell>
          );
        },
        header: copyText.tableHeaderTenantName,
        size: 250,
      }),
      columnHelper.accessor("term", {
        cell: (cell) => {
          const value = cell.getValue();
          if (typeof value === "string") {
            return copyText[`rateRecTermLabel_${value}`];
          } else if (typeof value === "number") {
            return `${value} ${value === 1 ? copyText.unitYear : copyText.unitYears}`;
          }
          if (value?.min && value?.max) {
            if (value?.min === value?.max) {
              return `${value?.min} ${value.min === 1 ? copyText.unitYear : copyText.unitYears}`;
            }

            return `${value?.min} - ${value?.max} ${copyText.unitYears}`;
          }
          return "";
        },
        header: copyText.tableHeaderTerm,
        size: 80,
      }),
      columnHelper.accessor("type", {
        cell: (cell) => {
          const value = cell.getValue();
          if (Array.isArray(value)) {
            return (
              <Tooltip
                content={
                  <Text color={theme.text_color_inverse}>
                    CUD (Committed Use Discount, GCP), RI (Reserved Instance,
                    AWS/Azure), SP (Savings Plan, AWS/Azure).
                  </Text>
                }
                width="225px"
              >
                <Text truncate>{value.join(", ")}</Text>
              </Tooltip>
            );
          }

          return copyText[`rateRecTypeLabel_${value}`];
        },
        header: copyText.tableHeaderType,
      }),
      columnHelper.accessor("estimatedMonthlySavings", {
        cell: ({ column, getValue }) => {
          const value = getValue();

          return (
            <MeasureCell
              applyMaxCharacters
              columnID={column?.id}
              currencyCode={"USD"}
              unit={UnitType.CURRENCY}
              value={value}
            />
          );
        },
        header: copyText.tableHeaderPotentialSavingsMonthly,
        meta: { align: "right" },
      }),
    ],
    [props.recommendations]
  );

  const data = useMemo(
    () =>
      props.recommendations.map((rec) => {
        const grant = authenticatedUser.grants.find(
          (grant) => grant.tenantDocID === rec.tenantDocID
        );
        return {
          ...rec,
          // turn term into a number for table sorting
          term: TermsInYears[rec.term] ?? rec.term,
          hasTenantAccess: !!grant,
        };
      }),
    [props.recommendations]
  );

  const aggregatedByTenant: TableData[] = useMemo(() => {
    const groupedByTenant = groupBy(data, "tenantDocID");
    return Object.keys(groupedByTenant).map((tenantDocID) => {
      const recommendations = groupedByTenant[tenantDocID];
      if (recommendations.length === 1) {
        return recommendations[0];
      }

      const estimatedMonthlySavings = sumBy(
        recommendations,
        "estimatedMonthlySavings"
      );

      const type = getSortedUniqueString(
        recommendations.map((r) => ({
          type: copyText[`rateRecTypeAbbreviation_${r.type}`],
        })),
        "type"
      );

      const term = createRange(recommendations, "term");

      const rowData: TableData = {
        id: recommendations[0].id,
        estimatedMonthlySavings,
        hasTenantAccess: recommendations[0].hasTenantAccess,
        subRows: recommendations,
        tenantDocID,
        tenantName: recommendations[0].tenantName,
        term,
        type,
      };
      return rowData;
    });
  }, [data]);

  return (
    <Table
      columns={columns}
      compact
      data={aggregatedByTenant}
      expandable
      getSubRows={(row) => row?.subRows ?? []}
      initialState={{
        sorting: [{ id: "estimatedMonthlySavings", desc: true }],
      }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

MspRateRecommendationTable.INTERACTION_LINK_CLICKED =
  "MspChildRateRecommendationTable.INTERACTION_LINK_CLICKED" as const;

interface InteractionLinkClicked {
  type: typeof MspRateRecommendationTable.INTERACTION_LINK_CLICKED;
  tenantDocID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspRateRecommendationTable {
  export type Interaction = InteractionLinkClicked;
}

export default MspRateRecommendationTable;
