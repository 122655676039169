import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { WidgetType } from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import React, { useMemo, useState } from "react";
import copyText from "../copyText";

type Budget = {
  id: string;
  name: string;
};

type Props = {
  budgets: Budget[];
  isLoading: boolean;
  onClose: () => void;
  onInteraction: (interaction: BudgetListModal.Interaction) => void;
};

type State = {
  budgetID: string | null;
  searchText: string;
};

type TableData = {
  id: string;
  name: string;
  timeLastModified: string;
};

const initialState: State = {
  budgetID: null,
  searchText: "",
};

export function BudgetListModal(props: Props): JSX.Element {
  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  function handleCreateWidget(
    widgetType:
      | typeof WidgetType.BUDGET_CURRENT_MONTH
      | typeof WidgetType.BUDGET_DAILY_TRENDS
  ) {
    if (!state.budgetID) return;

    props.onInteraction({
      type: BudgetListModal.INTERACTION_ADD_BUDGET_CLICKED,
      widgetType,
      budgetID: state.budgetID,
    });
    props.onClose();
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: copyText.tableHeaderName,
        size: 550,
      }),
      columnHelper.accessor("timeLastModified", {
        cell: ({ getValue }) => (
          <>{formatDate(new Date(getValue()), "MM/dd/yyyy")}</>
        ),
        header: copyText.tableHeaderTimeLastModified,
        size: 140,
      }),
    ],
    []
  );

  const data = useMemo((): TableData[] => {
    let filteredData = props.budgets
      .map((budget) => ({
        id: budget.id,
        name: budget.name,
        timeLastModified: new Date().toISOString(),
      }))
      .sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : 0
      );

    if (state.searchText.length > 0) {
      filteredData = filteredData.filter((budget) => {
        return budget.name
          .toLowerCase()
          .includes(state.searchText.toLowerCase());
      });
    }
    return filteredData;
  }, [props.budgets, state.searchText]);

  return (
    <Modal
      isOpen={true}
      showCloseButton
      width={state.budgetID === null ? 1000 : 300}
      onClose={props.onClose}
    >
      <Modal.Header>
        <Text appearance="h4">
          {state.budgetID === null
            ? copyText.addBudget
            : copyText.addBudgetSelectType}
        </Text>
      </Modal.Header>

      <Modal.Body>
        {state.budgetID === null ? (
          <>
            <Flex
              justifyContent="right"
              marginBottom={theme.space_md}
              marginRight={theme.size_medium}
              marginTop={-45}
            >
              <Box marginRight={theme.space_md} width="12rem">
                <TextInput
                  iconStart={
                    <Icon color={theme.text_color_secondary} icon={faSearch} />
                  }
                  placeholder={copyText.searchBudgetsDashboardsPlaceholder}
                  onChange={(event) =>
                    mergeState({ searchText: event.target.value })
                  }
                />
              </Box>
            </Flex>
            <Box height={500}>
              <Table
                clickableRows
                columns={columns}
                compact
                data={data}
                initialState={{
                  pagination: {
                    pageSize: 10,
                  },
                  sorting: [{ id: "name", desc: true }],
                }}
                isLoading={props.isLoading}
                showPagination
                sortable
                onClick={(row) => mergeState({ budgetID: row.id })}
              />
            </Box>
          </>
        ) : (
          <Flex justifyContent="space-between">
            <Button
              primary
              onClick={() => handleCreateWidget(WidgetType.BUDGET_DAILY_TRENDS)}
            >
              {copyText.addBudgetDaily}
            </Button>
            <Button
              primary
              onClick={() =>
                handleCreateWidget(WidgetType.BUDGET_CURRENT_MONTH)
              }
            >
              {copyText.addBudgetMonthly}
            </Button>
          </Flex>
        )}
      </Modal.Body>
    </Modal>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BudgetListModal {
  export const INTERACTION_ADD_BUDGET_CLICKED = `BudgetListModal.INTERACTION_ADD_BUDGET_CLICKED`;

  interface InteractionAddBudgetClicked {
    type: typeof BudgetListModal.INTERACTION_ADD_BUDGET_CLICKED;
    budgetID: string;
    widgetType:
      | typeof WidgetType.BUDGET_CURRENT_MONTH
      | typeof WidgetType.BUDGET_DAILY_TRENDS;
  }

  export type Interaction = InteractionAddBudgetClicked;
}

export default BudgetListModal;
