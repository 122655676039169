import useGatekeeper from "@/hooks/useGatekeeper";
import Modal from "@/ui-lib/components/Modal";
import SelectCheckbox, { Option } from "@/ui-lib/components/SelectCheckbox";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faLock, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { uniqBy } from "lodash";
import React, { useMemo, useState } from "react";
import copyText from "../copyText";
import { MAX_ALLOWED_WIDGETS } from "./DashboardViewContainer";

type Dashboard = {
  id: string;
  name: string;
  createdAt: string;
  createdByEmail: string | null;
  createdByID: string;
  widgetSpecs: WidgetSpec[];
  updatedAt: string | null;
};

// only care about spec count
type WidgetSpec = {
  type: string;
};

interface Props {
  dashboards: Dashboard[];
  isLoading: boolean;
  saveAsCopy?: boolean;
  title?: string;
  onClose?: () => void;
  onInteraction: (interaction: DashboardListModal.Interaction) => void;
}

type TableData = {
  id: string;
  createdBy: string;
  createdByID: string;
  name: string;
  timeLastModified: string;
  widgetSpecs: WidgetSpec[];
};

interface State {
  createdByFilters: string[];
  searchText: string;
}

const initialState = {
  createdByFilters: [],
  searchText: "",
};

export function DashboardListModal(props: Props): JSX.Element {
  const [state, setState] = useState<State>(initialState);
  const gatekeeper = useGatekeeper();
  const theme = useTheme();
  const mergeState = getMergeState(setState);

  const columnHelper = createColumnHelper<TableData>();

  if (!gatekeeper.canListDashboards || !gatekeeper.canUpdateDashboard) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  function handleClickCreateDashboard() {
    props.onInteraction({
      type: DashboardListModal.INTERACTION_CREATE_DASHBOARD_BUTTON_CLICKED,
    });
  }

  function handleClickRow(dashboardID: string): void {
    props.onInteraction({
      type: DashboardListModal.INTERACTION_ROW_CLICKED,
      dashboardID,
    });
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: copyText.tableHeaderName,
        size: 200,
      }),
      columnHelper.accessor("createdBy", {
        header: copyText.tableHeaderCreatedBy,
        size: 200,
      }),
      columnHelper.accessor("timeLastModified", {
        cell: ({ getValue }) => (
          <>{formatDate(new Date(getValue() ?? 0), "MM/dd/yyyy hh:mm a")}</>
        ),
        header: copyText.tableHeaderTimeLastModified,

        size: 200,
      }),
      columnHelper.accessor("widgetSpecs", {
        cell: ({ getValue }) => {
          const specs = getValue();
          return (
            <>{`${specs.length} ${
              specs.length >= MAX_ALLOWED_WIDGETS
                ? copyText.maximumNumberOfReports
                : ""
            }`}</>
          );
        },
        header: copyText.tableHeaderNumberOfReports,
        size: 200,
        sortingFn: (rowA, rowB) => {
          const aLength = rowA.original.widgetSpecs.length;
          const bLength = rowB.original.widgetSpecs.length;
          return aLength > bLength ? 1 : aLength < bLength ? -1 : 0;
        },
      }),
    ],
    []
  );

  const data: TableData[] = useMemo(() => {
    let filteredData = props.dashboards
      .map((dashboard) => ({
        id: dashboard.id,
        name: dashboard.name,
        widgetSpecs: dashboard.widgetSpecs,
        timeLastModified: dashboard.updatedAt ?? dashboard.createdAt,
        createdBy: dashboard.createdByEmail ?? "--",
        createdByID: dashboard.createdByID,
      }))
      .sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : 0
      );

    if (state.createdByFilters.length > 0) {
      filteredData = filteredData.filter((dashboard) => {
        return state.createdByFilters.includes(dashboard.createdByID ?? "");
      });
    }

    if (state.searchText.length > 0) {
      filteredData = filteredData.filter((dashboard) => {
        return dashboard.name
          .toLowerCase()
          .includes(state.searchText.toLowerCase());
      });
    }

    return filteredData;
  }, [props.dashboards, state.createdByFilters, state.searchText]);

  const createdByFilterOptions: Option[] = uniqBy(
    props.dashboards,
    "createdByEmail"
  )
    .reduce((accum: Option[], dashboard: Dashboard) => {
      if (!dashboard.createdByEmail) return accum;

      return [
        ...accum,
        {
          label: dashboard.createdByEmail,
          value: dashboard.createdByID,
        },
      ];
    }, [])
    .sort((a, b) =>
      a.value.toLowerCase() < b.value.toLowerCase()
        ? -1
        : a.value.toLowerCase() > b.value.toLowerCase()
          ? 1
          : 0
    );

  return (
    <Modal isOpen={true} showCloseButton onClose={props.onClose} minWidth={750}>
      <Modal.Header>
        <Text appearance="h4">{props.title}</Text>
      </Modal.Header>
      <Modal.Body>
        <Flex
          justifyContent="space-between"
          marginBottom={theme.space_md}
          marginTop={-10}
        >
          <Flex>
            <Box marginRight={theme.space_md} width="12rem">
              <TextInput
                iconStart={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchReportsDashboardsPlaceholder}
                onChange={(event) =>
                  mergeState({ searchText: event.target.value })
                }
              />
            </Box>
            <SelectCheckbox
              options={createdByFilterOptions}
              placeholder={copyText.filterReportsDashboardsPlaceholder}
              selectedValues={state.createdByFilters}
              width="12rem"
              onChange={(event) => mergeState({ createdByFilters: event })}
            />
          </Flex>
          <Button
            iconStart={<Icon icon={faPlus} />}
            locked={!gatekeeper.canCreateDashboards}
            marginLeft={theme.space_md}
            primary
            onClick={handleClickCreateDashboard}
          >
            {copyText.createDashboardButtonLabel}
          </Button>
        </Flex>
        <Table
          clickableRows
          columns={columns}
          compact
          data={data}
          initialState={{
            pagination: {
              pageSize: 10,
            },
            sorting: [{ id: "widgetSpecs", desc: true }],
          }}
          isLoading={props.isLoading}
          showPagination
          sortable
          disableRow={(row) => {
            return row.original.widgetSpecs.length >= MAX_ALLOWED_WIDGETS;
          }}
          onClick={(row) => handleClickRow(row.id)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button secondary width={70} onClick={props.onClose}>
          {copyText.cancelButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DashboardListModal {
  export const INTERACTION_ROW_CLICKED = `DashboardListModal.INTERACTION_ROW_CLICKED`;
  export const INTERACTION_CREATE_DASHBOARD_BUTTON_CLICKED = `DashboardList.INTERACTION_CREATE_DASHBOARD_BUTTON_CLICKED`;

  interface InteractionRowClicked {
    type: typeof DashboardListModal.INTERACTION_ROW_CLICKED;
    dashboardID: string;
  }

  interface InteractionCreateDashboardButtonClicked {
    type: typeof DashboardListModal.INTERACTION_CREATE_DASHBOARD_BUTTON_CLICKED;
  }

  export type Interaction =
    | InteractionRowClicked
    | InteractionCreateDashboardButtonClicked;
}

export default DashboardListModal;
