import { DateRange } from "@ternary/api-lib/analytics/utils";
import { DurationType } from "@ternary/api-lib/constants/enums";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { differenceInDays, sub } from "date-fns";
import copyText from "./copyText";

// Big Query

export function getPreviousPeriodDateRange(dateRange: DateRange): DateRange {
  const numberOfDaysInRange = differenceInDays(dateRange[1], dateRange[0]);

  return [
    sub(dateRange[0], { days: numberOfDaysInRange + 1 }),
    sub(dateRange[1], { days: numberOfDaysInRange + 1 }),
  ];
}

export function getStringifiedDurationType(
  durationType: DurationType,
  dateRange: Date[]
): string {
  switch (durationType) {
    case DurationType.YESTERDAY: {
      return copyText.durationTypeYesterday;
    }
    case DurationType.LAST_SEVEN_DAYS: {
      return copyText.durationTypeLastSevenDays;
    }
    case DurationType.LAST_THIRTY_DAYS: {
      return copyText.durationTypeLastThirtyDays;
    }
    case DurationType.LAST_NINETY_DAYS: {
      return copyText.durationTypeLastNinetyDays;
    }
    case DurationType.MONTH_TO_DATE: {
      return copyText.durationTypeMonthToDate;
    }
    case DurationType.YEAR_TO_DATE: {
      return copyText.durationTypeYearToDate;
    }
    default: {
      return `${formatDate(dateRange[0], "MM/dd/yyyy")} - ${formatDate(dateRange[1], "MM/dd/yyyy")}`;
    }
  }
}
