import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import Dropdown from "@/ui-lib/components/Dropdown";
import { ActionMenuButton } from "@/ui-lib/components/Table";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import {
  ScopedViewType,
  UserConfigStatus,
} from "@ternary/api-lib/constants/enums";
import { ScopedViewFilter, UserConfig } from "@ternary/api-lib/core/types";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { keyBy } from "lodash";
import React, { useMemo } from "react";
import copyText from "../copyText";

type User = {
  id: string;
  email: string;
};

type ScopedView = {
  id: string;
  createdAt: string;
  createdBy: string;
  filters: ScopedViewFilter[];
  name: string;
  type: ScopedViewType;
  updatedAt: string | null;
  userConfigs: UserConfig[];
};

export interface Props {
  isAdmin: boolean;
  isLoading: boolean;
  scopedViews: ScopedView[];
  users: User[];
  onInteraction: (interaction: ScopedViewList.Interaction) => void;
}

type TableData = {
  id: string;
  createdBy: string;
  enabled?: string;
  isSharedType: boolean;
  name: string;
  timeLastModified: string;
  totalFilters: number;
  totalUsers: number;
};

export function ScopedViewList(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const usersKeyedByID = keyBy(props.users, "id");
  const columnHelper = createColumnHelper<TableData>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: copyText.tableHeaderName,
      }),
      columnHelper.accessor("isSharedType", {
        cell: ({ getValue }) =>
          getValue() ? (
            <Tooltip content={copyText.sharedTooltip}>
              <Icon icon={faUserGroup} />
            </Tooltip>
          ) : null,
        enableSorting: false,
        meta: { align: "center" },
        size: 75,
      }),
      columnHelper.accessor("totalFilters", {
        header: copyText.tableHeaderTotalFilters,
        size: 60,
      }),
      ...(props.isAdmin
        ? [
            columnHelper.accessor("totalUsers", {
              header: copyText.tableHeaderTotalUsers,
              size: 60,
            }),
            columnHelper.accessor("createdBy", {
              header: copyText.tableHeaderCreatedBy,
              size: 140,
            }),
          ]
        : [
            columnHelper.accessor("enabled", {
              header: copyText.tableHeaderEnabled,
              size: 80,
            }),
            columnHelper.accessor("createdBy", {
              header: copyText.tableHeaderCreatedBy,
              size: 140,
            }),
          ]),
      columnHelper.accessor("timeLastModified", {
        cell: ({ getValue }) => (
          <>
            {formatDate(
              new Date(getValue() ? getValue() : 0),
              "MM/dd/yyyy hh:mm a"
            )}
          </>
        ),
        size: 100,
      }),
      columnHelper.display({
        id: "actionMenu",
        cell: ({ row }) => (
          <ScopedViewListDropdown
            isShared={row.original.isSharedType}
            scopedViewID={row.original.id}
            onInteraction={props.onInteraction}
          />
        ),
        meta: { align: "center" },
        size: 50,
      }),
    ],
    [props.scopedViews, props.users]
  );

  const data = useMemo(
    () =>
      props.scopedViews.map((scopedView) => ({
        id: scopedView.id,
        createdBy: usersKeyedByID[scopedView.createdBy]?.email,
        isSharedType:
          scopedView.type === ScopedViewType.ADMIN && !props.isAdmin,
        name: scopedView.name,
        timeLastModified: scopedView.updatedAt ?? scopedView.createdAt,
        totalFilters: scopedView.filters.length,
        totalUsers: scopedView.userConfigs.length,
        ...(props.isAdmin
          ? {}
          : {
              enabled: scopedView.userConfigs.some(
                (userConfig) =>
                  userConfig.userID === authenticatedUser.id &&
                  userConfig.status === UserConfigStatus.ENABLED
              )
                ? copyText.enabledYes
                : copyText.enabledNo,
            }),
      })),
    [props.scopedViews, props.users]
  );

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{ sorting: [{ id: "name", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

interface ScopedViewListDropwdownProps {
  isShared: boolean;
  scopedViewID: string;
  onInteraction: (interaction: ScopedViewList.Interaction) => void;
}

function ScopedViewListDropdown(
  props: ScopedViewListDropwdownProps
): JSX.Element {
  const options = [
    {
      label: copyText.actionMenuItemEditScopedView,
      onClick: () =>
        props.onInteraction({
          type: ScopedViewList.INTERACTION_EDIT_BUTTON_CLICKED,
          scopedViewID: props.scopedViewID,
        }),
    },
    {
      label: copyText.actionMenuItemMakeCopyScopedView,
      onClick: () =>
        props.onInteraction({
          type: ScopedViewList.INTERACTION_MAKE_COPY_BUTTON_CLICKED,
          scopedViewID: props.scopedViewID,
        }),
    },
    ...(props.isShared
      ? []
      : [
          {
            label: copyText.actionMenuItemDeleteScopedView,
            onClick: () =>
              props.onInteraction({
                type: ScopedViewList.INTERACTION_DELETE_BUTTON_CLICKED,
                scopedViewID: props.scopedViewID,
              }),
          },
        ]),
  ];

  return (
    <Dropdown options={options} placement="bottom-end">
      <ActionMenuButton />
    </Dropdown>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ScopedViewList {
  export const INTERACTION_DELETE_BUTTON_CLICKED =
    "ScopedViewList.INTERACTION_DELETE_BUTTON_CLICKED";
  export const INTERACTION_EDIT_BUTTON_CLICKED =
    "ScopedViewList.INTERACTION_EDIT_BUTTON_CLICKED";
  export const INTERACTION_MAKE_COPY_BUTTON_CLICKED =
    "ScopedViewList.INTERACTION_MAKE_COPY_BUTTON_CLICKED";

  interface InteractionDeleteButtonClicked {
    type: typeof ScopedViewList.INTERACTION_DELETE_BUTTON_CLICKED;
    scopedViewID: string;
  }

  interface InteractionEditButtonClicked {
    type: typeof ScopedViewList.INTERACTION_EDIT_BUTTON_CLICKED;
    scopedViewID: string;
  }
  interface InteractionMakeCopyButtonClicked {
    type: typeof ScopedViewList.INTERACTION_MAKE_COPY_BUTTON_CLICKED;
    scopedViewID: string;
  }

  export type Interaction =
    | InteractionEditButtonClicked
    | InteractionDeleteButtonClicked
    | InteractionMakeCopyButtonClicked;
}

export default ScopedViewList;
