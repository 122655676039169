import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import { DateRange } from "@/utils/dates";
import { useTheme } from "@emotion/react";
import { faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { formatCurrency } from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import copyText from "../../copyText";
import { EBStore } from "../types";

interface Props {
  date: DateRange;
  isLoading: boolean;
  volumes: EBStore[];
  onClose: () => void;
}

type TableData = {
  lineItemUsageAccountId: string;
  maxThroughputVolumeBytes: number;
  operationsCost: number;
  storageCost: number;
  storageUsageBytes: number;
  totalCost: number;
  totalReadTime: number;
  totalWriteTime: number;
  type: string;
  volumeId: string;
  volumeReadOps: number;
  volumeWriteOps: number;
};

export default function AWSStorageEBSVolumesTable(props: Props): JSX.Element {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  const [searchText, setSearchText] = useState("");

  const subTableTitle =
    props.volumes.length > 0
      ? `Volumes ${props.volumes[0].billPayerAccountId} / ${props.volumes[0].region}`
      : null;

  const columns = useMemo(
    () => [
      columnHelper.accessor("volumeId", {
        header: copyText.ebsSubTableVolumeGroupHeader_volumeId,
        meta: { align: "left", truncate: true },
        size: 220,
      }),
      columnHelper.accessor("lineItemUsageAccountId", {
        header: copyText.ebsSubTableVolumeGroupHeader_lineItemUsageAccountId,
        meta: { align: "left", truncate: true },
        size: 125,
      }),
      columnHelper.accessor("type", {
        header: copyText.ebsSubTableVolumeGroupHeader_type,
        meta: { align: "left" },
        size: 100,
      }),
      columnHelper.accessor("storageUsageBytes", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header: copyText.ebsSubTableVolumeGroupHeader_storageUsageBytes,
        meta: { align: "right" },
        size: 100,
      }),
      columnHelper.accessor("totalReadTime", {
        cell: ({ getValue }) => {
          const value = getValue();
          return getValue() ? <>{value.toFixed(2)}</> : <>---</>;
        },
        header: copyText.ebsSubTableVolumeGroupHeader_totalReadTime,
        meta: { align: "right" },
        size: 100,
      }),
      columnHelper.accessor("totalWriteTime", {
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? <>{value.toFixed(2)}</> : <>---</>;
        },
        header: copyText.ebsSubTableVolumeGroupHeader_totalWriteTime,
        meta: { align: "right" },
        size: 100,
      }),
      columnHelper.accessor("volumeReadOps", {
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? <>{value}</> : <>---</>;
        },
        header: copyText.ebsSubTableVolumeGroupHeader_volumeReadOps,
        meta: { align: "right" },
        size: 100,
      }),
      columnHelper.accessor("volumeWriteOps", {
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? <>{value}</> : <>---</>;
        },
        header: copyText.ebsSubTableVolumeGroupHeader_volumeWriteOps,
        meta: { align: "right" },
        size: 100,
      }),
      columnHelper.accessor("maxThroughputVolumeBytes", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header: copyText.ebsSubTableVolumeGroupHeader_maxThroughputVolumeBytes,
        meta: { align: "right" },
        size: 100,
      }),
      columnHelper.accessor("storageCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.ebsSubTableVolumeGroupHeader_storageCost,
        meta: { align: "right" },
        size: 100,
      }),
      columnHelper.accessor("operationsCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.ebsSubTableVolumeGroupHeader_operationsCost,
        meta: { align: "right" },
        size: 100,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.ebsSubTableVolumeGroupHeader_totalCost,
        meta: { align: "right" },
        size: 100,
      }),
    ],
    []
  );

  const data: TableData[] = useMemo(
    () =>
      props.volumes.map((volume) => ({
        lineItemUsageAccountId: volume.lineItemUsageAccountId,
        maxThroughputVolumeBytes: volume.maxThroughputVolumeBytes,
        operationsCost: volume.operationsCost,
        snapshotCost: volume.snapshotCost,
        storageCost: volume.storageCost,
        storageUsageBytes: volume.storageUsageBytes,
        totalCost: volume.totalCost,
        totalReadTime: volume.totalReadTime,
        totalWriteTime: volume.totalWriteTime,
        type: volume.type,
        volumeId: volume.volumeId,
        volumeReadOps: volume.volumeReadOps,
        volumeWriteOps: volume.volumeWriteOps,
      })),
    [props.volumes]
  );

  const filteredData = useMemo(() => {
    if (searchText.length === 0) return data;

    return data.filter((volume) => {
      const str = searchText.toLowerCase();

      const volumeName = volume.volumeId.toLowerCase();
      const volumeType = volume.type.toLowerCase();
      const volumeLineItemID = volume.lineItemUsageAccountId.toLowerCase();

      return (
        volumeName.includes(str) ||
        volumeType.includes(str) ||
        volumeLineItemID.includes(str)
      );
    });
  }, [data, searchText]);

  return (
    <Modal isOpen showCloseButton onClose={props.onClose} minWidth={1100}>
      <Modal.Header>
        <Flex
          justifyContent="space-between"
          marginLeft={theme.space_sm}
          width="100%"
        >
          <Text fontSize={theme.h4_fontSize}>{subTableTitle}</Text>
          <Flex alignItems="center">
            <Box width={250} marginRight={theme.space_lg}>
              <TextInput
                disabled={!props.volumes.length}
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchInputPlaceholder}
                size="medium"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Box>

            <CSVLink
              data={getCSVData(filteredData).rows}
              headers={getCSVData(filteredData).headers}
              filename={`${subTableTitle}-${formatDate(
                new Date(),
                "MM-dd-yyyy"
              )}`}
            >
              <Button
                iconStart={<Icon color="inherit" icon={faFileExport} />}
                primary
                size="small"
                marginRight={theme.space_md}
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Box width="100%" overflowX="auto">
          <Table
            columns={columns}
            data={filteredData}
            initialState={{ sorting: [{ id: "totalCost", desc: true }] }}
            isLoading={props.isLoading}
            showPagination
            sortable
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
}

const csvAccessors = [
  "volumeId",
  "lineItemUsageAccountId",
  "volumeReadOps",
  "volumeWriteOps",
  "maxThroughputVolumeBytes",
  "operationsCost",
  "storageCost",
  "storageUsageBytes",
  "totalCost",
  "totalReadTime",
  "totalWriteTime",
  "type",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(data: TableData[]): CSVData {
  if (!data.length) {
    return { headers: [], rows: [] };
  }

  const rows = data.map((datum) => ({
    volumeId: datum.volumeId,
    lineItemUsageAccountId: datum.lineItemUsageAccountId,
    volumeReadOps: datum.volumeReadOps,
    volumeWriteOps: datum.volumeWriteOps,
    maxThroughputVolumeBytes: datum.maxThroughputVolumeBytes,
    operationsCost: datum.operationsCost,
    storageCost: datum.storageCost,
    storageUsageBytes: datum.storageUsageBytes,
    totalCost: datum.totalCost,
    totalReadTime: datum.totalReadTime,
    totalWriteTime: datum.totalWriteTime,
    type: datum.type,
  }));

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `ebsSubTableVolumeGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}
