import styled from "@emotion/styled";
import Markdown from "markdown-to-jsx";
import React from "react";

const _Markdown = styled(Markdown)`
  color: ${({ theme }) => theme.text_color} !important;
  white-space: pre-wrap;

  li {
    font-size: ${({ theme }) => theme.fontSize_ui} !important;
    font-weight: ${({ theme }) => theme.fontWeight_regular} !important;
  }

  img {
    height: 300px;
    width: 600px;
  }
`;

const MarkdownWrapper = (props): JSX.Element => {
  return (
    <_Markdown options={{ disableParsingRawHTML: true }}>
      {props.children}
    </_Markdown>
  );
};

export default MarkdownWrapper;
