import { useTheme } from "@emotion/react";
import {
  faArrowDown,
  faArrowUp,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import {
  formatCurrencyRounded,
  formatPercentage,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import React from "react";
import copyText from "../copyText";

interface Props {
  isLoading: boolean;
  currentMtdForecastedSpend: number;
  currentMtdSpend: number;
  last3MonthAverage: number;
  lastMonthTotalSpend: number;
  lastMtdSpend: number;
}

export function ForecastingMeters(props: Props) {
  const theme = useTheme();

  const estimatedTotalSpend =
    props.currentMtdSpend + props.currentMtdForecastedSpend;

  const estimatedDeltaSpend = estimatedTotalSpend - props.last3MonthAverage;

  const absoluteDifferenceMtd = props.currentMtdSpend - props.lastMtdSpend;

  const percentDifferenceMtd = absoluteDifferenceMtd / props.lastMtdSpend;

  const mtdDifferenceColor =
    props.currentMtdSpend > props.lastMtdSpend
      ? theme.feedback_negative
      : theme.feedback_positive;

  const mtdDifferenceBackgroundColor =
    props.currentMtdSpend > props.lastMtdSpend
      ? theme.tag_button_background_color_danger_hover
      : theme.feedback_positive;

  const absoluteDifferenceTotal =
    estimatedTotalSpend - props.lastMonthTotalSpend;

  const percentDifferenceTotal =
    absoluteDifferenceTotal / props.lastMonthTotalSpend;

  const totalDifferenceColor =
    estimatedTotalSpend > props.lastMonthTotalSpend
      ? theme.feedback_negative
      : theme.feedback_positive;

  const totalDifferenceBackgroundColor =
    estimatedTotalSpend > props.lastMonthTotalSpend
      ? theme.tag_button_background_color_danger_hover
      : theme.feedback_positive;

  const percentDifferenceMonthlyDelta =
    estimatedDeltaSpend / props.last3MonthAverage;

  const monthlyDifferenceColor =
    estimatedTotalSpend > props.last3MonthAverage
      ? theme.feedback_negative
      : theme.feedback_positive;

  const monthlyDifferenceBackgroundColor =
    estimatedTotalSpend > props.last3MonthAverage
      ? theme.tag_button_background_color_danger_hover
      : theme.feedback_positive;

  return (
    <Flex justifyContent="space-between" marginBottom={theme.space_lg}>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        height={140}
        padding={theme.space_md}
        width="32%"
      >
        {props.isLoading ? (
          <EmptyPlaceholder
            icon={faList}
            loading={props.isLoading}
            small
            skeletonVariant="meter"
          />
        ) : (
          <>
            <Box>
              <Text color={theme.text_color_secondary}>
                {copyText.meterLabelCurrentMtdSpend}
              </Text>
              <Flex justifyContent="space-between">
                <Text appearance="h3">
                  {formatCurrencyRounded({
                    maxCharacters: 8,
                    number: props.currentMtdSpend,
                  })}
                </Text>
                <Flex
                  alignItems="center"
                  backgroundColor={mtdDifferenceBackgroundColor}
                  borderRadius={theme.borderRadius_3}
                  paddingHorizontal={theme.space_sm}
                >
                  <Icon
                    color="white"
                    icon={percentDifferenceMtd > 0 ? faArrowUp : faArrowDown}
                  />
                  <Text appearance="h4" marginLeft={theme.space_sm}>
                    {formatPercentage(percentDifferenceMtd)}
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Box>
              <Text color={theme.text_color_secondary}>
                {copyText.meterLabelPreviousMtdSpend}
              </Text>
              <Flex justifyContent="space-between">
                <Text appearance="h4">
                  {formatCurrencyRounded({
                    maxCharacters: 8,
                    number: props.lastMtdSpend,
                  })}
                </Text>
                <Flex alignItems="center" paddingHorizontal={theme.space_sm}>
                  <Icon
                    color={mtdDifferenceColor}
                    icon={absoluteDifferenceMtd > 0 ? faArrowUp : faArrowDown}
                  />
                  <Text
                    appearance="h4"
                    color={mtdDifferenceColor}
                    marginLeft={theme.space_sm}
                  >
                    {formatCurrencyRounded({
                      maxCharacters: 8,
                      number: absoluteDifferenceMtd,
                    })}
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </>
        )}
      </Box>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        height={140}
        padding={theme.space_md}
        width="32%"
      >
        {props.isLoading ? (
          <EmptyPlaceholder
            icon={faList}
            loading={props.isLoading}
            small
            skeletonVariant="meter"
          />
        ) : (
          <>
            <Box>
              <Text color={theme.text_color_secondary}>
                {copyText.meterLabelEstimatedMonthlyTotalSpend}
              </Text>
              <Flex justifyContent="space-between">
                <Text appearance="h3">
                  {formatCurrencyRounded({
                    maxCharacters: 8,
                    number: estimatedTotalSpend,
                  })}
                </Text>
                <Flex
                  alignItems="center"
                  backgroundColor={totalDifferenceBackgroundColor}
                  borderRadius={theme.borderRadius_3}
                  paddingHorizontal={theme.space_sm}
                >
                  <Icon
                    color="white"
                    icon={percentDifferenceTotal > 0 ? faArrowUp : faArrowDown}
                  />
                  <Text appearance="h4" marginLeft={theme.space_sm}>
                    {formatPercentage(percentDifferenceTotal)}
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Box>
              <Text color={theme.text_color_secondary}>
                {copyText.meterLabelPreviousMonthTotal}
              </Text>
              <Flex justifyContent="space-between">
                <Text appearance="h4">
                  {formatCurrencyRounded({
                    maxCharacters: 8,
                    number: props.lastMonthTotalSpend,
                  })}
                </Text>
                <Flex alignItems="center" paddingHorizontal={theme.space_sm}>
                  <Icon
                    color={totalDifferenceColor}
                    icon={absoluteDifferenceTotal > 0 ? faArrowUp : faArrowDown}
                  />
                  <Text
                    appearance="h4"
                    color={totalDifferenceColor}
                    marginLeft={theme.space_sm}
                  >
                    {formatCurrencyRounded({
                      maxCharacters: 8,
                      number: absoluteDifferenceTotal,
                    })}
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </>
        )}
      </Box>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        direction="column"
        height={140}
        justifyContent="space-between"
        padding={theme.space_md}
        width="32%"
      >
        {props.isLoading ? (
          <EmptyPlaceholder
            icon={faList}
            loading={props.isLoading}
            small
            skeletonVariant="meter"
          />
        ) : (
          <>
            <Box>
              <Text color={theme.text_color_secondary}>
                {copyText.meterLabelEstimatedMonthlyTotalSpend}
              </Text>
              <Flex justifyContent="space-between">
                <Text appearance="h3">
                  {formatCurrencyRounded({
                    maxCharacters: 8,
                    number: estimatedTotalSpend,
                  })}
                </Text>
                <Flex
                  alignItems="center"
                  backgroundColor={monthlyDifferenceBackgroundColor}
                  borderRadius={theme.borderRadius_3}
                  paddingHorizontal={theme.space_sm}
                >
                  <Icon
                    color="white"
                    icon={
                      percentDifferenceMonthlyDelta > 0
                        ? faArrowUp
                        : faArrowDown
                    }
                  />
                  <Text appearance="h4" marginLeft={theme.space_sm}>
                    {formatPercentage(percentDifferenceMonthlyDelta)}
                  </Text>
                </Flex>
              </Flex>
            </Box>

            <Box>
              <Text color={theme.text_color_secondary}>
                {copyText.meterLabelPrevious3MonthAverageSpend}
              </Text>
              <Flex justifyContent="space-between">
                <Text appearance="h4">
                  {formatCurrencyRounded({
                    maxCharacters: 8,
                    number: props.last3MonthAverage,
                  })}
                </Text>

                <Flex justifyContent="flex-end">
                  <Flex alignItems="center" paddingHorizontal={theme.space_sm}>
                    <Icon
                      color={monthlyDifferenceColor}
                      icon={estimatedDeltaSpend > 0 ? faArrowUp : faArrowDown}
                    />
                    <Text
                      appearance="h4"
                      color={monthlyDifferenceColor}
                      marginLeft={theme.space_sm}
                    >
                      {formatCurrencyRounded({
                        maxCharacters: 8,
                        number: estimatedDeltaSpend,
                      })}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default ForecastingMeters;
