import useGetUsersByTenantID from "@/api/core/hooks/useGetUsersByTenantID";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import { useFilterStore } from "@/context/FilterStoreProvider";
import useAvailableDimensions from "@/hooks/useAvailableDimensions";
import { DateHelper } from "@/lib/dates";
import { FilterData } from "@/lib/react-router";
import { QueryFilter } from "@/types";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  DataSource,
  Operator,
  UserConfigStatus,
} from "@ternary/api-lib/constants/enums";
import {
  ScopedViewEntity,
  ScopedViewFilter,
} from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import { isEqual, keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import { JsonParam, useQueryParams } from "use-query-params";
import useGetDimensionValues from "../api/analytics/useGetDimensionValues";
import { AuthenticatedUserEntity } from "../api/core/types";
import commonCopyText from "../common.copyText";
import useGetDimensionPreferencesByTenantID from "../features/admin/hooks/useGetDimensionPreferencesByTenantID";
import ScopedViewSaveForm from "../features/user-settings/components/ScopedViewSaveForm";
import featureCopyText from "../features/user-settings/copyText";
import scopedViewKeys from "../features/user-settings/hooks/queryKeys";
import useCreateScopedView from "../features/user-settings/hooks/useCreateScopedView";
import useGetScopedViewsByTenantID from "../features/user-settings/hooks/useGetScopedViewsByTenantID";
import GlobalFilterButton, { Variant } from "./GlobalFilterButton";
import GlobalFilterModal from "./GlobalFilterModal";
import SideDrawerLegacy from "./SideDrawerLegacy";

type Interaction =
  | GlobalFilterModal.Interaction
  | ScopedViewSaveForm.Interaction;

interface Props {
  authenticatedUser: AuthenticatedUserEntity;
  isEnabled?: boolean;
}

interface State {
  availableScopedViewIDs: string[];
  filters: ScopedViewFilter[];
  isModalOpen: boolean;
  isSideDrawerOpen: boolean;
}

const initialState: State = {
  availableScopedViewIDs: [],
  filters: [],
  isModalOpen: false,
  isSideDrawerOpen: false,
};

const copyText = { ...commonCopyText, ...featureCopyText };

export default function GlobalFilterManagementContainer(
  props: Props
): JSX.Element {
  const activityTracker = useActivityTracker();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const dateHelper = new DateHelper();

  //
  // Search Params
  //

  const [searchParamState, setSearchParamState] = useQueryParams({
    filter_data: JsonParam,
  });

  //
  // State
  //

  const filterStore = useFilterStore();

  const [state, setState] = useState<State>({
    ...initialState,
    availableScopedViewIDs: filterStore.availableScopedViewIDs,
    filters: filterStore.customFilters,
  });

  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: scopedViews = [] } = useGetScopedViewsByTenantID(
    props.authenticatedUser.tenant.fsDocID
  );

  const isSharedView =
    !!searchParamState.filter_data?.auth_id &&
    searchParamState.filter_data.auth_id !== props.authenticatedUser.id;

  const { data: users = [] } = useGetUsersByTenantID(
    props.authenticatedUser.tenant.fsDocID
  );

  const { data: dimensionPreferences = [] } =
    useGetDimensionPreferencesByTenantID(props.authenticatedUser.tenant.id);

  const validFilters = state.filters.filter((filter) => {
    return filter.name.length !== 0;
  });

  const dataSourceDimensions = validFilters.reduce(
    (accum: { dataSource: DataSource; dimensions: string[] }[], filter) => {
      const currentIndex = accum.findIndex(
        (value) => value.dataSource === filter.dataSource
      );

      if (currentIndex === -1) {
        accum.push({
          dataSource: filter.dataSource,
          dimensions: [filter.name],
        });
      } else {
        accum[currentIndex].dimensions = [
          ...accum[currentIndex].dimensions,
          filter.name,
        ];
      }

      return accum;
    },
    []
  );

  const {
    data: dimensionValuesMap = {},
    isLoading: isLoadingDimensionValuesMap,
  } = useGetDimensionValues(
    {
      dateRange: [dateHelper.nMonthsAgo(6), dateHelper.date],
      dataSourceDimensions,
    },
    { enabled: dataSourceDimensions.length > 0 }
  );

  const availableScopedViews = scopedViews.reduce(
    (accum: ScopedViewEntity[], scopedView) =>
      state.availableScopedViewIDs.includes(scopedView.id)
        ? [...accum, scopedView]
        : accum,
    []
  );

  const { isPending: isCreatingScopedView, mutate: createScopedView } =
    useCreateScopedView({
      onError: () => {
        postAlert({
          message: copyText.errorCreatingScopedViewMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (newScopedViewID, params) => {
        queryClient.refetchQueries({ queryKey: scopedViewKeys.all });

        postAlert({
          message: copyText.successCreatingScopedViewMessage,
          type: AlertType.SUCCESS,
        });

        const isApplied = params.userConfigs.some(
          (userConfig) =>
            userConfig.userID === props.authenticatedUser.id &&
            userConfig.status === UserConfigStatus.ENABLED
        );

        // If enabled for a current user then update searchParams and filterStore
        if (isApplied) {
          const data: FilterData = {
            ...(filterStore.customFilters
              ? { filters: filterStore.customFilters }
              : {}),
            auth_id: props.authenticatedUser.id,
            sv_ids: [...state.availableScopedViewIDs, newScopedViewID],
          };

          setSearchParamState({ filter_data: data }, "replace");

          filterStore.set({
            scopedViews: [
              ...filterStore.scopedViews,
              {
                ...params,
                id: newScopedViewID,
              },
            ],
          });
        }
      },
    });

  //
  // Side Effects
  //

  // Reconcile local state if Scoped Views change for current user
  useEffect(() => {
    if (
      isEqual(
        state.availableScopedViewIDs,
        filterStore.availableScopedViewIDs
      ) &&
      isEqual(state.filters, filterStore.customFilters)
    ) {
      return;
    }

    mergeState({
      availableScopedViewIDs: filterStore.availableScopedViewIDs,
    });
  }, [filterStore.availableScopedViewIDs, filterStore.customFilters]);

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case GlobalFilterModal.INTERACTION_ADD_FILTER_BUTTON_CLICKED: {
        const filter = {
          dataSource: interaction.dataSource,
          name: "",
          operator: Operator.EQUALS,
          values: [],
        };

        setState((currentState) => ({
          ...currentState,
          filters: [...currentState.filters, filter],
        }));
        return;
      }
      case GlobalFilterModal.INTERACTION_ADD_SCOPED_VIEW_BUTTON_CLICKED: {
        setState((currentState) => ({
          ...currentState,
          availableScopedViewIDs: [
            ...currentState.availableScopedViewIDs,
            interaction.scopedViewID,
          ],
        }));
        return;
      }
      case GlobalFilterModal.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({
          availableScopedViewIDs: searchParamState.filter_data?.sv_ids ?? [],
          filters: searchParamState.filter_data?.filters ?? [],
          isModalOpen: false,
        });
        return;
      }
      case GlobalFilterModal.INTERACTION_CLEAR_FILTERS: {
        setState((currentState) => ({
          ...currentState,
          availableScopedViewIDs: currentState.availableScopedViewIDs.filter(
            (id) =>
              scopedViewsKeyedByID[id].userConfigs.some(
                (userConfig) =>
                  userConfig.userID === props.authenticatedUser.id &&
                  (userConfig.status === UserConfigStatus.ENFORCED ||
                    userConfig.status === UserConfigStatus.ENABLED_STRICT)
              )
          ),
          filters: [],
        }));
        return;
      }
      case GlobalFilterModal.INTERACTION_FILTER_NAME_CHANGED: {
        setState((currentState) => {
          const filter = currentState.filters[interaction.index];

          return {
            ...currentState,
            filters: [
              ...currentState.filters.slice(0, interaction.index),
              {
                ...filter,
                name: interaction.name,
                values: [],
              },
              ...currentState.filters.slice(interaction.index + 1),
            ],
          };
        });
        return;
      }
      case GlobalFilterModal.INTERACTION_FILTER_OPERATOR_CHANGED: {
        setState((currentState) => {
          const filter = currentState.filters[interaction.index];

          if (!filter) return currentState;

          let values: QueryFilter["values"] = null;

          switch (interaction.operator) {
            case Operator.EQUALS:
            case Operator.NOT_EQUALS:
              values = filter.values ?? [];
              break;
            case Operator.CONTAINS:
            case Operator.NOT_CONTAINS:
              values =
                filter.values && filter.values[0] ? [filter.values[0]] : [];
              break;
            case Operator.NOT_SET:
            case Operator.SET:
              values = null;
              break;
          }

          const filters = [
            ...currentState.filters.slice(0, interaction.index),
            { ...filter, operator: interaction.operator, values },
            ...currentState.filters.slice(interaction.index + 1),
          ];

          return { ...currentState, filters };
        });
        return;
      }
      case GlobalFilterModal.INTERACTION_FILTER_VALUES_CHANGED: {
        setState((currentState) => {
          const filter = currentState.filters[interaction.index];

          if (!filter) return currentState;

          const filters = [
            ...currentState.filters.slice(0, interaction.index),
            { ...filter, values: interaction.values },
            ...currentState.filters.slice(interaction.index + 1),
          ];

          return { ...currentState, filters };
        });
        return;
      }
      case GlobalFilterModal.INTERACTION_REMOVE_FILTER_BUTTON_CLICKED: {
        setState((currentState) => ({
          ...currentState,
          filters: [
            ...currentState.filters.slice(0, interaction.index),
            ...currentState.filters.slice(interaction.index + 1),
          ],
        }));
        return;
      }
      case GlobalFilterModal.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        const enabledScopedViews = scopedViews.reduce(
          (accum: ScopedViewEntity[], scopedView) =>
            state.availableScopedViewIDs.includes(scopedView.id)
              ? [...accum, scopedView]
              : accum,
          []
        );

        const data: FilterData = {
          auth_id: props.authenticatedUser.id,
          ...(state.filters.length > 0 ? { filters: state.filters } : {}),
          ...(state.availableScopedViewIDs.length > 0
            ? { sv_ids: state.availableScopedViewIDs }
            : {}),
        };

        setSearchParamState({ filter_data: data }, "replace");

        filterStore.set({
          customFilters: state.filters,
          scopedViews: enabledScopedViews,
        });

        mergeState({ isModalOpen: false });

        return;
      }
      case GlobalFilterModal.INTERACTION_TOGGLE_SCOPED_VIEW_BUTTON_CLICKED: {
        setState((currentState) => {
          let enabledScopedViewIDs: string[] = [];

          if (interaction.isMuted) {
            enabledScopedViewIDs = [
              ...currentState.availableScopedViewIDs,
              interaction.scopedViewID,
            ];
          } else {
            enabledScopedViewIDs = currentState.availableScopedViewIDs.filter(
              (id) => id !== interaction.scopedViewID
            );
          }

          return {
            ...currentState,
            availableScopedViewIDs: enabledScopedViewIDs,
          };
        });

        return;
      }
      case GlobalFilterModal.INTERACTION_CREATE_SCOPED_VIEW_BUTTON_CLICKED: {
        mergeState({ isSideDrawerOpen: true });
        return;
      }
      case ScopedViewSaveForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ isSideDrawerOpen: false });
        return;
      }
      case ScopedViewSaveForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        activityTracker.captureAction(actions.CLICK_SCOPED_VIEW_MODAL_CREATED);

        createScopedView({
          tenantID: props.authenticatedUser.tenant.fsDocID,
          applyToNewUserStatus: interaction.applyToNewUserStatus,
          filters: interaction.filters.map((filter) => ({
            dataSource: filter.dataSource,
            name: filter.name,
            operator: filter.operator,
            values: filter.values ?? null,
          })),
          name: interaction.name,
          type: interaction.scopedViewType,
          userConfigs: interaction.userConfigs,
        });

        mergeState({ isSideDrawerOpen: false });
        return;
      }
    }
  }

  //
  // Render
  //

  const availableDimensionsMap = useAvailableDimensions();

  const scopedViewsKeyedByID = keyBy(scopedViews, "id");

  const defaultScopedViews = scopedViews.filter((scopedView) =>
    scopedView.userConfigs.some(
      (userConfig) =>
        userConfig.userID === props.authenticatedUser.id &&
        (userConfig.status === UserConfigStatus.ENABLED ||
          userConfig.status === UserConfigStatus.ENFORCED ||
          userConfig.status === UserConfigStatus.ENABLED_STRICT)
    )
  );

  const noScopedViewsEnabled = defaultScopedViews.length === 0;

  const defaultScopedViewsEnabled =
    isEqual(
      defaultScopedViews
        .map(({ id }) => id)
        .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1)),
      state.availableScopedViewIDs.sort((a, b) =>
        a.toLowerCase() < b.toLowerCase() ? -1 : 1
      )
    ) && state.filters.length === 0;

  const customScopedViewsEnabled =
    !defaultScopedViewsEnabled || state.filters.length > 0;

  let variant: Variant = "off";

  switch (true) {
    case noScopedViewsEnabled:
      variant = "off";
      break;
    case defaultScopedViewsEnabled:
      variant = "default";
      break;
    case customScopedViewsEnabled:
      variant = "custom";
      break;
  }

  const enabledCount =
    state.availableScopedViewIDs.length + state.filters.length;

  const buttonText = `${copyText.globalFilterButtonLabel}${getButtonText(
    enabledCount,
    scopedViewsKeyedByID[state.availableScopedViewIDs[0]]
  )}`;

  return (
    <Box>
      {props.isEnabled && (
        <GlobalFilterButton
          disabled={isSharedView}
          locked={false}
          variant={variant}
          onClick={() => mergeState({ isModalOpen: true })}
        >
          {buttonText}
        </GlobalFilterButton>
      )}
      {state.isModalOpen && (
        <GlobalFilterModal
          availableDimensionsMap={availableDimensionsMap}
          dimensionValuesMap={dimensionValuesMap}
          availableScopedViewIDs={state.availableScopedViewIDs}
          isLoadingDimensionValuesMap={isLoadingDimensionValuesMap}
          filters={state.filters}
          dimensionPreferences={dimensionPreferences}
          scopedViews={scopedViews}
          onInteraction={handleInteraction}
        />
      )}
      <Flex height="100vh" position="fixed" zIndex={theme.zIndex_100}>
        <SideDrawerLegacy
          isOpen={state.isSideDrawerOpen}
          title={copyText.sideDrawerTitleSave}
          renderContent={() =>
            state.isSideDrawerOpen ? (
              <ScopedViewSaveForm
                filters={state.filters}
                isProcessing={isCreatingScopedView}
                scopedViews={availableScopedViews}
                users={users}
                onInteraction={handleInteraction}
              />
            ) : null
          }
          width="700px"
          onClose={() => mergeState({ isSideDrawerOpen: false })}
        />
      </Flex>
    </Box>
  );
}

function getButtonText(
  enabledCount: number,
  scopedView?: { name: string }
): string {
  if (enabledCount === 0) return "";

  if (enabledCount === 1 && scopedView) {
    return `: ${scopedView.name}`;
  }

  return ` (${enabledCount})`;
}
