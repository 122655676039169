import { useTheme } from "@emotion/react";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { Operator, TimeGranularity } from "@ternary/api-lib/constants/enums";
import { lineStyleProps } from "@ternary/api-lib/ui-lib/charts/styles";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import { add, startOfHour, sub } from "date-fns";
import React, { useMemo } from "react";
import {
  Line,
  LineChart,
  ReferenceDot,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import useGetSparklineData from "../hooks/useGetSparklineData";
import { CostAlertDimension } from "../types";

type Props = {
  dimensionGrouping: CostAlertDimension[];
  granularity?: TimeGranularity;
  occurredAt: string;
};

export default function CostAlertSparkLineChart(props: Props) {
  const { granularity = TimeGranularity.HOUR } = props;

  const dateRange: [string, string] = useMemo(() => {
    if (granularity === TimeGranularity.DAY) {
      return [
        sub(startOfHour(new Date(props.occurredAt)), {
          days: 6,
        }).toISOString(),
        add(startOfHour(new Date(props.occurredAt)), {
          days: 3,
        }).toISOString(),
      ];
    }

    return [
      sub(startOfHour(new Date(props.occurredAt)), { hours: 6 }).toISOString(),
      add(startOfHour(new Date(props.occurredAt)), { hours: 3 }).toISOString(),
    ];
  }, [granularity]);

  const filters: QueryFilter[] = props.dimensionGrouping.map((dg) =>
    dg.value
      ? {
          name: dg.key,
          operator: Operator.EQUALS,
          values: [dg.value],
        }
      : {
          name: dg.key,
          operator: Operator.NOT_SET,
        }
  );

  const { data: newData = [], isLoading } = useGetSparklineData({
    dateRange,
    filters,
    granularity,
  });

  const theme = useTheme();

  if (isLoading) {
    return (
      <Flex alignItems="center" height={50} justifyContent="center" width={90}>
        <LoadingSpinner />
      </Flex>
    );
  }

  const dotDatum = newData.find(
    (d) => d.timestamp === startOfHour(new Date(props.occurredAt)).toISOString()
  );

  const dotValue = dotDatum?.cost;

  return (
    <Box width={90} height={50}>
      <ResponsiveContainer width="100%">
        <LineChart
          data={newData}
          height={30}
          margin={{ bottom: 3, left: 3, right: 3, top: 3 }}
          width={50}
        >
          <XAxis dataKey="timestamp" hide />
          <YAxis hide />
          <Line
            {...lineStyleProps}
            dataKey="cost"
            dot={false}
            stroke={theme.primary_color_background}
            strokeWidth={1.5}
            type="monotone"
          />
          <ReferenceDot
            fill="red"
            r={3}
            stroke="none"
            x={props.occurredAt}
            y={dotValue}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}
