import useRefFn from "@/hooks/useRefFn";
import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatCurrency } from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AWSDatabaseInstanceGroup,
  AWSDatabaseInstanceGroupFilters,
} from "../types";
import { createSortingUtils } from "@/utils/sort";

type TableData = {
  databaseType: string;
  family: string;
  familyType: string;
  instanceCount: number;
  instanceUnits: number;
  lineItemUsageAccountId: string;
  networkBytes: number;
  productMemoryBytes: number;
  productVCPU: number;
  region: string;
  totalCost: number;
  usedDiskBytes: number;
};

type Props = {
  instanceGroups: AWSDatabaseInstanceGroup[];
  isLoading: boolean;
  onInteraction: (
    interaction: AWSDatabaseInstanceGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  id: z.string(),
  desc: z.boolean(),
});

const { numberSort, stringSort } = createSortingUtils<TableData>({
  validKeys: [
    "family",
    "familyType",
    "instanceCount",
    "lineItemUsageAccountId",
    "networkBytes",
    "productMemoryBytes",
    "productVCPU",
    "region",
    "totalCost",
    "usedDiskBytes",
  ],
});

function AWSDatabaseInstanceGroupTable(props: Props) {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      id: "totalCost",
      desc: true,
    })
  );

  const tableData = useMemo(
    (): TableData[] =>
      props.instanceGroups.map((instanceGroup) => ({
        databaseType: instanceGroup.databaseType,
        family: instanceGroup.family,
        familyType: instanceGroup.familyType,
        instanceCount: instanceGroup.instanceIDCount,
        instanceUnits: instanceGroup.instanceUnits,
        lineItemUsageAccountId: instanceGroup.lineItemUsageAccountId,
        networkBytes: instanceGroup.networkBytes,
        productMemoryBytes: instanceGroup.productMemoryBytes,
        productVCPU: instanceGroup.productVCPU,
        region: instanceGroup.region,
        totalCost: instanceGroup.totalCost,
        usedDiskBytes: instanceGroup.usedDiskBytes,
      })),
    [props.instanceGroups]
  );

  // useRefFn keeps the props.onInteraction closure from becoming stale
  const onInteraction = useRefFn(props.onInteraction);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "viewInstances",
        cell: ({ row }) => (
          <Tooltip
            content={
              copyText.awsDatabaseTableInstanceGroupTooltipLabelViewInstances
            }
          >
            <LinkWithSearchParams
              searchParams={{
                selected_group: getSelectedGroupJSON(row.original),
              }}
            >
              <Button
                iconStart={<Icon icon={faTableList} />}
                primary
                size="tiny"
              />
            </LinkWithSearchParams>
          </Tooltip>
        ),
        enableSorting: false,
        size: 50,
      }),
      columnHelper.accessor("lineItemUsageAccountId", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "lineItemUsageAccountId",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.awsDatabaseTableNull}
          </Text>
        ),
        header: () => (
          <Tooltip
            content={
              <Box width={200}>
                <Text color={theme.text_color_inverse}>
                  {copyText.awsIAMListPermissionTooltipMessage}
                </Text>
              </Box>
            }
          >
            {copyText.awsComputeTableInstanceGroupHeader_lineItemUsageAccountId}
          </Tooltip>
        ),
        meta: { align: "left" },
        size: 140,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("region", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "region",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.awsDatabaseTableNull}
          </Text>
        ),
        header: copyText.awsDatabaseTableInstanceGroupHeader_region,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("family", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "family",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.awsDatabaseTableNull}
          </Text>
        ),
        header: copyText.awsDatabaseTableInstanceGroupHeader_family,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("familyType", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "familyType",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.awsDatabaseTableNull}
          </Text>
        ),
        header: copyText.awsDatabaseTableInstanceGroupHeader_familyType,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("databaseType", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "databaseType",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.awsDatabaseTableNull}
          </Text>
        ),
        header: copyText.awsDatabaseTableInstanceGroupHeader_databaseType,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("instanceCount", {
        cell: ({ getValue }) => getValue(),
        header: copyText.awsDatabaseTableInstanceGroupHeader_instanceCount,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("instanceUnits", {
        cell: ({ getValue }) => getValue(),
        header: copyText.awsDatabaseTableInstanceGroupHeader_instanceUnits,
        meta: { align: "right" },
        size: 60,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsDatabaseTableInstanceGroupHeader_totalCost,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("productVCPU", {
        cell: ({ getValue }) => getValue(),
        header: copyText.awsDatabaseTableInstanceGroupHeader_productVCPU,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("productMemoryBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsDatabaseTableInstanceGroupHeader_productMemoryBytes,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("networkBytes", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header: copyText.awsDatabaseTableInstanceGroupHeader_networkBytes,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("usedDiskBytes", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header: copyText.awsDatabaseTableInstanceGroupHeader_usedDiskBytes,
        meta: { align: "right" },
        size: 100,
      }),
    ],
    [onInteraction]
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      initialState={{ sorting: [sortRule] }}
      isLoading={props.isLoading}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

function getSelectedGroupJSON(datum: TableData) {
  try {
    return JSON.stringify({
      family: datum.family,
      familyType: datum.familyType,
      lineItemUsageAccountId: datum.lineItemUsageAccountId,
      databaseType: datum.databaseType,
      region: datum.region,
    });
  } catch {
    return "";
  }
}

AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED =
  `AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof AWSDatabaseInstanceGroupFilters;
  filterValue: AWSDatabaseInstanceGroupFilters[keyof AWSDatabaseInstanceGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSDatabaseInstanceGroupTable {
  export type Interaction = InteractionFilterClicked;
}

export default AWSDatabaseInstanceGroupTable;
