import { createStructParam } from "@/lib/use-query-params";
import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { AWSDatabaseInstance } from "../types";
import { createSortingUtils } from "@/utils/sort";

type TableData = {
  cpuUtilization: number;
  databaseConnections: number;
  instanceId: string;
  instanceType: string;
  networkBytes: number;
  productMemoryBytes: number;
  productVCPU: number;
  ramUtilization: number;
  totalCost: number;
  usedDiskBytes: number;
};

type Props = {
  instances: AWSDatabaseInstance[];
  isLoading: boolean;
  onClose: () => void;
};

const sortRuleStruct = z.object({
  id: z.string(),
  desc: z.boolean(),
});

const { numberSort, stringSort } = createSortingUtils<TableData>({
  validKeys: [
    "cpuUtilization",
    "databaseConnections",
    "instanceId",
    "instanceType",
    "networkBytes",
    "productMemoryBytes",
    "productVCPU",
    "ramUtilization",
    "totalCost",
    "usedDiskBytes",
  ],
});

export default function AWSDatabaseInstanceTable(props: Props) {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  const [sortRule, setSortRule] = useQueryParam(
    "instance_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const [searchText, setSearchText] = useState("");

  const tableData = useMemo(
    () => getTableData(props.instances),
    [props.instances]
  );

  const filteredData = useMemo(() => {
    if (searchText.length === 0) return tableData;

    return tableData.filter((bucket) => {
      const str = searchText.toLowerCase();

      const instanceId = bucket.instanceId.toLowerCase();
      const instanceType = bucket.instanceType.toLowerCase();

      return instanceId.includes(str) || instanceType.includes(str);
    });
  }, [tableData, searchText]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("instanceId", {
        cell: ({ getValue }) => (
          <>{getValue() || copyText.awsDatabaseTableNull}</>
        ),
        header: copyText.awsDatabaseTableInstanceHeader_instanceId,
        meta: { align: "left", truncate: true },
        size: 220,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsDatabaseTableInstanceHeader_totalCost,
        meta: { align: "right" },
        size: 125,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("instanceType", {
        cell: ({ getValue }) => (
          <>{getValue() || copyText.awsDatabaseTableNull}</>
        ),
        header: copyText.awsDatabaseTableInstanceHeader_instanceType,
        meta: { align: "left" },
        size: 125,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("productVCPU", {
        cell: ({ getValue }) => getValue(),
        header: copyText.awsDatabaseTableInstanceHeader_productVCPU,
        meta: { align: "right" },
        size: 125,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("cpuUtilization", {
        cell: ({ getValue }) => <>{formatPercentage(getValue() / 100)}</>,
        header: copyText.awsDatabaseTableInstanceHeader_cpuUtilization,
        meta: { align: "right" },
        size: 125,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("productMemoryBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsDatabaseTableInstanceHeader_productMemoryBytes,
        meta: { align: "right" },
        size: 125,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("ramUtilization", {
        cell: ({ getValue }) => <>{formatPercentage(getValue() / 100)}</>,
        header: copyText.awsDatabaseTableInstanceHeader_ramUtilization,
        meta: { align: "right" },
        size: 125,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("networkBytes", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header: copyText.awsDatabaseTableInstanceHeader_networkBytes,
        meta: { align: "right" },
        size: 125,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("usedDiskBytes", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header: copyText.awsDatabaseTableInstanceHeader_usedDiskBytes,
        meta: { align: "right" },
        size: 125,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("databaseConnections", {
        cell: ({ getValue }) => getValue(),
        header: copyText.awsDatabaseTableInstanceHeader_databaseConnections,
        meta: { align: "right" },
        size: 125,
        sortingFn: numberSort,
      }),
    ],
    []
  );

  return (
    <Modal isOpen showCloseButton onClose={props.onClose} minWidth={1100}>
      <Modal.Header>
        <Flex
          justifyContent="space-between"
          marginLeft={theme.space_sm}
          width="100%"
        >
          <Text fontSize={theme.h4_fontSize}>
            {copyText.awsDatabaseTableInstanceTitle}
          </Text>

          <Flex alignItems="center">
            <Box width={250} marginRight={theme.space_lg}>
              <TextInput
                disabled={!props.instances.length}
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchInputPlaceholder}
                size="medium"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Box>

            <CSVLink
              data={flattenDataToCSV(filteredData)}
              filename={`Instances-${formatDate(new Date(), "MM-dd-yyyy")}`}
            >
              <Button
                iconStart={<Icon color="inherit" icon={faFileExport} />}
                primary
                size="small"
                marginRight={theme.space_md}
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Table
          columns={columns}
          data={filteredData}
          initialState={{ sorting: [sortRule] }}
          isLoading={props.isLoading}
          showPagination
          sortable
          onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
        />
      </Modal.Body>
    </Modal>
  );
}

function getTableData(instances: AWSDatabaseInstance[]): TableData[] {
  return instances.map((instance) => ({
    cpuUtilization: instance.cpuUtilization,
    databaseConnections: instance.databaseConnections,
    instanceId: instance.instanceId,
    instanceType: instance.instanceType,
    networkBytes: instance.networkBytes,
    productMemoryBytes: instance.productMemoryBytes,
    productVCPU: instance.productVCPU,
    ramUtilization: instance.ramUtilization,
    totalCost: instance.totalCost,
    usedDiskBytes: instance.usedDiskBytes,
  }));
}

function flattenDataToCSV(data: TableData[]) {
  if (!data.length) return [];

  return data.map((datum) => ({
    cpuUtilization: datum.cpuUtilization,
    databaseConnections: datum.databaseConnections,
    instanceId: datum.instanceId,
    instanceType: datum.instanceType,
    networkBytes: datum.networkBytes,
    productMemoryBytes: datum.productMemoryBytes,
    productVCPU: datum.productVCPU,
    ramUtilization: datum.ramUtilization,
    totalCost: datum.totalCost,
    usedDiskBytes: datum.usedDiskBytes,
  }));
}
