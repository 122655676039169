import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faCheck,
  faEdit,
  faInfoCircle,
  faTimes,
  faTrash,
  faTrashAlt,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BudgetThresholdWarningType,
  DataSource,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { add, format, isBefore } from "date-fns";
import { isEqual, keyBy, uniq } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Switch from "react-switch";
import isEmail from "validator/lib/isEmail";
import { DimensionValuesMap } from "../../../api/analytics/useGetDimensionValues";
import {
  CreateBudgetParameters,
  UpdateBudgetParameters,
} from "../../../api/core/types";
import { DEFAULT_WIDTH } from "../../../components/SideDrawerLegacy";
import UserDualListbox from "../../../components/UserDualListbox";
import externalLinks from "../../../constants/externalLinks";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { DateHelper } from "../../../lib/dates";
import { FormField } from "../../../ui-lib/components/Form";
import NumberInput from "../../../ui-lib/components/NumberInput";
import Select from "../../../ui-lib/components/Select";
import TextInput from "../../../ui-lib/components/TextInput";
import {
  getMonthNameForMonthNumber,
  getMonthOptions,
  getYearOptions,
} from "../../../utils/dates";
import getCn from "../../../utils/getCn";
import getMergeState from "../../../utils/getMergeState";
import { groupOptionsByPreferences } from "../../admin/utils";
import { FormType } from "../constants";
import copyText from "../copyText";
import {
  Budget,
  BudgetScope,
  BudgetThreshold,
  UpdatedPeriodVersion,
} from "../types";
import {
  MonthSummary,
  getAllMonthSummariesFromBudget,
  getAmountFromBudget,
  getHasHighlightKeyedByMonth,
  getSortedMonthsFromPeriodVersions,
  getSortedThresholds,
} from "../utils";
import BudgetPeriodVersionsHistory from "./BudgetPeriodVersionsHistory";

const COMPONENT_NAME = "BudgetForm";
const cn = getCn(COMPONENT_NAME);

const StyledForm = styled.form<{ showMonths: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: ${(props) => props.theme.panel_backgroundColor};
  padding: ${(props) => props.theme.space_lg};
  padding-bottom: 0;

  > h2 {
    font-size: 1rem;
    font-weight: bold;
  }

  label {
    font-weight: bold;
    font-size: 0.8rem;
    white-space: nowrap;
  }

  .${cn("top-rows")} {
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    > div:first-of-type {
      width: 45%;
    }

    > div:nth-of-type(2) {
      width: 45%;
    }

    .${cn("adjacent-label")} {
      color: ${(props) => props.theme.text_color};
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
  }

  table {
    color: ${({ theme }) => theme.text_color};
  }

  th {
    font-weight: bold;
    font-size: 0.8rem;
    text-align: center;
  }

  td {
    padding: 0.25rem 0;
    text-align: center;
    font-size: 0.8rem;
  }

  .${cn("month-name")} {
    font-weight: bold;
  }

  .${cn("past-month")} {
    color: ${(props) => props.theme.text_color_secondary};

    input {
      color: ${(props) => props.theme.text_color_secondary};
    }
  }

  .${cn("current-month")} {
    background-color: ${({ theme }) => theme.secondary_color_background};
    font-weight: bold;
  }

  .${cn("clickable")} {
    svg {
      cursor: pointer;
    }
  }

  .${cn("warning-text")} {
    color: ${({ theme }) => theme.feedback_negative};
    font-size: 0.7rem;
    font-weight: bold;
    margin-top: 0.5rem;
  }

  .${cn("footer")} {
    position: sticky;
    bottom: 0;
    padding-top: ${(props) => props.theme.space_sm};
    padding-bottom: ${(props) => props.theme.space_lg};
    button {
      margin-left: 1rem;
    }
  }

  .fixed_header tbody {
    display: block;
    width: 100%;
  }
  .fixed_header tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .fixed_header thead {
    display: block;
  }

  .fixed_header td,
  .fixed_header th {
    :first-of-type,
    :nth-of-type(2),
    :nth-of-type(3) {
      width: calc((${DEFAULT_WIDTH} - 3rem) / 5);
    }
  }

  label,
  p {
    color: ${(props) => props.theme.text_color};
  }

  .${cn("starter-kit")} {
    width: 100%;
    margin-bottom: 1rem;

    .${cn("switch")} {
      text-align: center;
    }

    > div > div {
      width: 18%;
    }
  }
`;

type Option = {
  label: string | number;
  value: string | number;
};

type ScopeOptions = {
  label: string;
  value: string;
};

type AlertSubscribers = {
  includeAllUsers: boolean;
  userIDs: string[];
  externalEmails: string[];
};

type DimensionPreference = {
  dataSource: DataSource;
  category: string;
  values: string[];
};

type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

interface Props {
  availableDimensions: string[];
  budget?: Budget;
  dimensionValues: DimensionValuesMap;
  formType: FormType;
  dimensionPreferences: DimensionPreference[];
  isLoadingDimensionValues: boolean;
  isProcessing: boolean;
  users: User[];
  onCancel: () => void;
  onCreate: (params: CreateBudgetParameters) => void;
  onScopeChange: (scopeKeys: string[]) => void;
  onUpdatePeriodVersions: (
    budgetID: string,
    updatedPeriodVersions: UpdatedPeriodVersion[]
  ) => void;
  onUpdate: (budgetID: string, params: UpdateBudgetParameters) => void;
}

interface State {
  amount: number;
  externalSubscriberEmailInput: string;
  includeCredits: boolean;
  isVariable: boolean;
  measure: string;
  months: MonthSummary[];
  name: string;
  scopeInputs: BudgetScope[];
  selectedMonth: string | null;
  selectedSubscribers: AlertSubscribers;
  setAsHighlighted: boolean;
  thresholds: BudgetThreshold[];
  updatingSubscribers: AlertSubscribers | null;
  variableMonthCount: number;
  variableStartingAmount: number;
  variableStartMonth: Option | undefined;
  variableStartYear: Option | undefined;
}

export default function BudgetForm(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const now = new DateHelper();

  const emptyScope: BudgetScope = { key: "", values: [] };

  const emptyState = {
    updatingSubscribers: null,
    selectedSubscribers: {
      includeAllUsers: false,
      userIDs: [],
      externalEmails: [],
    },
    externalSubscriberEmailInput: "",
    amount: 0,
    includeCredits: false,
    isVariable: false,
    months: [],
    measure: "cost",
    name: "",
    scopeInputs: [emptyScope],
    selectedMonth: null,
    setAsHighlighted: false,
    thresholds: [],
    variableStartMonth: getMonthOptions().find(
      (opt) => Number(opt.value) === now.date.getMonth() + 1
    ),
    variableStartYear: getYearOptions().find(
      (opt) => opt.value === now.date.getFullYear()
    ),
    variableMonthCount: 12,
    variableStartingAmount: 0,
  };

  const [state, setState] = useState<State>(emptyState);
  const mergeState = getMergeState(setState);

  const externalEmailInputRef = useRef<HTMLInputElement | null>(null);

  const isCreating = props.formType === FormType.CREATE;

  useEffect(() => {
    if (!props.budget) {
      mergeState(emptyState);
      return;
    }
    const allMonthSummaries = getAllMonthSummariesFromBudget(props.budget);
    const thresholds = getSortedThresholds(props.budget.thresholds);
    const nameIncludesCopy =
      props.formType === FormType.COPY
        ? `${props.budget.name} (${copyText.actionCopy})`
        : props.budget.name;
    mergeState({
      amount: getAmountFromBudget(props.budget) || 0,
      isVariable: Boolean(props.budget.amount.variableAmount),
      measure: props.budget.measure,
      months: allMonthSummaries,
      name: nameIncludesCopy,
      scopeInputs: props.budget.scopes,
      selectedSubscribers: props.budget.subscribers,
      thresholds,
    });
  }, [props.budget]);

  function handleChangeIsUpdatingSubscribers(isUpdating: boolean) {
    if (isUpdating) {
      setState((prevState) => ({
        ...prevState,
        updatingSubscribers: { ...prevState.selectedSubscribers },
      }));
    } else {
      mergeState({ updatingSubscribers: null });
    }
  }

  function handleUpdateSelectedSubscribers() {
    if (!state.updatingSubscribers) return;

    mergeState({ selectedSubscribers: { ...state.updatingSubscribers } });
  }

  function handleInternalSubscribedUsersChange(newSelection: string[]) {
    if (!state.updatingSubscribers) return;

    setState((prevState) => {
      if (prevState.updatingSubscribers) {
        if (newSelection.length === props.users.length) {
          return {
            ...prevState,
            updatingSubscribers: {
              externalEmails: prevState.updatingSubscribers.externalEmails,
              includeAllUsers: true,
              userIDs: [],
            },
          };
        } else {
          return {
            ...prevState,
            updatingSubscribers: {
              externalEmails: prevState.updatingSubscribers.externalEmails,
              includeAllUsers: false,
              userIDs: newSelection.map((id) => id),
            },
          };
        }
      }

      return {
        ...prevState,
        selectedSubscribers: {
          externalEmails: prevState.selectedSubscribers.externalEmails,
          includeAllUsers: false,
          userIDs: newSelection.map((id) => id),
        },
      };
    });
  }

  function handleAddExternalSubscriberEmail() {
    const email = state.externalSubscriberEmailInput;

    if (!state.updatingSubscribers) return;
    if (!isEmail(email)) return;

    const internalUserWithEmail = props.users.find((user) =>
      isSameIgnoreCase(user.email, email)
    );
    if (internalUserWithEmail) return;

    setState((prevState) => {
      if (!prevState.updatingSubscribers) return prevState;

      let nextExternalEmails: string[];

      if (
        prevState.updatingSubscribers.externalEmails.find((other) =>
          isSameIgnoreCase(other, email)
        )
      ) {
        // this email is already in the list. Move to top
        nextExternalEmails = [
          email,
          ...prevState.updatingSubscribers.externalEmails.filter(
            (other) => !isSameIgnoreCase(other, email)
          ),
        ];
      } else {
        nextExternalEmails = [
          email,
          ...prevState.updatingSubscribers.externalEmails,
        ];
      }

      return {
        ...prevState,
        externalSubscriberEmailInput: "",
        updatingSubscribers: {
          ...prevState.updatingSubscribers,
          externalEmails: nextExternalEmails,
        },
      };
    });
  }

  function handleRemoveExternalSubscriber(emailToRemove: string) {
    function filterExternal<T extends AlertSubscribers | null>(
      subscribers: T
    ): T {
      if (!subscribers) return subscribers;

      return {
        ...subscribers,
        externalEmails: subscribers.externalEmails.filter(
          (email) => !isSameIgnoreCase(email, emailToRemove)
        ),
      };
    }

    setState((prevState) => {
      if (prevState.updatingSubscribers) {
        return {
          ...prevState,
          updatingSubscribers: filterExternal(prevState.updatingSubscribers),
        };
      }

      return {
        ...prevState,
        selectedSubscribers: filterExternal(prevState.selectedSubscribers),
      };
    });
  }

  function handleRemoveInternalSubscriber(userIDToRemove: string) {
    setState((prevState) => {
      if (prevState.updatingSubscribers) {
        return {
          ...prevState,
          updatingSubscribers: {
            ...prevState.updatingSubscribers,
            userIDs: prevState.updatingSubscribers.userIDs.filter(
              (id) => !isSameIgnoreCase(id, userIDToRemove)
            ),
          },
        };
      }

      return {
        ...prevState,
        selectedSubscribers: {
          ...prevState.selectedSubscribers,
          userIDs: prevState.selectedSubscribers.userIDs.filter(
            (id) => !isSameIgnoreCase(id, userIDToRemove)
          ),
        },
      };
    });
  }

  function handleChangeName(value: string) {
    mergeState({ name: value });
  }

  function handleChangeKey(value: string, index: number) {
    setState((currentState) => {
      const updatedScopes = [...currentState.scopeInputs];
      updatedScopes[index].key = value;
      updatedScopes[index].values = [];

      props.onScopeChange(updatedScopes.map((scope) => scope.key));

      return { ...currentState, scopeInputs: updatedScopes };
    });
  }

  function handleChangeValues(values: string[], index: number) {
    setState((currentState) => {
      const updatedScopes = [...currentState.scopeInputs];
      updatedScopes[index].values = values;

      return { ...currentState, scopeInputs: updatedScopes };
    });
  }

  function handleChangeNumber(text, key: string) {
    const str = text.replace(/,/g, "");
    const num = Number(str);
    if (Number.isNaN(num)) return;

    const res = num === 0 ? "" : num;
    mergeState({ [key]: res });
  }

  function handleToggleThresholdVersions(
    versionToToggle: string,
    index: number
  ) {
    const oneOfRequred = ["LATEST", "HIGHLIGHTED"];

    setState((st) => {
      const thresholds = st.thresholds.map((t) => ({ ...t }));
      const threshold = thresholds[index];

      let newVersions: string[] = [];

      // UnCheck it
      if (threshold.limitVersions.includes(versionToToggle)) {
        newVersions = threshold.limitVersions.filter(
          (v) => v !== versionToToggle
        );
      } else {
        // Check it
        newVersions = [...threshold.limitVersions, versionToToggle];
      }

      // If it results in an empty, push the default (the other in this case since there are only two)
      if (newVersions.length === 0) {
        const fallback = oneOfRequred.filter((v) => v !== versionToToggle)[0];
        newVersions.push(fallback);
      }

      thresholds[index].limitVersions = newVersions;

      return { ...st, thresholds };
    });
  }

  function handleUpdateThreshold(
    value: number | string,
    name: string,
    index: number
  ) {
    setState((st) => {
      const thresholds = st.thresholds;
      const threshold = thresholds[index];
      threshold[name] = value;

      return { ...st, thresholds };
    });
  }

  function handleAddScope() {
    mergeState({ scopeInputs: [...state.scopeInputs, emptyScope] });
  }

  function handleDeleteScope(index: number) {
    const updatedScopes = state.scopeInputs.filter((_, i) => i !== index);

    props.onScopeChange(updatedScopes.map((scope) => scope.key));

    mergeState({ scopeInputs: updatedScopes });
  }

  function handleAddAlert() {
    const emptyThreshold = {
      percent: 0,
      spendBasis: "actualSpend",
      limitVersions: ["LATEST"],
      warningType: BudgetThresholdWarningType.MONTHLY_SPEND,
    };

    setState((st) => ({
      ...st,
      thresholds: state.thresholds.concat(emptyThreshold),
    }));
  }

  function handleRemoveThreshold(index: number) {
    setState((st) => {
      const filtered = st.thresholds.filter((_, i) => i !== index);
      return { ...st, thresholds: filtered };
    });
  }

  // For new budgets, users can remove their months and start over
  function handleResetMonths() {
    mergeState({ months: [] });
  }

  function handleUpdateVersionLatest(value: number, index: number) {
    setState((st) => {
      const months = st.months;
      const month = months[index];
      month.latest = Number(value);

      return { ...st, months };
    });
  }

  function handleClose(): void {
    setState(emptyState);
    props.onCancel();
  }

  function generateMonths() {
    const months: MonthSummary[] = [];

    const currentDate = new Date(
      Number(state.variableStartYear?.value),
      Number(state.variableStartMonth?.value) - 1
    );

    for (let i = 0; i < state.variableMonthCount; i++) {
      const date = add(currentDate, { months: i });

      const month = format(date, "yyyy-MM");

      months.push({
        latestID: null,
        month,
        versions: 0,
        latest: state.variableStartingAmount,
        hasHighlighted: state.setAsHighlighted,
      });
    }

    mergeState({ months });
  }

  function appendNewMonth() {
    const months = state.months;
    const lastMonthDate = new Date(months[months.length - 1].month);
    const date = new Date(add(lastMonthDate, { months: 1, days: 15 })); // adding days to get month without timezone concerns

    const month = format(date, "yyyy-MM");
    months.push({
      latestID: null,
      month,
      versions: 0,
      latest: state.variableStartingAmount,
      hasHighlighted: state.setAsHighlighted,
    });

    mergeState({ months });
  }

  function handleSubmit() {
    if (props.formType !== FormType.UPDATE) {
      handleCreate();
    } else {
      handleUpdate();
    }
  }

  function handleCreate() {
    props.onCreate({
      ...(state.isVariable ? {} : { amount: state.amount }),
      tenantID: authenticatedUser.tenant.fsDocID,
      isVariable: state.isVariable,
      measure: state.measure,
      name: state.name,
      periods: state.isVariable
        ? state.months.map((summary) => ({
            amount: summary.latest ?? 0,
            highlighted: state.setAsHighlighted,
            month: summary.month,
          }))
        : [],
      scopes: state.scopeInputs,
      subscribers: state.selectedSubscribers,
      thresholds: state.thresholds,
    });
  }

  function handleUpdate() {
    if (!props.budget) return;

    const validScopes = state.scopeInputs.filter((scope) => {
      return scope.key.length && scope.values.length;
    });

    const budgetUpdates: UpdateBudgetParameters = {
      amount: state.isVariable ? null : state.amount,
      isVariable: state.isVariable,
      measure: state.measure,
      name: state.name,
      scopes: validScopes,
      subscribers: state.selectedSubscribers,
      thresholds: state.thresholds,
    };

    if (!state.isVariable) {
      props.onUpdate(props.budget.id, budgetUpdates);

      return;
    }

    // TODO: dedupe and conditionally do the following for isVariable
    const allMonthSummariesFromProps = getAllMonthSummariesFromBudget(
      props.budget
    );
    const allMonthSummariesFromState = state.months;

    const latestKeyedByMonthFromProps = allMonthSummariesFromProps.reduce(
      (accum, summary) => {
        accum[summary.month] = summary.latest;
        return accum;
      },
      {}
    );
    const latestKeyedByMonthFromState = allMonthSummariesFromState.reduce(
      (accum, summary) => {
        accum[summary.month] = summary.latest;
        return accum;
      },
      {}
    );

    const updatedMonths: string[] = [];
    const newMonths: string[] = [];

    Object.keys(latestKeyedByMonthFromProps).forEach((monthString) => {
      if (
        latestKeyedByMonthFromProps[monthString] !==
        latestKeyedByMonthFromState[monthString]
      ) {
        updatedMonths.push(monthString);
      }
    });

    Object.keys(latestKeyedByMonthFromState).forEach((monthString) => {
      if (!latestKeyedByMonthFromProps[monthString]) {
        newMonths.push(monthString);
      }
    });

    const monthsToAddVersion = [...updatedMonths, ...newMonths];

    const summariesToHighlight = state.setAsHighlighted
      ? allMonthSummariesFromState.filter(
          (summary) =>
            summary.latestID && !monthsToAddVersion.includes(summary.month)
        )
      : [];

    props.onUpdate(props.budget.id, {
      ...budgetUpdates,
      newPeriodVersions: state.months
        .filter((summary) => monthsToAddVersion.includes(summary.month))
        .map((summary) => ({
          amount: summary.latest ?? 0,
          highlighted: state.setAsHighlighted,
          month: summary.month,
        })),
      updatedPeriodVersions: summariesToHighlight.map((summary) => ({
        id: summary.latestID as string,
        highlighted: true,
        month: summary.month,
      })),
    });
  }

  function canSave() {
    return isCreating ? canCreate() : canUpdate();
  }

  function hasChanged() {
    // Only relevant to Updates
    if (!props.budget) return true;

    const nameChange = state.name !== props.budget.name;

    const measureChange = state.measure !== props.budget.measure;

    const specifiedAmountChange =
      (!state.isVariable &&
        state.amount !== getAmountFromBudget(props.budget)) ||
      (!state.isVariable &&
        props.budget.amount &&
        props.budget.amount.variableAmount);

    const isVariable =
      props.budget.amount && props.budget.amount.variableAmount;

    const startingMonthSummaries = isVariable
      ? getAllMonthSummariesFromBudget(props.budget)
      : [];

    let variableAmountChanged = false;

    if (startingMonthSummaries.length !== state.months.length) {
      variableAmountChanged = true;
    } else {
      startingMonthSummaries.forEach((month, i) => {
        if (month.latest !== state.months[i].latest) {
          variableAmountChanged = true;
        }
        if (month.hasHighlighted !== state.months[i].hasHighlighted) {
          variableAmountChanged = true;
        }
      });
    }

    let thresholdsChanged = false;
    const originalThresholds = props.budget.thresholds || [];

    if (originalThresholds.length !== state.thresholds.length) {
      thresholdsChanged = true;
    } else {
      const sortedThresholdsFromProps = getSortedThresholds(originalThresholds);
      state.thresholds.forEach((threshold, i) => {
        const original = sortedThresholdsFromProps[i];
        Object.keys(original).forEach((key) => {
          if (original[key] !== threshold[key]) {
            thresholdsChanged = true;
          }
        });
      });
    }

    const scopesChange = !isEqual(state.scopeInputs, props.budget?.scopes);

    const subscribersChange = !isEqual(
      state.selectedSubscribers,
      props.budget?.subscribers
    );

    return (
      measureChange ||
      nameChange ||
      specifiedAmountChange ||
      variableAmountChanged ||
      scopesChange ||
      thresholdsChanged ||
      subscribersChange
    );
  }

  function canCreate() {
    return (
      state.name.length > 0 &&
      (state.amount > 0 || (state.isVariable && state.months.length > 0)) &&
      state.scopeInputs.length > 0 &&
      validateScopeInputs(state.scopeInputs) &&
      (!state.isVariable || state.months.length > 0)
    );
  }

  function canUpdate() {
    return hasChanged() && canCreate();
  }

  const uniqueLabels = uniq(
    props.availableDimensions.map((label) => ({
      key: label,
      value: label,
    }))
  );

  const scopeInputKeys = state.scopeInputs.map((scopeInput) => scopeInput.key);

  const keySuggestions: ScopeOptions[] = uniqueLabels
    .map((label) => ({
      label: label.key,
      value: label.key,
    }))
    .filter((option) => !scopeInputKeys.includes(option.value))
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

  const groupedKeySuggestions = groupOptionsByPreferences(
    keySuggestions,
    props.dimensionPreferences,
    DataSource.BILLING,
    gatekeeper.isLabelPreferenceAdmin
  );

  const spendBasisOptions = [
    { label: copyText.budgetsFormSpendBasisActual, value: "actualSpend" },
    {
      label: copyText.budgetsFormSpendBasisProjected,
      value: "forecastedSpend",
    },
  ];

  const thresholdWarningTypeOptions = [
    {
      label: copyText.budgetsFormThresholdWarningDaily,
      value: BudgetThresholdWarningType.DAILY_SPEND,
    },
    {
      label: copyText.budgetsFormThresholdWarningMonthly,
      value: BudgetThresholdWarningType.MONTHLY_SPEND,
    },
  ];

  if (state.selectedMonth) {
    return (
      <BudgetPeriodVersionsHistory
        budget={props.budget}
        loading={props.isProcessing}
        month={state.selectedMonth}
        onClose={() => mergeState({ selectedMonth: null })}
        onUpdatePeriodVersions={props.onUpdatePeriodVersions}
      />
    );
  }

  const measureOptions = [
    {
      label: copyText.budgetsFormLabelCost,
      value: "cost",
    },
    ...(gatekeeper.canApplyCustomPricing
      ? [
          {
            label: copyText.budgetsFormLabelCustomNetCost,
            value: "customNetCost",
          },
        ]
      : []),
    {
      label: copyText.budgetsFormLabelNetCost,
      value: "netCost",
    },
  ];

  return (
    <StyledForm showMonths={state.isVariable}>
      <Box marginBottom={theme.space_sm}>
        <Text>
          {copyText.learnMoreCaption + " "}
          <a
            href={externalLinks.readmeBudgetsDocumentation}
            rel="noreferrer"
            target="_blank"
          >
            {copyText.learnMoreLink}
          </a>
        </Text>
      </Box>
      <Flex
        className={cn("top-rows")}
        alignItems="center"
        justifyContent="space-between"
      >
        <FormField
          input={TextInput}
          label={copyText.budgetsFormInputLabelName}
          value={state.name}
          onChange={(event) => handleChangeName(event.target.value)}
        />
        <Flex alignItems="center" justifyContent="flex-end">
          <Text marginRight={theme.space_sm} marginVertical={0}>
            {copyText.budgetsFormLabelMeasure}
          </Text>
          <Box width={200}>
            <Select
              options={measureOptions}
              value={measureOptions.find((option) => {
                return option.value === state.measure;
              })}
              onChange={(option) =>
                option && mergeState({ measure: option.value })
              }
            />
          </Box>
          <Tooltip
            content={copyText.budgetsFormTooltipCredits}
            icon={faInfoCircle}
            width="8rem"
          />
        </Flex>
      </Flex>
      <Flex
        className={cn("top-rows")}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex direction="column">
          <FormField label={copyText.budgetsFormInputLabelScopeKey}>
            <>
              {state.scopeInputs.map((_, i) => {
                return (
                  <Flex key={i} marginBottom="0.5rem">
                    <Select
                      defaultValue={groupedKeySuggestions}
                      isDisabled={props.isProcessing}
                      isSearchable
                      options={groupedKeySuggestions}
                      value={convertToOption(state.scopeInputs[i].key)}
                      onChange={(option) => {
                        if (option) {
                          handleChangeKey(option?.label, i);
                        }
                      }}
                    />
                  </Flex>
                );
              })}
            </>
          </FormField>
        </Flex>
        <Flex direction="column">
          <FormField label={copyText.budgetsFormInputLabelScopeValue}>
            <>
              {state.scopeInputs.map((scope, i) => {
                const multiSelectOptions = createValueSuggestions(
                  props.dimensionValues,
                  scope.key
                );
                return (
                  <Flex key={i} marginBottom="0.5rem" alignItems="center">
                    <Select
                      defaultValue={multiSelectOptions}
                      isClearable
                      isDisabled={props.isProcessing}
                      isLoading={props.isLoadingDimensionValues}
                      isMulti={true}
                      isSearchable
                      options={multiSelectOptions}
                      value={convertToOption(state.scopeInputs[i].values)}
                      onChange={(options) => {
                        handleChangeValues(
                          options.map((option) => option.value),
                          i
                        );
                      }}
                    />
                    <Button
                      iconStart={<Icon icon={faTrashAlt} />}
                      size="tiny"
                      onClick={() => handleDeleteScope(i)}
                      type="button"
                    />
                  </Flex>
                );
              })}
            </>
          </FormField>
        </Flex>
        <Tooltip
          content={copyText.budgetsFormTooltipScope}
          icon={faInfoCircle}
          width="8rem"
        />
      </Flex>
      <Flex marginBottom={theme.space_md}>
        <Button
          disabled={props.isProcessing}
          primary
          size="small"
          onClick={handleAddScope}
          type="button"
        >
          {copyText.budgetsFormButtonAddNewScope}
        </Button>
      </Flex>
      <Flex
        alignItems="center"
        className={cn("top-rows")}
        justifyContent="space-between"
      >
        <FormField label={copyText.budgetsFormInputLabelAmount}>
          {state.isVariable ? (
            <TextInput
              disabled={state.isVariable}
              value={copyText.budgetsFormVariableAmount}
            />
          ) : (
            <NumberInput
              formatter="currency-rounded"
              value={typeof state.amount === "number" ? state.amount : 0}
              onChange={(value) =>
                handleChangeNumber(value.toString(), "amount")
              }
            />
          )}
        </FormField>
        <div>
          <Flex alignItems="center" justifyContent="flex-end">
            <label className={cn("adjacent-label")}>
              {copyText.budgetsFormRecurringToggleLabel}
            </label>
            <Switch
              checked={!state.isVariable}
              onColor={theme.switch_color_on}
              onChange={() =>
                setState((st) => ({
                  ...st,
                  isVariable: !st.isVariable,
                }))
              }
            />
            <Tooltip
              content={copyText.budgetsFormRecurringSwitchTooltip}
              icon={faInfoCircle}
              width="15rem"
            />
          </Flex>
          {props.budget &&
            props.budget.amount.variableAmount &&
            !state.isVariable && (
              <div className={cn("warning-text")}>
                {copyText.budgetsFormRecurringSwitchWarning}
              </div>
            )}
        </div>
      </Flex>
      <br />
      <label>{copyText.budgetsFormSectionLabelAlerts}</label>
      {state.thresholds.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>{copyText.budgetsFormAlertsTableHeadingPercentage}</th>
              <th style={{ width: 160 }}>
                {copyText.budgetsFormAlertsTableHeadingThresholdWarningType}
              </th>
              <th style={{ width: 160 }}>
                <Flex justifyContent="center">
                  {copyText.budgetsFormAlertsTableHeadingSpendBasis}{" "}
                  <Tooltip
                    content={copyText.budgetsFormTooltipSpendBasis}
                    icon={faInfoCircle}
                    width={"8rem"}
                  />
                </Flex>
              </th>
              <th>
                <Flex justifyContent="center" alignItems="center">
                  {copyText.budgetsFormAlertsTableHeadingLatestAmount}
                  <Tooltip
                    content={copyText.budgetsFormTooltipLatestAmount}
                    icon={faInfoCircle}
                    width={"8rem"}
                  />
                </Flex>
              </th>
              <th>
                <Flex justifyContent="center" alignItems="center">
                  {copyText.budgetsFormAlertsTableHeadingHighlighted}
                  <Tooltip
                    content={copyText.budgetsFormTooltipReferenceVersion}
                    icon={faInfoCircle}
                    width={"8rem"}
                  />
                </Flex>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>{state.thresholds.map(renderThreshold, theme)}</tbody>
        </table>
      ) : (
        <p>{copyText.budgetsFormAlertsPlaceholder}</p>
      )}

      <Flex marginTop={theme.space_lg} marginBottom={theme.space_md}>
        <Button
          disabled={props.isProcessing}
          onClick={handleAddAlert}
          primary
          size="small"
          type="button"
        >
          {copyText.budgetsFormButtonAddNewAlert}
        </Button>
      </Flex>

      <br />

      {state.updatingSubscribers && state.thresholds.length > 0 ? (
        <>
          {renderInternalUserSubscriberForm()}
          {renderExternalUserSubscriberForm()}
        </>
      ) : (
        renderSubscriberList()
      )}

      <div>
        {!state.isVariable ? null : (
          <>
            <label>
              {copyText.budgetsFormSectionLabelVariableMonthAmounts}
            </label>
            {renderVariableControlsRow(state.months.length > 0)}
            {state.months.length > 0 && (
              <table className="fixed_header">
                <thead>
                  <tr>
                    <th>
                      {copyText.budgetsFormVariableMonthsTableHeaderMonth}
                    </th>
                    <th>
                      {copyText.budgetsFormVariableMonthsTableHeaderLatest}
                    </th>
                    <th>
                      {copyText.budgetsFormVariableMonthsTableHeaderVersions}
                    </th>
                    <th>
                      <Flex alignItems="center">
                        {
                          copyText.budgetsFormVariableMonthsTableHeaderHighlighted
                        }
                        <Tooltip
                          content={
                            copyText.budgetsFormVariableMonthsTableHeaderTooltipHighlighted
                          }
                          icon={faInfoCircle}
                          width={"6rem"}
                        />
                      </Flex>
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {state.months.map(renderSummary)}
                  <tr>
                    <td>
                      <Flex>
                        <Button
                          disabled={props.isProcessing}
                          primary
                          size="small"
                          onClick={appendNewMonth}
                          type="button"
                        >
                          {copyText.budgetsFormButtonAddMonth}
                        </Button>
                      </Flex>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </>
        )}
      </div>

      <Box flex="1 0 100px" />

      <Flex
        backgroundColor={theme.panel_backgroundColor}
        className={cn("footer")}
        justifyContent="flex-end"
      >
        {state.updatingSubscribers && state.thresholds.length > 0 ? (
          renderSubscriberSave()
        ) : (
          <>
            <Button
              disabled={props.isProcessing}
              onClick={handleClose}
              secondary
              type="reset"
              width={100}
            >
              {copyText.actionCancel}
            </Button>
            <Button
              disabled={props.isProcessing || !canSave() || !hasChanged()}
              primary
              width={100}
              onClick={handleSubmit}
              type="button"
            >
              {props.isProcessing ? (
                <LoadingSpinner />
              ) : props.budget ? (
                copyText.actionSave
              ) : (
                copyText.actionSubmit
              )}
            </Button>
          </>
        )}
      </Flex>

      <Box marginTop={theme.space_sm} />
    </StyledForm>
  );

  function convertToOption(option: string | string[]) {
    let convertedOption;
    if (Array.isArray(option) && option) {
      convertedOption = option
        .map((val) => ({
          label: val,
          value: val,
        }))
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
    } else if (!Array.isArray(option) && option) {
      convertedOption = { label: option, value: option };
    } else {
      return null;
    }
    return convertedOption;
  }

  function createValueSuggestions(valueData, scopeKey: string) {
    const newValueSuggestions: ScopeOptions[] = [];

    if (scopeKey === undefined || !scopeKey.length) {
      return newValueSuggestions;
    }

    if (
      scopeKey.length &&
      valueData.BILLING &&
      Object.keys(valueData.BILLING).length > 0
    ) {
      const billingValues = valueData.BILLING;

      for (const label in billingValues) {
        if (label === scopeKey) {
          billingValues[label].forEach((val: string) => {
            newValueSuggestions.push({ label: val, value: val });
          });
        }
      }

      return newValueSuggestions.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    }
  }

  function renderThreshold(threshold: BudgetThreshold, index: number) {
    const limitVersionsDefined = Array.isArray(threshold.limitVersions);
    const selectedSpendBasis = spendBasisOptions.find(
      (sbo) => sbo.value === threshold.spendBasis
    );

    const selectedThresholdWarningType = thresholdWarningTypeOptions.find(
      (thresholdType) => thresholdType.value === threshold.warningType
    );

    return (
      <tr
        key={index} // effectively turns off resorting
      >
        <td>
          <Box display="inline-block" width={60}>
            <NumberInput
              size="small"
              value={threshold.percent}
              formatter="percent-divided"
              onChange={(value) =>
                handleUpdateThreshold(value, "percent", index)
              }
            />
          </Box>
        </td>
        <td>
          <Box display="inline-block" width={160} paddingRight={theme.space_xs}>
            <Select
              compact
              options={thresholdWarningTypeOptions}
              value={selectedThresholdWarningType}
              onChange={(option) =>
                option &&
                handleUpdateThreshold(option.value, "warningType", index)
              }
            />
          </Box>
        </td>
        <td>
          {selectedThresholdWarningType?.value !==
          BudgetThresholdWarningType.DAILY_SPEND ? (
            <Select
              compact
              options={spendBasisOptions}
              value={selectedSpendBasis}
              onChange={(option) =>
                option &&
                handleUpdateThreshold(option.value, "spendBasis", index)
              }
            />
          ) : (
            <Text>{copyText.budgetsFormSpendBasisofMonthlyBudget}</Text>
          )}
        </td>
        {/* TODO: only show the next two columns if variable - when making a budget specified, we turn all thresholds to latest */}
        <td>
          {state.isVariable ? (
            <Switch
              height={20}
              width={40}
              onColor={theme.switch_color_on}
              checked={
                limitVersionsDefined &&
                threshold.limitVersions.includes("LATEST")
              }
              onChange={() => handleToggleThresholdVersions("LATEST", index)}
            />
          ) : (
            "-"
          )}
        </td>
        <td>
          {state.isVariable ? (
            <Switch
              height={20}
              width={40}
              onColor={theme.switch_color_on}
              checked={
                limitVersionsDefined &&
                threshold.limitVersions.includes("HIGHLIGHTED")
              }
              onChange={() =>
                handleToggleThresholdVersions("HIGHLIGHTED", index)
              }
            />
          ) : (
            "-"
          )}
        </td>
        <td>
          <FontAwesomeIcon
            icon={faTrash}
            style={{ cursor: "pointer" }}
            onClick={() => handleRemoveThreshold(index)}
          />
        </td>
      </tr>
    );
  }

  function renderSummary(summary: MonthSummary, index: number) {
    let date = new Date(summary.month);
    date = add(date, { days: 1 }); // to avoid edge of the month / utc issues

    const now = new DateHelper();
    const isCurrentMonth =
      date.getMonth() === now.date.getMonth() &&
      date.getFullYear() === now.date.getFullYear();

    const isPastMonth = isBefore(date, now.date) && !isCurrentMonth;

    const rowClassName = isCurrentMonth
      ? cn("current-month")
      : isPastMonth
        ? cn("past-month")
        : "";

    const isFinalRow = index === state.months.length - 1;

    return (
      <tr className={rowClassName} key={summary.month}>
        {/* TODO: cleanup this mess */}
        {/* add 1 to month number because .getMonth is zero-indexed */}
        <td>
          {getMonthNameForMonthNumber(date.getMonth() + 1) +
            " " +
            date.getFullYear()}
        </td>
        <td>
          <Box display="inline-block" width={100}>
            <NumberInput
              formatter="currency-rounded"
              size="small"
              value={summary.latest ?? 0}
              onChange={(value) => handleUpdateVersionLatest(value, index)}
            />
          </Box>
        </td>
        <td>{summary.versions}</td>
        <td>
          {summary.hasHighlighted ? (
            <FontAwesomeIcon icon={faCheck} color={theme.primary_color_text} />
          ) : (
            <FontAwesomeIcon
              icon={faTimes}
              color={theme.text_color_secondary}
            />
          )}
        </td>
        {!isCreating && (
          <td className={cn("clickable")}>
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => mergeState({ selectedMonth: summary.month })}
            />
          </td>
        )}
        {!isCreating &&
        props.budget &&
        state.months.length >
          getSortedMonthsFromPeriodVersions(props.budget, true).length &&
        isFinalRow ? (
          <td className={cn("clickable")}>
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() =>
                setState((st) => {
                  return { ...st, months: st.months.slice(0, -1) };
                })
              }
            />
          </td>
        ) : (
          <td />
        )}
      </tr>
    );
  }

  // TODO: so much to dedupe
  function renderVariableControlsRow(hasBeenCompleted: boolean) {
    if (!isCreating && props.budget && props.budget.amount.variableAmount)
      return (
        <div className={cn("starter-kit")}>
          <Flex alignItems="center">
            <label style={{ margin: "0 0.5rem 0 0" }}>
              {copyText.budgetsFormVariableMonthsHighlightedToggle}
            </label>
            <Switch
              height={20}
              width={40}
              checked={state.setAsHighlighted}
              onChange={() =>
                setState((st) => {
                  const newValue = !st.setAsHighlighted;
                  let originalHasHighlightStatuses: {
                    [monthString: string]: boolean;
                  } = {};

                  if (newValue === false && props.budget) {
                    originalHasHighlightStatuses = getHasHighlightKeyedByMonth(
                      props.budget
                    );
                  }

                  return {
                    ...st,
                    setAsHighlighted: newValue,
                    months: st.months.map((m) => ({
                      ...m,
                      hasHighlighted:
                        newValue || originalHasHighlightStatuses[m.month],
                    })),
                  };
                })
              }
            />
            <Tooltip
              content={
                copyText.budgetsFormVariableMonthsHighlightedToggleTooltip
              }
            />
          </Flex>
        </div>
      );

    if (hasBeenCompleted) {
      return (
        <div className={cn("starter-kit")}>
          <Flex justifyContent="space-between">
            <Flex alignItems="center">
              <label style={{ margin: "0 0.5rem 0 0" }}>
                {
                  copyText.budgetsFormVariableMonthsHighlightedLabelHighlightToggle
                }
              </label>
              <Switch
                height={20}
                width={40}
                checked={state.setAsHighlighted}
                onChange={() =>
                  setState((st) => ({
                    ...st,
                    setAsHighlighted: !st.setAsHighlighted,
                    months: st.months.map((m) => ({
                      ...m,
                      hasHighlighted: !st.setAsHighlighted,
                    })),
                  }))
                }
              />
            </Flex>
            <Button
              disabled={props.isProcessing}
              primary
              size="small"
              onClick={handleResetMonths}
              type="button"
            >
              {copyText.budgetsFormVariableMonthsButtonResetMonths}
            </Button>
          </Flex>
        </div>
      );
    }

    return (
      <div className={cn("starter-kit")}>
        {
          <>
            <Text appearance="h3">{copyText.budgetsFormStarterTitle}</Text>
            <p>{copyText.budgetsFormStarterInstructions}</p>
          </>
        }
        <Flex justifyContent="space-between">
          <FormField label={copyText.budgetsFormLabelStartingMonth}>
            <Select
              compact
              placeholder={copyText.unitMonth}
              value={state.variableStartMonth}
              options={getMonthOptions()}
              onChange={(opt) => opt && mergeState({ variableStartMonth: opt })}
            />
          </FormField>
          <FormField label={copyText.budgetsFormLabelStartingYear}>
            <Select
              compact
              placeholder={copyText.unitYear}
              options={getYearOptions()}
              value={state.variableStartYear}
              onChange={(opt) => opt && mergeState({ variableStartYear: opt })}
            />
          </FormField>
          <FormField label={copyText.budgetsFormLabelMonthCount}>
            <NumberInput
              size="small"
              value={state.variableMonthCount}
              onChange={(value) => mergeState({ variableMonthCount: value })}
            />
          </FormField>
          <FormField label={copyText.budgetsFormLabelAutofillAmount}>
            <NumberInput
              formatter="currency-rounded"
              size="small"
              value={state.variableStartingAmount}
              onChange={(value) =>
                mergeState({
                  variableStartingAmount: value,
                })
              }
            />
          </FormField>
          <div className={cn("switch")}>
            <Tooltip
              content={copyText.budgetsReferenceVersionTooltip}
              width={"8rem"}
            >
              <label>{copyText.budgetsFormLabelAutofillHighlighted}</label>
            </Tooltip>
            <Switch
              checked={state.setAsHighlighted}
              onChange={() =>
                setState((st) => ({
                  ...st,
                  setAsHighlighted: !st.setAsHighlighted,
                }))
              }
            />
          </div>
        </Flex>
        <br />
        <Flex>
          <Button
            disabled={props.isProcessing}
            primary
            size="small"
            type="button"
            onClick={generateMonths}
          >
            {copyText.budgetsFormButtonGenerate}
          </Button>
        </Flex>
      </div>
    );
  }

  function renderSubscriberList() {
    if (state.thresholds.length === 0) {
      return (
        <FormField label={copyText.budgetsFormSubscribers}>
          <p>{copyText.budgetsFormAlertsPlaceholder}</p>
        </FormField>
      );
    }

    const usersKeyedByID = keyBy(props.users, "id");

    const externalEmails = state.selectedSubscribers.externalEmails;
    const internalIds = state.selectedSubscribers.userIDs;

    const isShowingNonAccountEmails = externalEmails.length > 0;

    return (
      <FormField label={copyText.budgetsFormSubscribers}>
        <Box marginLeft={isShowingNonAccountEmails ? theme.space_md : 0}>
          <Flex justifyContent="space-between">
            <Box minWidth={0} flex="0 0 240px">
              {isShowingNonAccountEmails && (
                <FormField
                  label={copyText.budgetsFormSubscribersListAccount}
                  marginBottom={theme.space_xxs}
                />
              )}

              <Box marginBottom={theme.space_xs} marginLeft={theme.space_md}>
                {/* internal Subscribers */}
                {state.selectedSubscribers.includeAllUsers ? (
                  <Text>{copyText.budgetsFormSubscribersAll}</Text>
                ) : internalIds.length === 0 ? (
                  <Text>{copyText.budgetsFormSubscribersNone}</Text>
                ) : (
                  internalIds.map((id) => (
                    <Flex key={id} justifyContent="space-between">
                      <Box flex="1 0 0" width={0}>
                        <Text truncate>
                          {usersKeyedByID[id]?.email ?? "---"}
                        </Text>
                      </Box>

                      <Box>
                        <Button
                          onClick={() => handleRemoveInternalSubscriber(id)}
                          size="tiny"
                          type="button"
                        >
                          <Icon icon={faXmark} />
                        </Button>
                      </Box>
                    </Flex>
                  ))
                )}
              </Box>

              {/* external Subscribers */}
              {isShowingNonAccountEmails && (
                <>
                  <FormField
                    label={copyText.budgetsFormSubscribersListNonAccount}
                    marginBottom={theme.space_xxs}
                  />
                  {externalEmails.map((email) => (
                    <Flex key={email} justifyContent="space-between">
                      <Box flex="1 0 0" marginLeft={theme.space_md} width={0}>
                        <Text truncate>{email}</Text>
                      </Box>

                      <Box>
                        <Button
                          key={email}
                          onClick={() => handleRemoveExternalSubscriber(email)}
                          size="tiny"
                          type="button"
                        >
                          <Icon icon={faXmark} />
                        </Button>
                      </Box>
                    </Flex>
                  ))}
                </>
              )}
            </Box>
          </Flex>
          <Box marginTop={theme.space_md}>
            <Button
              disabled={props.isProcessing}
              primary
              size="small"
              type="button"
              onClick={() => handleChangeIsUpdatingSubscribers(true)}
            >
              {copyText.budgetsFormSubscribersEdit}
            </Button>
          </Box>
        </Box>
      </FormField>
    );
  }

  function renderInternalUserSubscriberForm() {
    if (!state.updatingSubscribers) return null;

    return (
      <FormField label={copyText.budgetsFormSubscribeUsers}>
        <Box marginLeft={theme.space_md} height={300}>
          <UserDualListbox
            selectedIDs={
              state.updatingSubscribers.includeAllUsers
                ? props.users.map(({ id }) => id)
                : state.updatingSubscribers.userIDs
            }
            users={props.users}
            onChange={handleInternalSubscribedUsersChange}
          />
        </Box>
      </FormField>
    );
  }

  function renderExternalUserSubscriberForm() {
    if (!state.updatingSubscribers) return null;
    const inputIsInternalUser = !!props.users.find(({ email }) =>
      isSameIgnoreCase(email, state.externalSubscriberEmailInput)
    );

    return (
      <FormField label={copyText.budgetsFormSubscribersAddNonAccount}>
        <Flex marginLeft={theme.space_md}>
          <Box width={200} flex="0 0 auto">
            <TextInput
              placeholder={copyText.budgetsFormSubscribersEmailPlaceholder}
              inputRef={externalEmailInputRef}
              onChange={(e) =>
                mergeState({ externalSubscriberEmailInput: e.target.value })
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleAddExternalSubscriberEmail();
                }
              }}
              size="small"
              value={state.externalSubscriberEmailInput}
              variant={inputIsInternalUser ? "danger" : undefined}
            />
            {inputIsInternalUser && (
              <Box
                color={theme.feedback_negative}
                fontSize={theme.fontSize_small}
                fontWeight={theme.fontWeight_bold}
                marginLeft={theme.space_xxs}
                marginTop={theme.space_xxs}
              >
                {copyText.budgetsFormSubscribersIsInternalWarning}
              </Box>
            )}
          </Box>
          <Box flex="1 0 0">
            <Flex direction="column" alignItems="flex-end">
              <Tooltip
                content={copyText.budgetsFormTooltipExternalUserSubscription}
              >
                <Button
                  disabled={
                    inputIsInternalUser ||
                    !isEmail(state.externalSubscriberEmailInput)
                  }
                  primary
                  size="small"
                  type="button"
                  onClick={() => {
                    externalEmailInputRef.current?.focus();
                    handleAddExternalSubscriberEmail();
                  }}
                >
                  {copyText.budgetsFormSubscribersAddNonAccount}
                </Button>
              </Tooltip>
              <Box marginTop={theme.space_xxs} width={200}>
                {state.updatingSubscribers.externalEmails.map((email) => (
                  <Flex
                    key={email}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box flex="1 0 0" width={0}>
                      <Text truncate>{email}</Text>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => handleRemoveExternalSubscriber(email)}
                        size="tiny"
                        type="button"
                      >
                        <Icon icon={faXmark} />
                      </Button>
                    </Box>
                  </Flex>
                ))}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </FormField>
    );
  }

  function renderSubscriberSave() {
    const canUpdate = !isEqual(
      state.selectedSubscribers,
      state.updatingSubscribers
    );

    return (
      <>
        <Button
          secondary
          type="reset"
          width={100}
          onClick={() => handleChangeIsUpdatingSubscribers(false)}
        >
          {copyText.actionCancel}
        </Button>
        <Button
          disabled={!canUpdate}
          type="button"
          onClick={() => {
            handleUpdateSelectedSubscribers();
            handleChangeIsUpdatingSubscribers(false);
          }}
          primary
        >
          {copyText.budgetsFormSubscribersUpdate}
        </Button>
      </>
    );
  }
}

function isSameIgnoreCase(a: string, b: string) {
  return a.trim().toLowerCase() === b.trim().toLowerCase();
}

function validateScopeInputs(scopeInputs: BudgetScope[]) {
  let isValid = true;
  scopeInputs.map((input) => {
    if (input.key.length === 0 || input.values.length === 0) {
      isValid = false;
    }
  });
  return isValid;
}
