import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import {
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import useBuildDataQuery from "../../../api/analytics/utils/useDataQuery";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

export type Params = {
  dateRange: Date[];
  costAlertID?: string;
};

export type PredictionBoundData = {
  lowerBound: number;
  upperBound: number;
  timestamp: string;
};

export default function useGetBoundsData(
  params: Params,
  options?: UseQueryOptions<PredictionBoundData[], UError>
): UseQueryResult<PredictionBoundData[], UError> {
  const client = useAnalyticsApiClient();

  const filters: QueryFilter[] = params.costAlertID
    ? [
        {
          name: "alertEventID",
          operator: Operator.EQUALS,
          values: [params.costAlertID],
        },
      ]
    : [];

  const dateRange =
    params.dateRange.length === 2 ? params.dateRange : [new Date(), new Date()];

  const [tenantID, query] = useBuildDataQuery({
    preAggFilters: filters,
    dataSource: DataSource.PREDICTION_BOUNDS,
    dateRange,
    measures: ["lowerBound", "upperBound"],
    granularity: TimeGranularity.DAY,
    ignoreGlobalFilters: true,
  });

  let enabled = options?.enabled ?? true;
  enabled = enabled && Boolean(params.costAlertID);

  return useQuery({
    queryKey: ["predictionBoundData", params],
    queryFn: async () => {
      const result = await client.loadData<PredictionBoundData>(
        tenantID,
        query
      );

      return result.response;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    enabled,
    ...options,
  });
}
