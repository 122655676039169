import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatCurrency } from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AWSComputeInstanceGroup,
  AWSComputeInstanceGroupFilters,
} from "../types";
import { createSortingUtils } from "@/utils/sort";

type TableData = {
  diskReadBytes: number;
  diskWriteBytes: number;
  family: string;
  familyType: string;
  instanceCount: number;
  instanceUnits: number;
  lineItemUsageAccountId: string;
  networkInBytes: number;
  networkOutBytes: number;
  operatingSystem: string;
  productMemoryBytes: number;
  productVCPU: number;
  region: string;
  totalCost: number;
};

type Props = {
  instanceGroups: AWSComputeInstanceGroup[];
  isLoadingInstanceGroups: boolean;
  onInteraction: (
    interaction: AWSComputeInstanceGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const { numberSort, stringSort } = createSortingUtils<TableData>({
  validKeys: [
    "diskReadBytes",
    "diskWriteBytes",
    "family",
    "familyType",
    "instanceCount",
    "lineItemUsageAccountId",
    "networkInBytes",
    "networkOutBytes",
    "operatingSystem",
    "productMemoryBytes",
    "productVCPU",
    "region",
    "totalCost",
  ],
});

function AWSComputeInstanceGroupTable(props: Props) {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const tableData = useMemo(
    (): TableData[] =>
      props.instanceGroups.map((instanceGroup) => ({
        diskReadBytes: instanceGroup.diskReadBytes,
        diskWriteBytes: instanceGroup.diskWriteBytes,
        family: instanceGroup.family,
        familyType: instanceGroup.familyType,
        instanceCount: instanceGroup.instanceIDCount,
        instanceUnits: instanceGroup.instanceUnits,
        lineItemUsageAccountId: instanceGroup.lineItemUsageAccountId,
        networkInBytes: instanceGroup.networkInBytes,
        networkOutBytes: instanceGroup.networkOutBytes,
        operatingSystem: instanceGroup.operatingSystem,
        productMemoryBytes: instanceGroup.productMemoryBytes,
        productVCPU: instanceGroup.productVCPU,
        region: instanceGroup.region,
        totalCost: instanceGroup.totalCost,
      })),
    [props.instanceGroups]
  );

  const handleFilterClick = (
    filterKey: keyof AWSComputeInstanceGroupFilters,
    filterValue: string
  ) =>
    props.onInteraction({
      type: AWSComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
      filterKey,
      filterValue,
    });

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "viewInstances",
        cell: ({ row }) => (
          <Tooltip content="View Instances">
            <LinkWithSearchParams
              searchParams={{
                selected_group: getSelectedGroupJSON(row.original),
              }}
            >
              <Button
                iconStart={<Icon icon={faTableList} />}
                primary
                size="tiny"
              />
            </LinkWithSearchParams>
          </Tooltip>
        ),
        enableSorting: false,
        size: 50,
      }),
      columnHelper.accessor("family", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() => handleFilterClick("family", getValue())}
          >
            {getValue() || copyText.awsComputeTableNull}
          </Text>
        ),
        header: copyText.awsComputeTableInstanceGroupHeader_family,
        meta: { align: "left" },
        size: 60,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("region", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() => handleFilterClick("region", getValue())}
          >
            {getValue() || copyText.awsComputeTableNull}
          </Text>
        ),
        header: copyText.awsComputeTableInstanceGroupHeader_region,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("lineItemUsageAccountId", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              handleFilterClick("lineItemUsageAccountId", getValue())
            }
          >
            {getValue() || copyText.awsComputeTableNull}
          </Text>
        ),
        header: () => (
          <Tooltip
            content={
              <Box width={200}>
                <Text color={theme.text_color_inverse}>
                  {copyText.awsIAMListPermissionTooltipMessage}
                </Text>
              </Box>
            }
          >
            {copyText.awsComputeTableInstanceGroupHeader_lineItemUsageAccountId}
          </Tooltip>
        ),
        meta: { align: "left" },
        size: 160,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("operatingSystem", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() => handleFilterClick("operatingSystem", getValue())}
          >
            {getValue() || copyText.awsComputeTableNull}
          </Text>
        ),
        header: copyText.awsComputeTableInstanceGroupHeader_operatingSystem,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("familyType", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() => handleFilterClick("familyType", getValue())}
          >
            {getValue() || copyText.awsComputeTableNull}
          </Text>
        ),
        header: copyText.awsComputeTableInstanceGroupHeader_familyType,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("instanceCount", {
        cell: ({ getValue }) => getValue(),
        header: copyText.awsComputeTableInstanceGroupHeader_instanceCount,
        meta: { align: "right" },
        size: 140,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("instanceUnits", {
        cell: ({ getValue }) => (
          <>{getValue() || copyText.awsComputeTableNull}</>
        ),
        header: copyText.awsComputeTableInstanceGroupHeader_instanceUnits,
        meta: { align: "right" },
        size: 125,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsComputeTableInstanceGroupHeader_totalCost,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("productVCPU", {
        cell: ({ getValue }) => getValue(),
        header: copyText.awsComputeTableInstanceGroupHeader_productVCPU,
        meta: { align: "right" },
        size: 145,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("diskReadBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsComputeTableInstanceGroupHeader_diskReadBytes,
        meta: { align: "right" },
        size: 110,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("diskWriteBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsComputeTableInstanceGroupHeader_diskWriteBytes,
        meta: { align: "right" },
        size: 110,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("productMemoryBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsComputeTableInstanceGroupHeader_productMemoryBytes,
        meta: { align: "right" },
        size: 140,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("networkInBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsComputeTableInstanceGroupHeader_networkInBytes,
        meta: { align: "right" },
        size: 110,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("networkOutBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.awsComputeTableInstanceGroupHeader_networkOutBytes,
        meta: { align: "right" },
        size: 120,
        sortingFn: numberSort,
      }),
    ],
    [props.instanceGroups]
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      initialState={{ sorting: [sortRule] }}
      isLoading={props.isLoadingInstanceGroups}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

function getSelectedGroupJSON(datum: TableData) {
  try {
    return JSON.stringify({
      family: datum.family,
      familyType: datum.familyType,
      lineItemUsageAccountId: datum.lineItemUsageAccountId,
      operatingSystem: datum.operatingSystem,
      region: datum.region,
    });
  } catch {
    return "";
  }
}

AWSComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED =
  `AWSComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AWSComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof AWSComputeInstanceGroupFilters;
  filterValue: AWSComputeInstanceGroupFilters[keyof AWSComputeInstanceGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSComputeInstanceGroupTable {
  export type Interaction = InteractionFilterClicked;
}

export default AWSComputeInstanceGroupTable;
