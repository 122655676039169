import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatNumber } from "@ternary/web-ui-lib/utils/formatNumber";
import React, { useMemo } from "react";
import copyText from "../../copyText";

type Tags = {
  [key: string]: string;
};

type Warehouse = {
  id: number;
  cloudServicesCredits: number;
  computeCredits: number;
  name: string;
  queryCount: number;
  tags: Tags[];
  totalCreditsUsed: number;
  totalRunTime: number;
};

type TableData = {
  accountName: string;
  cloudServicesCreditsUsed: number;
  computeCreditsUsed: number;
  queryElapsedTime: number;
  totalCreditsUsed: number;
  warehouse: Warehouse;
  warehouseID: number;
  warehouseName: string;
  warehouseSize: string;
  warehouseType: string;
};

interface Props {
  isLoading: boolean;
  resources: TableData[];
  selectedWarehouseID?: string;
  onInteraction: (
    interaction: SnowflakeWarehouseResourceTable.Interaction
  ) => void;
}

export function SnowflakeWarehouseResourceTable(props: Props): JSX.Element {
  const columnHelper = createColumnHelper<TableData>();

  function selectSubTable(value: Warehouse): JSX.Element {
    return (
      <Flex alignItems="center">
        <Tooltip content={"View Warehouse"}>
          <Button
            onClick={() =>
              props.onInteraction({
                type: SnowflakeWarehouseResourceTable.INTERACTION_SELECT_WAREHOUSE_CLICKED,
                selectedWarehouse: value,
              })
            }
            iconStart={<Icon icon={faTableList} />}
            primary
            size="tiny"
          />
        </Tooltip>
      </Flex>
    );
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor("warehouse", {
        cell: ({ getValue }) => selectSubTable(getValue()),
        size: 25,
      }),
      columnHelper.accessor("accountName", {
        header: copyText.snowflakeResourceTableHeader_accountName,
        size: 70,
      }),
      columnHelper.accessor("warehouseName", {
        header: copyText.snowflakeResourceTableHeader_warehouseName,
        size: 80,
      }),
      columnHelper.accessor("warehouseSize", {
        header: copyText.snowflakeResourceTableHeader_warehouseSize,
        size: 60,
      }),
      columnHelper.accessor("warehouseType", {
        cell: ({ getValue }) => <>{getValue().toLowerCase()}</>,
        header: copyText.snowflakeResourceTableHeader_warehouseType,
        meta: { align: "left" },
        size: 70,
      }),
      columnHelper.accessor("queryElapsedTime", {
        cell: ({ getValue }) => <>{millisecondsToHMS(getValue())}</>,
        header: copyText.snowflakeResourceTableHeader_elapsedTime,
        meta: { align: "right" },
        size: 70,
      }),
      columnHelper.accessor("computeCreditsUsed", {
        cell: ({ getValue }) => <>{formatNumber(getValue())}</>,
        header: copyText.snowflakeResourceTableHeader_computeCredits,
        meta: { align: "right" },
        size: 70,
      }),
      columnHelper.accessor("cloudServicesCreditsUsed", {
        cell: ({ getValue }) => <>{formatNumber(getValue())}</>,
        header: copyText.snowflakeResourceTableHeader_cloudServices,
        meta: { align: "right" },
        size: 70,
      }),
      columnHelper.accessor("totalCreditsUsed", {
        cell: ({ getValue }) => <>{formatNumber(getValue())}</>,
        header: copyText.snowflakeResourceTableHeader_totalCredits,
        meta: { align: "right" },
        size: 70,
      }),
    ],
    [props.resources]
  );

  const data: TableData[] = useMemo(() => {
    const tableData = props.resources.map((item) => {
      return {
        accountName: item.accountName,
        cloudServicesCreditsUsed: item.cloudServicesCreditsUsed,
        computeCreditsUsed: item.computeCreditsUsed,
        totalCreditsUsed: item.totalCreditsUsed,
        warehouse: item.warehouse,
        warehouseID: item.warehouseID,
        warehouseName: item.warehouseName,
        warehouseSize: item.warehouseSize,
        warehouseType: item.warehouseType,
        queryElapsedTime: item.queryElapsedTime,
      };
    });
    return tableData;
  }, [props.resources]);

  return (
    <Table
      columns={columns}
      data={data}
      isLoading={props.isLoading}
      selectedRowID={props.selectedWarehouseID}
      showPagination
      sortable
    />
  );
}

export function millisecondsToHMS(ms: number) {
  let seconds = Math.floor(ms / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;
  hours += days * 25;

  if (!hours) {
    return `${minutes}m : ${seconds}s`;
  }

  return `${hours}h : ${minutes}m : ${seconds}s`;
}

SnowflakeWarehouseResourceTable.INTERACTION_FILTERS_CHANGED =
  `SnowflakeWarehouseResourceTable.INTERACTION_FILTERS_CHANGES` as const;

SnowflakeWarehouseResourceTable.INTERACTION_SELECT_WAREHOUSE_CLICKED =
  `SnowflakeWarehouseResourceTable.INTERACTION_SELECT_WAREHOUSE_CLICKED` as const;

interface InteractionFiltersChanged {
  type: typeof SnowflakeWarehouseResourceTable.INTERACTION_FILTERS_CHANGED;
  filter: {
    type: string;
    value: string | null;
  };
}

interface InteractionSelectBucketsClicked {
  type: typeof SnowflakeWarehouseResourceTable.INTERACTION_SELECT_WAREHOUSE_CLICKED;
  selectedWarehouse: Warehouse;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SnowflakeWarehouseResourceTable {
  export type Interaction =
    | InteractionFiltersChanged
    | InteractionSelectBucketsClicked;
}

export default SnowflakeWarehouseResourceTable;
