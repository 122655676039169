import { LinkWithSearchParams } from "@/lib/react-router";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import copyText from "../../copyText";
import { CloudSQLResource, CloudSQLResourceType } from "../types";

type TableData = {
  classification: string;
  cpuAndRamCost: number | null;
  databaseType: string;
  hasNoUsageData: boolean;
  projectID: string;
  region: string;
  totalCost: number | null;
  totalReserved: number | null;
  totalUsage: number | null;
  utilizationPercent: string;
};

interface Props {
  isLoading: boolean;
  resources: CloudSQLResource[];
  resourceType: CloudSQLResourceType;
  selectedProjectID?: string;
  selectedResourceID?: string;
}

export default function CloudSQLResourceTable(props: Props): JSX.Element {
  const columnHelper = createColumnHelper<TableData>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "showGraph",
        cell: ({ row }) => (
          <Tooltip
            content={
              row.original.hasNoUsageData
                ? copyText.cloudSQLResourceTableTooltipLabelViewNoInstances
                : copyText.cloudSQLResourceTableTooltipLabelViewInstances
            }
          >
            <LinkWithSearchParams
              disabled={row.original.hasNoUsageData}
              searchParams={{
                database_type: row.original.databaseType,
                project_id: row.original.projectID,
                region: row.original.region,
              }}
            >
              <Button
                disabled={row.original.hasNoUsageData}
                iconStart={<Icon icon={faChartLine} />}
                primary
                size="tiny"
              />
            </LinkWithSearchParams>
          </Tooltip>
        ),
        enableSorting: false,
        size: 50,
      }),
      columnHelper.accessor("classification", {
        header: "Classification",
        size: 220,
      }),
      columnHelper.accessor("projectID", {
        header: "Project ID",
        size: 220,
      }),
      columnHelper.accessor("cpuAndRamCost", {
        cell: ({ getValue }) => <>{formatCost(getValue())}</>,
        header: "CPU&RAM Cost",
        size: 140,
      }),
      columnHelper.accessor("utilizationPercent", {
        header: `${props.resourceType} Utilization`,
        size: 160,
      }),
      columnHelper.accessor("totalReserved", {
        cell: ({ getValue }) => (
          <>{formatByteUsage(props.resourceType, getValue())}</>
        ),
        header:
          props.resourceType === CloudSQLResourceType.CPU
            ? copyText.cloudSQLResourceTableHeaderTotalProvisionedCores
            : copyText.cloudSQLResourceTableHeaderTotalProvisionedBytes,
        size: 135,
      }),
      columnHelper.accessor("totalUsage", {
        cell: ({ getValue }) => (
          <>{formatByteUsage(props.resourceType, getValue())}</>
        ),
        header:
          props.resourceType === CloudSQLResourceType.CPU
            ? copyText.cloudSQLResourceTableHeaderTotalCores
            : copyText.cloudSQLResourceTableHeaderTotalBytes,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCost(getValue())}</>,
        header: "Total Cost",
        size: 140,
      }),
    ],
    [props.resourceType]
  );

  const data = useMemo(
    () => getTableDataByType(props.resources, props.resourceType),
    [props.resources, props.resourceType]
  );

  const columnVisibility =
    props.resourceType === CloudSQLResourceType.NETWORK
      ? {
          utilizationPercent: false,
          totalReserved: false,
        }
      : undefined;

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{
        columnVisibility,
        sorting: [{ id: "totalCost", desc: true }],
      }}
      isLoading={props.isLoading}
      selectedRowID={props.selectedResourceID}
      showPagination
      sortable
    />
  );
}

function getTableDataByType(
  resources: CloudSQLResource[],
  usageType: CloudSQLResourceType
): TableData[] {
  return resources.map((node): TableData => {
    const hasNoUsageData = getHasNoUsageData(node);

    const data: TableData = {
      classification: getClassificationString(node, hasNoUsageData),
      cpuAndRamCost: node.cpuAndRamCost,
      databaseType: node.databaseType ?? "",
      hasNoUsageData,
      projectID: node.projectId ?? "",
      region: node.region ?? "",
      totalCost: node.totalCost,
      totalReserved: null,
      totalUsage: null,
      utilizationPercent: "",
    };

    switch (usageType) {
      case CloudSQLResourceType.MEMORY:
        return {
          ...data,
          totalReserved: node.latestProvisionedRam,
          totalUsage: node.avgRamUsedBytes,
          utilizationPercent: safePercent(
            node.totalRamUsedBytes,
            node.totalRamReservedBytes
          ),
        };
      case CloudSQLResourceType.CPU:
        return {
          ...data,
          totalReserved: node.latestProvisionedCpu,
          totalUsage: node.avgCpuUsedCoreHours,
          utilizationPercent: safePercent(
            node.totalCpuUsedCoreHours,
            node.totalCpuReservedCores
          ),
        };
      case CloudSQLResourceType.DISK:
        return {
          ...data,
          totalReserved: node.latestProvisionedDisk,
          totalUsage: node.avgDiskUsedBytes,
          utilizationPercent: safePercent(
            node.totalDiskUsedBytes,
            node.totalDiskSizeBytes
          ),
        };
      case CloudSQLResourceType.NETWORK:
        return {
          ...data,
          totalUsage: node.avgNetworkSentBytes,
          utilizationPercent: copyText.cloudSQLResourceTableLabelNotAvailable,
        };
    }
  });
}

const formatCost = (cost: number | null) => {
  if (cost === null) return copyText.cloudSQLResourceTableLabelNotAvailable;
  return formatCurrency({ number: cost });
};

const formatByteUsage = (
  usageType: CloudSQLResourceType,
  bytes: number | null
) => {
  if (bytes === null) return copyText.cloudSQLResourceTableLabelNotAvailable;
  return usageType !== CloudSQLResourceType.CPU
    ? prettyBytes(bytes)
    : formatNumber(bytes, 2);
};

function safePercent(used?: number | null, total?: number | null): string {
  if (used === null && total === null) {
    return copyText.cloudSQLResourceTableLabelNotAvailable;
  }
  return typeof used === "number" && typeof total === "number" && total > 0
    ? `${((used / total) * 100).toFixed(2)}%`
    : "0";
}

function getHasNoUsageData(node: CloudSQLResource) {
  return (
    node.maxCpuReservedCores === null &&
    node.maxCpuAverageUsedCores === null &&
    node.ramReservedBytes === null &&
    node.maxRamAverageUsedBytes === null &&
    node.maxDiskSizeBytes === null &&
    node.maxDiskUsedBytes === null
  );
}

function getClassificationString(
  node: CloudSQLResource,
  hasNoUsageData: boolean
) {
  const { databaseType, region, classification } = node;
  const isNull = (s: string | null) => s === null || s === "null";

  if (!hasNoUsageData) {
    return `[ ${databaseType} / ${region} ]`;
  }

  if (!isNull(databaseType) && !isNull(region)) {
    return `[ ${databaseType} / ${region} / ${classification} ]`;
  }

  if (isNull(databaseType)) {
    return `[ ${classification} / ${region} ]`;
  }

  if (isNull(region)) {
    return `[ ${databaseType} / ${classification} ]`;
  }

  return `[ ${classification} ]`;
}
