import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { FunctionComponent, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import getCn from "../utils/getCn";

const ANIMATION_TIME_MS = 300;
const COMPONENT_NAME = "SideDrawer";
export const DEFAULT_WIDTH = "40rem";

const cn = getCn(COMPONENT_NAME);

interface StyledDivProps {
  isOpenDrawer: boolean;
  isVisibileMask: boolean;
  width: string;
  padding: string | number;
}

// This outer div is the mask that covers the whole screen
const StyledDiv = styled.div<StyledDivProps>`
  z-index: 1000000; // NOTE: to get above ZenDesk Help Button
  position: fixed;
  right: 0;
  top: 0;
  width: ${(props) => (props.isVisibileMask ? "100vw" : 0)};
  height: ${(props) => (props.isVisibileMask ? "100vh" : 0)};
  background-color: ${(props) =>
    props.isOpenDrawer ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0)"};
  transition: background-color ${ANIMATION_TIME_MS}ms;

  .${cn("drawer")} {
    position: fixed;
    right: ${(props) => (props.isOpenDrawer ? 0 : "-" + props.width)};
    top: 0;
    width: ${(props) => props.width};
    height: 100%;
    background-color: ${(props) => props.theme.side_drawer_background_color};
    transition:
      width ${ANIMATION_TIME_MS}ms,
      right ${ANIMATION_TIME_MS}ms;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
  }

  .${cn("header")} {
    display: flex;
    /*  */
    padding: ${(props) => `${props.theme.space_md} ${props.theme.space_lg}`};
    gap: var(--spacing-md, 8px);
    align-self: stretch;
    background-color: ${(props) => props.theme.side_drawer_background_color};
    color: ${(props) => props.theme.text_color};
    max-width: 100%;

    .${cn("closer")} {
      background: none;
      border: none;
      padding: 0;
      svg {
        color: ${(props) => props.theme.text_color_secondary};
      }
    }
  }

  .${cn("content")} {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-self: stretch;
    flex-direction: column;
  }
`;

interface Props {
  isOpen: boolean;
  title: string;
  titleCaption?: string;
  width?: string;
  onClose: () => void;
  renderHeaderContent?: () => JSX.Element | null;
  renderContent: () => JSX.Element | null;
}

const SideDrawer: FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const [openDrawerState, setOpenDrawerState] = useState(props.isOpen);
  const [visibileMaskState, setVisibileMaskState] = useState(props.isOpen);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => document.removeEventListener("keydown", onKeyDown, false);
  }, []);

  function onKeyDown(e) {
    if (e.keyCode === 27) {
      props.onClose();
    }
  }

  // Purpose here is to drive a more fluid animation
  useEffect(() => {
    if (props.isOpen) {
      setOpenDrawerState(true);
      setVisibileMaskState(true);
    } else {
      setTimeout(() => {
        setVisibileMaskState(false);
      }, ANIMATION_TIME_MS);
      setOpenDrawerState(false);
    }
  }, [props.isOpen]);

  function handleClickToClose(e) {
    e.stopPropagation();
    if (e.target === e.currentTarget) {
      props.onClose();
    }
  }

  return ReactDOM.createPortal(
    <StyledDiv
      isOpenDrawer={openDrawerState}
      isVisibileMask={visibileMaskState}
      width={props.width ?? DEFAULT_WIDTH}
      padding={theme.space_lg}
      onClick={handleClickToClose}
    >
      <div className={cn("drawer")}>
        <Flex className={cn("header")} direction="column">
          <Flex justifyContent="space-between" width="100%">
            <Text appearance="h3" color={theme.text_color} whiteSpace="nowrap">
              {props.title}
            </Text>
            <button className={cn("closer")} onClick={props.onClose}>
              <Icon clickable icon={faTimes} size="xl" />
            </button>
          </Flex>
          {props.titleCaption ? (
            <Text color={theme.text_color_caption}>{props.titleCaption}</Text>
          ) : null}
          {props.renderHeaderContent && (
            <div className={cn("headerContent")}>
              {props.renderHeaderContent()}
            </div>
          )}
        </Flex>
        <div className={cn("content")}>{props.renderContent()}</div>
      </div>
    </StyledDiv>,
    document.getElementsByTagName("body")[0]
  );
};

export default SideDrawer;
