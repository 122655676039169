import {
  UpdateDashboardParameters,
  UpdateDashboardsParameters,
} from "@/api/core/types";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import { useTheme } from "@emotion/react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useEffect, useMemo, useState } from "react";
import useGatekeeper from "../../../hooks/useGatekeeper";
import Form from "../../../ui-lib/components/Form";
import Select from "../../../ui-lib/components/Select";
import TableTags from "../../../ui-lib/components/TableTags";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";

export const SIDE_DRAWER_WIDTH = "55rem";

const EditOperation = {
  ADD: "ADD",
  REMOVE: "REMOVE",
} as const;

type Dashboard = {
  id: string;
  createdAt: string;
  createdByID: string;
  favoritedUserIDs: string[];
  name: string;
  scope: string;
  tags: string[];
  updatedAt: string | null;
};

interface Props {
  existingTags: string[];
  isOpen: boolean;
  loadingSubmit: boolean;
  selectedDashboards: Dashboard[];
  onInteraction: (interaction: DashboardTagsEditForm.Interaction) => void;
}

type TableData = {
  name: string;
  owner: string;
  tags: string[];
  scope: string;
};

type Option = {
  label: string;
  value: string;
};

type State = {
  tags: string[];
  inputTags: Option[];
  openSelect: boolean;
  operation: (typeof EditOperation)[keyof typeof EditOperation] | null;
};

const defaultState: State = {
  tags: [],
  inputTags: [],
  openSelect: false,
  operation: null,
};

export function DashboardTagsEditForm(props: Props) {
  const theme = useTheme();

  const gatekeeper = useGatekeeper();

  const [state, setState] = useState<State>(defaultState);
  const mergeState = getMergeState(setState);
  const columnHelper = createColumnHelper<TableData>();

  useEffect(() => {
    if (props.isOpen === false) {
      setState(defaultState);
    }

    if (props.selectedDashboards.length === 1) {
      setState({
        ...defaultState,
        inputTags:
          props.selectedDashboards.length === 1
            ? props.selectedDashboards[0].tags.map((tag) => ({
                label: tag,
                value: tag,
              }))
            : [],
      });
    }
  }, [props.isOpen, props.selectedDashboards]);

  const isMultiEdit = props.selectedDashboards.length > 1;

  function handleSubmitMultiple() {
    if (props.selectedDashboards.length === 0 || state.inputTags.length === 0)
      return;

    const updates = props.selectedDashboards.map((dashboard) => {
      const update: UpdateDashboardsParameters["paramsArray"][number] = {
        dashboardID: dashboard.id,
      };

      switch (state.operation) {
        case EditOperation.ADD: {
          const updatedTags = [...dashboard.tags];

          state.inputTags.forEach((tag) => {
            if (!dashboard.tags.includes(tag.value)) {
              updatedTags.push(tag.value);
            }
          });
          update.tags = updatedTags;
          break;
        }
        case EditOperation.REMOVE: {
          const inputTags = state.inputTags.map((inputTag) => inputTag.value);
          update.tags = dashboard.tags.filter((tag) => {
            return !inputTags.includes(tag);
          });
          break;
        }
      }

      return update;
    });

    props.onInteraction({
      type: DashboardTagsEditForm.INTERACTION_MULTIPLE_SUBMIT_BUTTON_CLICKED,
      params: updates,
    });
  }

  function handleSubmitSingle(): void {
    const selectedDashboard = props.selectedDashboards[0];

    const update: UpdateDashboardParameters = {};

    if (state.inputTags && state.inputTags.length) {
      update.tags = state.inputTags.map((inputTag) => inputTag.value);
    } else {
      update.tags = [];
    }

    props.onInteraction({
      type: DashboardTagsEditForm.INTERACTION_SINGLE_SUBMIT_BUTTON_CLICKED,
      dashboardID: selectedDashboard.id,
      params: update,
    });
  }

  function handleClose(): void {
    props.onInteraction({
      type: DashboardTagsEditForm.INTERACTION_CANCEL_BUTTON_CLICKED,
    });
  }

  function handleClickTag(tag: string) {
    setState((currentState) => {
      let { inputTags } = currentState;
      if (inputTags.some(({ value }) => value === tag)) {
        inputTags = inputTags.filter(({ value }) => value !== tag);
      } else {
        inputTags = [...inputTags, { label: tag, value: tag }];
      }

      return { ...currentState, inputTags };
    });
  }

  const repeatedTags = useMemo(() => {
    if (
      props.selectedDashboards.length === 0 ||
      props.existingTags.length === 0
    ) {
      return [];
    }
    const repeatedTags: string[] = [];

    const dashboardsWithTags = props.selectedDashboards.filter((dashboard) => {
      return dashboard.tags.length > 0;
    });

    props.existingTags.forEach((tag) => {
      if (
        dashboardsWithTags.every((dashboard) => dashboard.tags.includes(tag))
      ) {
        repeatedTags.push(tag);
      }
    });

    return repeatedTags;
  }, [props.selectedDashboards, props.existingTags]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: ({ getValue }) => <Text truncate>{getValue()}</Text>,
        header: copyText.tableHeaderName,
        size: 200,
      }),
      columnHelper.accessor("owner", {
        header: copyText.tableHeaderOwner,
        size: 140,
      }),
      columnHelper.accessor("scope", {
        header: copyText.tableHeaderVisibility,
        size: 70,
      }),
      columnHelper.accessor("tags", {
        cell: ({ getValue }) => {
          const value = getValue();
          if (value.length === 0) return "--";

          return (
            <TableTags
              tags={value}
              repeatedTags={isMultiEdit ? repeatedTags : undefined}
              onClickTag={handleClickTag}
            />
          );
        },
        header: "Tags",
        size: 250,
      }),
    ],
    [repeatedTags, isMultiEdit]
  );

  const tableData = useMemo((): TableData[] => {
    return props.selectedDashboards.map((dashboard) => ({
      name: dashboard.name,
      owner: dashboard.createdByID,
      tags: dashboard.tags,
      scope: dashboard.scope,
    }));
  }, [props.selectedDashboards]);

  function handleChangeInputTagss(opts): void {
    mergeState({ inputTags: opts });
  }

  const canUpdate = props.selectedDashboards.every((dashboard) =>
    gatekeeper.canUpdateDashboard(dashboard.id)
  );

  const tagOptions =
    state.operation === EditOperation.ADD &&
    props.selectedDashboards.length === 1
      ? props.existingTags
          .filter((tag) => !props.selectedDashboards[0].tags.includes(tag))
          .map((tag) => ({ label: tag, value: tag }))
      : props.existingTags.map((tag) => ({ label: tag, value: tag }));

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen={props.isOpen}
      title={copyText.modalTitleEditTagsForm}
      width={SIDE_DRAWER_WIDTH}
      onClose={handleClose}
    >
      <Flex
        direction="column"
        minHeight={350}
        minWidth={`calc(${SIDE_DRAWER_WIDTH} - ${theme.space_jumbo})`}
      >
        <Box flex="1 0 auto">
          <Table
            alternateRows
            rowHeight="75px"
            columns={columns}
            data={tableData}
            initialState={{
              pagination: {
                pageSize: 4,
              },
            }}
            isLoading={props.loadingSubmit}
            showPagination
          />
        </Box>

        <Flex direction="column" minHeight={150} paddingLeft={theme.space_sm}>
          <Text appearance="h4" marginBottom={theme.space_xs}>
            {copyText.tagsEditSectionTitle}
          </Text>
          <Form>
            <Flex width={"100%"}>
              {isMultiEdit ? (
                <Flex marginRight={theme.space_sm}>
                  <Tooltip
                    content={copyText.dashboardEditAddTagsTooltipMessage}
                  >
                    <Button
                      iconStart={<Icon icon={faPlus} />}
                      primary={state.operation === EditOperation.ADD}
                      secondary
                      onClick={(e) => {
                        e.preventDefault();
                        mergeState({
                          operation: EditOperation.ADD,
                        });
                      }}
                    />
                  </Tooltip>

                  <Tooltip
                    content={copyText.dashboardEditRemoveTagsTooltipMessage}
                  >
                    <Button
                      iconStart={<Icon icon={faMinus} />}
                      marginLeft={theme.space_sm}
                      primary={state.operation === EditOperation.REMOVE}
                      secondary
                      onClick={(e) => {
                        e.preventDefault();
                        mergeState({ operation: EditOperation.REMOVE });
                      }}
                    />
                  </Tooltip>
                </Flex>
              ) : null}

              <Box width={"100%"}>
                <Select
                  disabled={!state.operation && isMultiEdit}
                  placeholder={
                    !state.operation
                      ? copyText.dashboardEditSelectDisabledPlaceholder
                      : copyText.dashboardEditSelectPlaceholder
                  }
                  isMulti
                  isSearchable
                  isCreatable
                  options={tagOptions}
                  value={state.inputTags}
                  onChange={handleChangeInputTagss}
                />
              </Box>
            </Flex>
          </Form>
        </Flex>

        <Flex justifyContent="flex-end">
          <Button
            disabled={props.loadingSubmit}
            secondary
            onClick={handleClose}
          >
            {copyText.actionClose}
          </Button>
          {isMultiEdit ? (
            <Button
              disabled={!canUpdate || !state.operation || props.loadingSubmit}
              marginLeft={theme.space_md}
              primary
              width={100}
              onClick={handleSubmitMultiple}
            >
              {props.loadingSubmit ? <LoadingSpinner /> : copyText.actionSubmit}
            </Button>
          ) : (
            <Button
              disabled={props.loadingSubmit || !canUpdate}
              marginLeft={theme.space_md}
              primary
              width={100}
              onClick={handleSubmitSingle}
            >
              {props.loadingSubmit ? <LoadingSpinner /> : copyText.actionSubmit}
            </Button>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
}

DashboardTagsEditForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  `DashboardTagsEditForm.INTERACTION_CANCEL_BUTTON_CLICKED` as const;
DashboardTagsEditForm.INTERACTION_MULTIPLE_SUBMIT_BUTTON_CLICKED =
  `DashboardTagsEditForm.INTERACTION_MULTIPLE_SUBMIT_BUTTON_CLICKED` as const;
DashboardTagsEditForm.INTERACTION_SINGLE_SUBMIT_BUTTON_CLICKED =
  `DashboardTagsEditForm.INTERACTION_SINGLE_SUBMIT_BUTTON_CLICKED` as const;

type InteractionCancelButtonClicked = {
  type: typeof DashboardTagsEditForm.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionBulkSubmitButtonClicked = {
  type: typeof DashboardTagsEditForm.INTERACTION_MULTIPLE_SUBMIT_BUTTON_CLICKED;
  params: UpdateDashboardsParameters["paramsArray"];
};

type InteractionSingleSubmitButtonClicked = {
  type: typeof DashboardTagsEditForm.INTERACTION_SINGLE_SUBMIT_BUTTON_CLICKED;
  dashboardID: string;
  params: UpdateDashboardParameters;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DashboardTagsEditForm {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSingleSubmitButtonClicked
    | InteractionBulkSubmitButtonClicked;
}

export default DashboardTagsEditForm;
