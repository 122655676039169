import { createStructParam } from "@/lib/use-query-params";
import Dropdown from "@/ui-lib/components/Dropdown";
import { ActionMenuButton } from "@/ui-lib/components/Table";
import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatCurrency } from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { EBStoreGroup, EBStoreGroupFilters } from "../types";

type TableData = {
  billPayerAccountId: string;
  groupID: string;
  operationsCost: number;
  region: string;
  snapshotCost: number;
  snapshotCount: number;
  snapshotUsageBytes: number;
  storageCost: number;
  storageUsageBytes: number;
  totalCost: number;
  volumeCount: number;
};

type Props = {
  isLoadingEBStoreGroups: boolean;
  storeGroups: EBStoreGroup[];
  onInteraction: (interaction: EBStoreGroupTable.Interaction) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

function EBStoreGroupTable(props: Props) {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  const [sortRule, setSortRule] = useQueryParam(
    "ebs_group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("groupID", {
        cell: ({ getValue }) => (
          <EBSDropdown
            groupID={getValue()}
            onSelectSnapshots={(groupID) => {
              props.onInteraction({
                type: EBStoreGroupTable.INTERACTION_VIEW_SNAPSHOTS_CLICKED,
                selectedSnapshotGroupID: groupID,
              });
            }}
            onSelectVolumes={(groupID) => {
              props.onInteraction({
                type: EBStoreGroupTable.INTERACTION_VIEW_VOLUMES_CLICKED,
                selectedVolumeGroupID: groupID,
              });
            }}
          />
        ),
        enableSorting: false,
        size: 50,
      }),
      columnHelper.accessor("billPayerAccountId", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: EBStoreGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "billPayerAccountId",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.s3TableNull}
          </Text>
        ),
        header: () => (
          <Tooltip
            content={
              <Box width={200}>
                <Text color={theme.text_color_inverse}>
                  {copyText.awsIAMListPermissionTooltipMessage}
                </Text>
              </Box>
            }
          >
            {copyText.ebsTableGroupHeader_billPayerAccountId}
          </Tooltip>
        ),
        meta: { align: "left" },
      }),
      columnHelper.accessor("region", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: EBStoreGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "region",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.s3TableNull}
          </Text>
        ),
        header: copyText.ebsTableGroupHeader_region,
        meta: { align: "left" },
        size: 100,
      }),
      columnHelper.accessor("volumeCount", {
        cell: ({ getValue }) => getValue(),
        header: copyText.ebsTableGroupHeader_volumeCount,
        meta: { align: "right" },
        size: 60,
      }),
      columnHelper.accessor("snapshotCount", {
        cell: ({ getValue }) => getValue(),
        header: copyText.ebsTableGroupHeader_snapshotCount,
        meta: { align: "right" },
        size: 60,
      }),
      columnHelper.accessor("storageUsageBytes", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header: copyText.ebsTableGroupHeader_storageUsageBytes,
        meta: { align: "right" },
        size: 100,
      }),
      columnHelper.accessor("snapshotUsageBytes", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header: copyText.ebsTableGroupHeader_snapshotUsageBytes,
        meta: { align: "right" },
        size: 100,
      }),
      columnHelper.accessor("snapshotCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.ebsTableGroupHeader_snapshotCost,
        meta: { align: "right" },
        size: 100,
      }),
      columnHelper.accessor("storageCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.ebsTableGroupHeader_storageCost,
        meta: { align: "right" },
        size: 100,
      }),
      columnHelper.accessor("operationsCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.ebsTableGroupHeader_operationsCost,
        meta: { align: "right" },
        size: 100,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.ebsTableGroupHeader_totalCost,
        meta: { align: "right" },
        size: 100,
      }),
    ],
    [props.onInteraction, theme]
  );

  const data: TableData[] = useMemo(
    () =>
      props.storeGroups.map((storeGroup) => ({
        billPayerAccountId: storeGroup.billPayerAccountId,
        groupID: storeGroup.groupID,
        operationsCost: storeGroup.operationsCost,
        region: storeGroup.region,
        snapshotCost: storeGroup.snapshotCost,
        snapshotCount: storeGroup.snapshots.length,
        snapshotUsageBytes: !storeGroup.snapshotUsageBytes
          ? 0
          : storeGroup.snapshotUsageBytes / storeGroup.snapshots.length,
        storageCost: storeGroup.storageCost,
        storageUsageBytes: !storeGroup.storageUsageBytes
          ? 0
          : storeGroup.storageUsageBytes / storeGroup.volumes.length,
        totalCost: storeGroup.totalCost,
        volumeCount: storeGroup.volumes.length,
      })),
    [props.storeGroups]
  );

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{ sorting: [sortRule] }}
      isLoading={props.isLoadingEBStoreGroups}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );

  interface EBSDropdownProps {
    groupID: string;
    onSelectSnapshots: (id: string) => void;
    onSelectVolumes: (id: string) => void;
  }

  function EBSDropdown(dropdownProps: EBSDropdownProps): JSX.Element {
    const options = [
      {
        label: copyText.storageSubTableViewVolumes,
        onClick: () => dropdownProps.onSelectVolumes(dropdownProps.groupID),
      },
      {
        label: copyText.storageSubTableViewSnapshots,
        onClick: () => dropdownProps.onSelectSnapshots(dropdownProps.groupID),
      },
    ];

    return (
      <Dropdown options={options} placement="bottom-start">
        <ActionMenuButton />
      </Dropdown>
    );
  }
}

EBStoreGroupTable.INTERACTION_FILTER_CLICKED =
  `EBStoreGroupTable.INTERACTION_FILTER_CLICKED` as const;

EBStoreGroupTable.INTERACTION_VIEW_VOLUMES_CLICKED =
  `EBStoreGroupTable.INTERACTION_VIEW_VOLUMES_CLICKED` as const;

EBStoreGroupTable.INTERACTION_VIEW_SNAPSHOTS_CLICKED =
  `EBStoreGroupTable.INTERACTION_VIEW_SNAPSHOTS_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof EBStoreGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof EBStoreGroupFilters;
  filterValue: EBStoreGroupFilters[keyof EBStoreGroupFilters];
}

interface InteractionViewVolumesClicked {
  type: typeof EBStoreGroupTable.INTERACTION_VIEW_VOLUMES_CLICKED;
  selectedVolumeGroupID: string;
}

interface InteractionViewSnapshotsClicked {
  type: typeof EBStoreGroupTable.INTERACTION_VIEW_SNAPSHOTS_CLICKED;
  selectedSnapshotGroupID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace EBStoreGroupTable {
  export type Interaction =
    | InteractionFilterClicked
    | InteractionViewSnapshotsClicked
    | InteractionViewVolumesClicked;
}

export default EBStoreGroupTable;
