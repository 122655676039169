import useGatekeeper from "@/hooks/useGatekeeper";
import Dropdown from "@/ui-lib/components/Dropdown";
import { ActionMenuButton } from "@/ui-lib/components/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import React, { useMemo } from "react";
import copyText from "../copyText";

type Bill = {
  id: string;
  cloudProviderType: CloudProviderType;
  createdAt: string;
  createdBy: string;
  name: string;
};

type TableData = {
  id: string;
  cloudProviderType: CloudProviderType;
  createdAt: string;
  createdBy: string;
  name: string;
};

interface Props {
  bills: Bill[];
  isLoading: boolean;
  onInteraction: (interaction: BillTable.Interaction) => void;
}

export function BillTable(props: Props): JSX.Element {
  const gatekeeper = useGatekeeper();
  const columnHelper = createColumnHelper<TableData>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "actionMenu",
        cell: ({ row }) => (
          <BillDropdown
            billID={row.original.id}
            onInteraction={props.onInteraction}
          />
        ),
        enableSorting: false,
        meta: { align: "center" },
        size: 50,
      }),
      columnHelper.accessor("name", {
        header: copyText.billTableHeaderBillName,
        size: 200,
      }),
      columnHelper.accessor("cloudProviderType", {
        header: copyText.billTableHeaderSource,
        size: 200,
      }),
      columnHelper.accessor("createdBy", {
        header: copyText.billTableHeaderCreatedBy,
        size: 200,
      }),
      columnHelper.accessor("createdAt", {
        cell: ({ getValue }) => (
          <>
            {formatDate(
              new Date(getValue() ? getValue() : 0),
              "MM/dd/yyyy hh:mm a"
            )}
          </>
        ),
        header: copyText.billTableHeaderCreatedAt,
        size: 200,
      }),
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={props.bills}
      initialState={{ sorting: [{ id: "displayName", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );

  interface BillDropdownProps {
    billID: string;
    onInteraction: (interaction: BillTable.Interaction) => void;
  }

  function BillDropdown(dropdownProps: BillDropdownProps): JSX.Element {
    const options = [
      {
        label: copyText.actionMenuItemGenerateReport,
        locked: !gatekeeper.canGenerateTakeoutReport,
        onClick: () =>
          dropdownProps.onInteraction({
            type: BillTable.INTERACTION_GENERATE_TAKEOUT_BUTTON_CLICKED,
            billID: dropdownProps.billID,
          }),
      },
      {
        label: copyText.actionMenuItemDeleteBill,
        locked: !gatekeeper.getCanDeleteSpecificBill(
          props.bills.find((bill) => bill.id === dropdownProps.billID)
            ?.createdBy ?? ""
        ),
        onClick: () =>
          dropdownProps.onInteraction({
            type: BillTable.INTERACTION_DELETE_BUTTON_CLICKED,
            billID: dropdownProps.billID,
          }),
      },
    ];

    return (
      <Dropdown options={options} placement="bottom-start">
        <ActionMenuButton />
      </Dropdown>
    );
  }
}

BillTable.INTERACTION_DELETE_BUTTON_CLICKED = `BillTable.INTERACTION_DELETE_BUTTON_CLICKED`;
BillTable.INTERACTION_GENERATE_TAKEOUT_BUTTON_CLICKED = `BillTable.INTERACTION_GENERATE_TAKEOUT_BUTTON_CLICKED`;

interface InteractionDeleteButtonClicked {
  type: typeof BillTable.INTERACTION_DELETE_BUTTON_CLICKED;
  billID: string;
}

interface InteractionGenerateTakeoutButtonClicked {
  type: typeof BillTable.INTERACTION_GENERATE_TAKEOUT_BUTTON_CLICKED;
  billID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BillTable {
  export type Interaction =
    | InteractionDeleteButtonClicked
    | InteractionGenerateTakeoutButtonClicked;
}

export default BillTable;
